import React from 'react';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';

export const CustomerAddress: React.FunctionComponent = () => {
  const account = useSelectedAccount();
  const address = account?.address;

  if (!address) {
    return null;
  }

  return (
    <React.Fragment>
      <div>{address.addressLine1}</div>
      {address.addressLine2 && <div>{address.addressLine2}</div>}
      <div>
        {address.city}, {address.state} {address.zipcode}
      </div>
    </React.Fragment>
  );
};
