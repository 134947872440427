export function getHref(baseUrl: string, params?: Record<string, string>): string {
  const urlWithParams = new URL(`${baseUrl}`, window.location.href);

  if (params) {
    for (const key in params) {
      const value = params[key]?.trim();
      if (value) {
        urlWithParams.searchParams.append(key, value);
      }
    }
  }

  return urlWithParams.href;
}
