import { UsageData } from './TimeOfUseData';

export function uniqueRecordsByMeter(records: UsageData[]): UsageData[] {
  const uniqueMeters: Record<string, UsageData> = {};

  for (const record of records || []) {
    // Only look at 1 entry for each meter per time period
    // Needs to account for instances where we have multiple installation on the same meter
    const time = `${record.record.month}-${record.record.day}-${record.record.hour}`;
    const place = `${record.record.premiseNumber}-${record.record.meterNumber}`;
    const key = `${place}-${time}-${record.record.readingType}`;
    if (uniqueMeters[key] !== undefined) {
      continue;
    }
    uniqueMeters[key] = record;
  }

  return Object.values(uniqueMeters);
}
