import React from 'react';

export function patternStripeForward(UUID?: string, className?: string, patternSize?: number): JSX.Element {
  let id = 'pattern-stripe-forward';
  if (UUID) {
    id += '-' + UUID;
  }

  let width = '100%';
  let height = '100%';
  if (patternSize) {
    width = '' + patternSize;
    height = '' + patternSize;
  }

  let classNames = 'pattern-stripe-forward';
  if (className) {
    classNames += ' ' + className;
  }

  return (
    <React.Fragment>
      <pattern
        x="40%"
        y="40%"
        id={id}
        patternUnits="userSpaceOnUse"
        className={classNames}
        width={width}
        height={height}
        patternTransform="rotate(135)"
        viewBox="0 0 15 15"
      >
        <rect width="15" height="15" />
        <line x1="0" y="0" x2="0" y2="15" />
      </pattern>
    </React.Fragment>
  );
}

export function patternStripeBackward(UUID?: string, className?: string, patternSize?: number): JSX.Element {
  let id = 'pattern-stripe-backward';
  if (UUID) {
    id += '-' + UUID;
  }

  let width = '100%';
  let height = '100%';
  if (patternSize) {
    width = '' + patternSize;
    height = '' + patternSize;
  }

  let classNames = 'pattern-stripe-backward';
  if (className) {
    classNames += ' ' + className;
  }

  return (
    <React.Fragment>
      <pattern
        x="40%"
        y="40%"
        id={id}
        patternUnits="userSpaceOnUse"
        className={classNames}
        width={width}
        height={height}
        patternTransform="rotate(45)"
        viewBox="0 0 15 15"
      >
        <rect width="15" height="15" />
        <line x1="0" y="0" x2="0" y2="15" />
      </pattern>
    </React.Fragment>
  );
}

export const Patterns = (
  <svg className="patterns" aria-hidden="true">
    <defs>
      <linearGradient id={'redToBlue'} x1="0" x2="0" y1="0" y2="100%">
        <stop offset="0%" stopColor="#990000" stopOpacity={0.1} />
        <stop offset="100%" stopColor="#1E3575" stopOpacity={0.1} />
      </linearGradient>
      {patternStripeForward('peak', 'peak')}
      {patternStripeBackward('superOffpeak', 'noData superOffpeak')}
      {patternStripeBackward('offpeak', 'noData offpeak')}
      {patternStripeBackward('peak', 'noData peak')}
      {patternStripeBackward('standard', 'noData standard')}
    </defs>
  </svg>
);
