import React, { ChangeEvent, SyntheticEvent } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import './SmartRadioGroup.scss';

export interface RadioGroupChoice {
  label: JSX.Element | string;
  value: string;
  disabled?: boolean;
}

interface Props {
  name: string;
  inline?: boolean;
  choices: RadioGroupChoice[];
  defaultChoice: string;
  onChoiceChange: (choice: string) => void;
}

// Attempt to use the default to select a valid choice
export function validChoice(choices: RadioGroupChoice[], defaultChoice: string): string {
  if (!choices.length) {
    // In theory we should always have a choice
    return '';
  }

  // Return the default value (if present)
  const matchesDefault = choices.filter((choice) => {
    if (choice.disabled) {
      return false;
    }
    return choice.value === defaultChoice;
  });
  if (matchesDefault.length) {
    return defaultChoice;
  }

  // Otherwise default to the first value
  return choices[0].value;
}

export const SmartRadioGroup: React.FunctionComponent<Props> = (props: Props) => {
  const updateValidChoice = (): void => {
    // If we selected an initial value that wasn't the default, let the parent know
    const validDefault = validChoice(props.choices, props.defaultChoice);
    if (validDefault !== props.defaultChoice) {
      props.onChoiceChange(validDefault);
    }
  };

  updateValidChoice();

  const updateChoice = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.currentTarget.value;
    props.onChoiceChange(newValue);
    event.stopPropagation();
  };

  const renderChoice = (choice: RadioGroupChoice) => {
    const id = `${props.name}-${choice.value}`;

    // Single choice
    if (props.choices.length === 1) {
      if (props.inline) {
        return (
          <div className="singleChoice" key={id}>
            {choice.label}
          </div>
        );
      }

      return (
        <Row key={id}>
          <Col>{choice.label}</Col>
        </Row>
      );
    }

    const checked = props.defaultChoice === choice.value;

    if (props.inline) {
      return (
        <Form.Check
          key={id}
          type="radio"
          custom
          inline
          label={choice.label}
          value={choice.value}
          name={props.name}
          id={id}
          checked={checked}
          disabled={choice.disabled}
          onChange={updateChoice}
          onClick={(event: SyntheticEvent) => event.stopPropagation()}
        />
      );
    }

    return (
      <Row key={id}>
        <Col>
          <Form.Check
            type="radio"
            custom
            label={choice.label}
            value={choice.value}
            name={props.name}
            id={id}
            checked={checked}
            disabled={choice.disabled}
            onChange={updateChoice}
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
          />
        </Col>
      </Row>
    );
  };

  const choices = props.choices.map(renderChoice);
  return <React.Fragment>{choices}</React.Fragment>;
};
