export function normalizeValue(value: string): string {
  // Cover if value is null, undefined, or not a string
  if (typeof value?.trim !== 'function') {
    return undefined;
  }

  const trimmed = value.trim();
  if (!trimmed) {
    return undefined;
  }

  return trimmed;
}

export function equalJson(valueA: unknown, valueB: unknown): boolean {
  // Shortcut and return true if the values are strictly equal
  if (valueA === valueB) {
    return true;
  }

  return stringifyJSON(valueA) === stringifyJSON(valueB);
}

export function copyJson<T>(value: T): T {
  if (!value) {
    return undefined;
  }
  return parseJSON(JSON.stringify(value));
}

export function unwrap<T>(values: T[]): T {
  if (!values?.length) {
    return undefined;
  }

  return values[0];
}

function ignoreBlankValues(_key: string, rawValue: unknown): unknown {
  let value = rawValue;

  // Normalize strings
  if (typeof value === 'string') {
    value = normalizeValue(value);
  }

  // Ignore anything undefined
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  return value;
}

function getStableJson(_key: string, value: unknown): unknown {
  // Ensure keys are sorted in objects
  if (value instanceof Object && !(value instanceof Array)) {
    return Object.keys(value)
      .sort()
      .reduce((sorted, key) => {
        sorted[key] = value[key];
        return sorted;
      }, {});
  }

  return ignoreBlankValues(_key, value);
}

export function stringifyJSON(value: unknown): string {
  try {
    const jsonString = JSON.stringify(value, getStableJson, 4);
    return jsonString;
  } catch {
    return undefined;
  }
}

export function parseJSON<T>(value: string): T {
  try {
    return JSON.parse(value, getStableJson) as T;
  } catch {
    return undefined;
  }
}
