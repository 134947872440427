import {
  isValid,
  parseISO,
  startOfDay,
  isAfter as dateFnsAfter,
  isBefore as dateFnsBefore,
  isPast as dateFnsPast,
} from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function normalizeDate(dateString: string): string {
  const date = getDate(dateString);
  return formatISO(date, 'EXACT');
}

export function getTruncatedDate(dateString: string, formatType: string): Date {
  let date = getDate(dateString);
  if (dateString) {
    date = new Date();
  }
  const formatted = formatISO(date, formatType);
  return getDate(formatted);
}

export function startOfTodayEST(): Date {
  const date = utcToZonedTime(new Date(), 'America/New_York');
  const dayStartZoned = startOfDay(date);
  return zonedTimeToUtc(dayStartZoned, 'America/New_York');
}

export function formatISO(date: Date, formatType: string): string {
  if (!isValid(date)) {
    return undefined;
  }
  if (formatType === 'DAY') {
    return formatEST(date, 'yyyy-MM-dd');
  }
  return date.toISOString();
}

// Months in AP Style
const monthSubstitutions: Record<string, string> = {
  January: 'Jan.',
  February: 'Feb.',
  August: 'Aug.',
  September: 'Sept.',
  October: 'Oct.',
  December: 'Dec.',
};

function abberviateMonths(dateString: string): string {
  let formatted = dateString;

  for (const month of Object.keys(monthSubstitutions)) {
    const abbreviation = monthSubstitutions[month];
    formatted = formatted.replaceAll(month, abbreviation);
  }

  return formatted;
}

function includesShortMonth(dateFormat: string): boolean {
  return dateFormat.includes('MMM') && !dateFormat.includes('MMMM');
}

export function formatEST(date: Date, dateFormat: string): string {
  if (!isValid(date)) {
    return undefined;
  }

  // Determine if we need custom logic to replace short months
  const shortMonth = includesShortMonth(dateFormat);

  // If using short months, start by formatted the date with the full month name (then do a replacment with the abbreviation afterwards)
  if (shortMonth) {
    dateFormat = dateFormat.replaceAll('MMM', 'MMMM');
  }

  let formatted;
  try {
    formatted = formatInTimeZone(date, 'America/New_York', dateFormat);

    // Go back and replace the full month name with the abbreviated version
    if (shortMonth) {
      formatted = abberviateMonths(formatted);
    }
  } catch (error) {
    // Unable to format date
  }

  return formatted;
}

export function getDate(dateString: string): Date {
  if (!dateString) {
    return undefined;
  }

  try {
    const parsed = parseISO(dateString);
    if (isValid(parsed)) {
      return parsed;
    }
  } catch {
    // Unable to parse date
  }

  return undefined;
}

export function isAfter(dateA: Date, dateB: Date): boolean {
  if (!dateA || !dateB) {
    return false;
  }

  return dateFnsAfter(dateA, dateB);
}

export function isBefore(dateA: Date, dateB: Date): boolean {
  if (!dateA || !dateB) {
    return false;
  }

  return dateFnsBefore(dateA, dateB);
}

export function isPast(date: Date): boolean {
  if (!date) {
    return false;
  }

  return dateFnsPast(date);
}
