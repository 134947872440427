import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../../libraryStyleOverrides/DatePicker.scss';
import { useInterval } from '../../hooks/parameters/useInterval';
import { MonthSelection } from './MonthSelection';
import { YearSelection } from './YearSelection';
import { DaySelection } from './DaySelection';

export const DateSelection: React.FunctionComponent = () => {
  const [interval] = useInterval();

  if (interval === 'hour') {
    return <DaySelection />;
  }

  // TODO: add day back in as well?
  if (interval === 'day') {
    return <MonthSelection />;
  }

  return <YearSelection />;
};
