import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../libraryStyleOverrides/DatePicker.scss';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { firstDefinedValue } from '../../util/firstDefinedValue';

export const DaySelection: React.FunctionComponent = () => {
  const [dateLimitMin, dateLimitMax] = useDateLimits();
  const [{ end: endDate }, setDateRange] = useSelectedDateRange();

  const [selectedDate, setSelectedDate] = useState<Date>(endDate);

  useEffect(() => {
    if (!dateLimitMax) {
      return;
    }

    const newDates = [selectedDate, endDate, dateLimitMax];
    const newDate = firstDefinedValue(newDates);

    setDateRange({ start: newDate, end: newDate });
  }, [dateLimitMax, endDate, selectedDate, setDateRange]);

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }}>
        <Form.Group as="fieldset">
          <div>
            <Form.Label htmlFor="dayDate">Day</Form.Label>
          </div>
          <DatePicker
            id="dayDate"
            formatWeekDay={(d: unknown): string => {
              // TODO: update when this bug is fixed:
              // https://github.com/Hacker0x01/react-datepicker/issues/3918
              const dateString = d + '';
              return dateString.charAt(0);
            }}
            selected={endDate}
            minDate={dateLimitMin}
            maxDate={dateLimitMax}
            onChange={setSelectedDate}
            dateFormat="M/d/yyyy"
            popperPlacement="bottom-start"
            data-track="usage"
            data-track-detail="usage shiftandsave"
            data-track-action="click"
            data-track-sub-action="select day"
          />
        </Form.Group>
      </Col>
    </React.Fragment>
  );
};
