import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useUsageData } from '../../components/UsageData/UsageWrapper';
import { useTemperatureData } from '../../components/TemperatureData/TemperatureWrapper';
import { UsageSummary } from '../../components/UsageSummary/UsageSummary';
import { UsageTable } from '../../components/UsageTable/UsageTable';
import { GraphLegend } from '../../components/UsageGraph/GraphLegend';

import 'react-datepicker/dist/react-datepicker.css';
import './GraphSelection.scss';
import { UsageGraphHelper } from '../../components/UsageData/UsageDataHelper';
import { UsageGraph, useGraphRecords } from '../../components/UsageGraph/UsageGraph';
import { Spinner } from '../../components/Spinner/Spinner';
import { TemperatureGraphHelper } from '../../components/TemperatureData/TemperatureDataHelper';
import { MeterOptions } from '../../components/UsageGraph/MeterOptions';
import { DateSelection } from '../../components/Dates/DateSelection';
import { IntervalOptions } from '../../components/TimeOfUseGraph/IntervalOptions';
import { useMode } from '../../hooks/parameters/useMode';
import { Patterns } from '../../components/Patterns';
import { DownloadDataInfo } from '../../components/Info/DownloadDataInfo';

interface WarningsProps {
  openModal: () => void;
  usage?: UsageGraphHelper;
}

const Warnings: React.FunctionComponent<WarningsProps> = (props: WarningsProps) => {
  if (!props.usage) {
    return null;
  }

  // const standardWarning = null;

  let missingDataWarning = null;
  if (props.usage.hasPartialData || props.usage.hasMissingData) {
    missingDataWarning = (
      <tr className="warningDetails">
        <td>
          <sup>*</sup>
        </td>
        <td>
          Your bill statement may vary as data may take up to 36 hours to process or may not be available because of an
          interruption or outage.
        </td>
      </tr>
    );
  }

  return (
    <Row>
      <Col className="mr-3 ml-3">
        <table className="graphWarnings">
          <colgroup>
            <col className="symbol" />
            <col className="explanation" />
          </colgroup>
          <thead className="sr-only">
            <tr>
              <th>Symbol</th>
              <th>Explanation</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr className="warningDetails">
              <td>
                <sup>1</sup>
              </td>
              <td>
                <Button
                  variant="link"
                  onClick={props.openModal}
                  data-track="usage"
                  data-track-detail="usage show-cost-advisement"
                  data-track-action="click"
                  data-track-sub-action="show cost-advisement"
                >
                  Costs are estimated
                </Button>{' '}
                and may not match what’s listed on your bill. Please refer to your bill for exact costs.
              </td>
            </tr>
            {standardWarning} */}
            {missingDataWarning}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

interface DataDisplayProps {
  usage: UsageGraphHelper | undefined;
  temperatures: TemperatureGraphHelper | undefined;
  openModal: () => void;
}

export const DataDisplay: React.FunctionComponent<DataDisplayProps> = (props: DataDisplayProps) => {
  const [mode] = useMode();
  const graphData = useGraphRecords(mode, props.usage, props.temperatures);

  if (!props.usage || !props.temperatures) {
    return <Spinner className="comboGraph" />;
  }

  return (
    <React.Fragment>
      <GraphLegend usage={props.usage} temperatures={props.temperatures} product="gas" />
      <UsageGraph usage={props.usage} temperatures={props.temperatures} graphData={graphData} product="gas" />
      <UsageSummary mode={mode} usage={props.usage} product="gas" />
      <Warnings openModal={props.openModal} usage={props.usage} />
      <UsageTable mode={mode} graphData={graphData} product="gas" />
    </React.Fragment>
  );
};

export const GraphSelection: React.FunctionComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const temperatures = useTemperatureData();
  const usage = useUsageData();

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form className="usageOptions">
            <Form.Row>
              <MeterOptions />
              <IntervalOptions />
              <DateSelection />
              {/* <ModeOptions mode={mode} setMode={setMode} usage={usage} />
              <CostWarning mode={mode} usage={usage} showModal={showModal} setShowModal={setShowModal} /> */}
            </Form.Row>
          </Form>
          {Patterns}
          <DataDisplay usage={usage} temperatures={temperatures} openModal={openModal} />
        </Col>
      </Row>
      <DownloadDataInfo />
    </React.Fragment>
  );
};
