import { useCallback } from 'react';
import { useProductType } from './useProductType';
import { useValidatedStringQueryParam } from './useValidatedStringQueryParam';

const gasValues = ['day', 'month'];
const electricValues = ['hour', 'day', 'month'];

export function useIntervalValue(): [string, (string) => void] {
  const [productType] = useProductType();

  const isValidInterval = useCallback(
    (value: string) => {
      if (productType === 'gas') {
        return gasValues.includes(value);
      }
      return electricValues.includes(value);
    },
    [productType]
  );

  const [interval, setInterval] = useValidatedStringQueryParam('interval', isValidInterval, 'day');
  return [interval, setInterval];
}
