import axios, { AxiosRequestConfig } from 'axios';
import { AxiosResponse, AxiosError } from 'axios';
import { config } from './config';
import { useState, useEffect, useRef } from 'react';
import { jsonEqual } from './utils';
import { validateToken } from './validation';
import { setupCache } from 'axios-cache-interceptor';
import { CacheOptions } from 'axios-cache-interceptor/dist/cache/create';
import { useToken } from '../contexts/TokenContext/useToken';
import { secondsToMilliseconds } from 'date-fns';

const axiosConfig = {
  baseUrl: config.apiBase,
  headers: {
    Accept: 'application/json',
    'Ocp-Apim-Subscription-Key': config.apiSubscriptionKey,
  },
};

// For most calls, we want caching for the life of the page
const cachedApisBase = axios.create({ ...axiosConfig });

const cacheOptions: CacheOptions = {
  // 45 minute in memory cache (same as security tokens)
  ttl: 45 * 60 * 1000,
};
const cachedApis = setupCache(cachedApisBase, cacheOptions);

// For some calls we don't want any caching behavior
const apis = axios.create(axiosConfig);

interface APIMError {
  code?: string;
  statusCode?: string;
  message?: string;
}

function check401(error: AxiosError): string {
  // Not a 401
  if (error.response?.status !== 401) {
    return;
  }

  const response = error?.response?.data as APIMError;
  const code = response?.code;

  if (['KEY_INVALID', 'KEY_NOT_FOUND'].includes(code)) {
    if (!config.isProd) {
      // Redirect to test page to re-enter subscription key
      // eslint-disable-next-line no-alert
      alert('Bad subscription key -- please enter a correct subscription key');
      window.location.pathname = '/test?invalidKey=true';
    }

    // Should never get here as long as app config is correct
    return 'Bad subscription key';
  }

  if (['TOKEN_EXPIRED'].includes(code)) {
    redirectToLogin();
    return 'Expired login';
  }

  // Fallback on redirect
  redirectToLogin();
  return 'Expired login';
}

function check403(error: AxiosError): string {
  // Not a 401
  if (error.response?.status !== 403) {
    return undefined;
  }

  // eslint-disable-next-line no-alert
  alert("Your account doesn't have the correct roles for access to this application");

  return 'Not Authorized';
}

function handleAxiosError(error: AxiosError): void {
  // Not an Axios error
  if (!axios.isAxiosError(error)) {
    return;
  }

  // Ignore if we specifically requested to cancel the request
  if (axios.isCancel(error)) {
    return;
  }

  // For some reason calling isCancel resets the type to never
  const axiosError = error as AxiosError;

  // Check for 401 response
  let errorMessage = check401(axiosError);
  if (errorMessage) {
    console.log('401 error: ', errorMessage);
    console.log('Response: ', axiosError.response);
    return;
  }

  // User doesn't have access
  errorMessage = check403(axiosError);
  if (errorMessage) {
    console.log('403 error: ', errorMessage);
    console.log('Response: ', axiosError.response);
    return;
  }

  // eslint-disable-next-line no-console
  console.error('Service Error: ', axiosError);
  console.log('Response: ', axiosError.response);

  // TODO: need better error handling

  // eslint-disable-next-line no-alert
  alert('An error occurred retrieving data.  Please refresh the page and try again.');
}

function redirectToLogin(): void {
  // TODO: need different page for employee login
  window.sessionStorage.setItem('urlAfterLogin', window.location.href);
  if (isEmployee()) {
    window.location.reload();
  } else {
    window.location.href = `${config.webBase}/wps/wcm/connect/dte-web/login`;
  }
}

export function isEmployee(): boolean {
  return window.location.pathname.includes('/cr/');
}

export function getDefaultRequestConfig(token?: string): AxiosRequestConfig {
  const requestConfig: AxiosRequestConfig = {
    responseType: 'json',
    withCredentials: false,
  };
  requestConfig.headers = {};

  if (token) {
    requestConfig.headers['Authorization'] = 'Bearer ' + token;
    requestConfig.timeout = secondsToMilliseconds(100);
  }
  return requestConfig;
}

// TODO: replace this with async
export function getCustomerToken(callback: (token: string) => void): void {
  const url = `${config.dteApi}/getUserDetails`;

  const requestConfig: AxiosRequestConfig = {
    withCredentials: true,
  };
  requestConfig.headers = {};
  requestConfig.headers['Accept'] = 'text/plain';

  // Set a shorter timeout when getting the customer JWT token
  requestConfig.timeout = secondsToMilliseconds(30);

  const request = axios.get<string>(url, requestConfig);
  request.catch(handleAxiosError);
  void request.then((response: AxiosResponse<string>) => {
    callback(response.data);
  });
}

export async function jsonPost<T>(url: string, requestConfig: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  requestConfig.method = 'post';

  const id = getUrlId(url, requestConfig);
  await cachedApis.storage.remove(id);

  const request = apis.post<T>(url, undefined, requestConfig);
  request.catch(handleAxiosError);

  return request;
}

export async function jsonGet<T>(
  url: string,
  requestConfig: AxiosRequestConfig,
  cache = true
): Promise<AxiosResponse<T>> {
  requestConfig.method = 'get';

  let request: Promise<AxiosResponse<T>>;
  if (cache) {
    const id = getUrlId(url, requestConfig);
    const combinedConfig = {
      ...requestConfig,
      id: id,
    };

    request = cachedApis.get<T>(url, combinedConfig);
  } else {
    request = apis.get<T>(url, requestConfig);
  }
  request.catch(handleAxiosError);

  return request;
}

function validRequest(
  url: string | undefined | null,
  config: AxiosRequestConfig | undefined | null,
  loadingURL: string | undefined | null,
  loadingConfig: AxiosRequestConfig | undefined | null
): [string | null, AxiosRequestConfig | null] {
  const currentURL = url;
  const currentConfig: AxiosRequestConfig | null = config ? { ...config } : null;

  // Need a valid URL and config
  if (!currentURL || !currentConfig) {
    return [null, null];
  }

  const sameURL = currentURL === loadingURL;
  const sameConfig = jsonEqual(currentConfig, loadingConfig);

  // Already loading this
  if (sameURL && sameConfig) {
    return [null, null];
  }

  return [currentURL, currentConfig];
}

// React hook for a JSON get
export function useJsonGet<T>(
  url: string | null,
  requestConfig: AxiosRequestConfig | null,
  cacheRequest = true
): [T | null, boolean] {
  const [results, setResults] = useState<T | null>(null);

  // Start off as true if we haven't loaded anything yet
  const [loading, setLoading] = useState(true);

  const cache = useRef(cacheRequest);

  const mounted = useRef<boolean>(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const loadingURL = useRef<string | null>(null);
  const loadingConfig = useRef<AxiosRequestConfig | null>(null);
  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    const [currentURL, currentConfig] = validRequest(url, requestConfig, loadingURL.current, loadingConfig.current);
    if (!currentURL || !currentConfig) {
      return;
    }

    loadingURL.current = currentURL;
    loadingConfig.current = currentConfig;
    setLoading(true);

    // Check for when we finish -- is this still the request data that's wanted?
    const ignoreRequest = () => {
      // No longer mounted
      if (!mounted.current) {
        return true;
      }

      // Not the most current request
      if (currentURL !== url) {
        return true;
      }

      if (!jsonEqual(currentConfig, requestConfig)) {
        return true;
      }

      // If we get this far, it's the request we wanted
      return false;
    };

    const fetch = async () => {
      // Get the records
      let requestResults: T | null = null;
      try {
        let request;
        if (cache) {
          const id = getUrlId(url, requestConfig);
          const combinedConfig = {
            ...requestConfig,
            id: id,
          };
          request = cachedApis.get<T>(currentURL, combinedConfig);
        } else {
          request = apis.get<T>(currentURL, currentConfig);
        }
        const response = await request;

        // Ignore if not the most recent request
        if (ignoreRequest()) {
          return;
        }
        requestResults = response.data;
      } catch (e) {
        // Error
        if (axios.isAxiosError(e)) {
          handleAxiosError(e);
        } else {
          console.error('service error: ', e);
        }
      }

      setResults(requestResults);
      setLoading(false);
    };
    void fetch();
  }, [url, requestConfig]);

  return [results, loading];
}

export interface UrlDetails {
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
}

function getRequestConfig(
  token: string | null | undefined,
  urlDetails: UrlDetails | null | undefined,
  authenticated: boolean
): AxiosRequestConfig | null {
  if (!urlDetails) {
    return null;
  }

  try {
    let newConfig;
    if (authenticated) {
      const validToken = validateToken(token);
      newConfig = getDefaultRequestConfig(validToken);
    } else {
      newConfig = getDefaultRequestConfig();
    }

    newConfig.method = 'get';
    if (urlDetails.params) {
      newConfig.params = { ...urlDetails.params };
    }
    return newConfig;
  } catch {
    // invalid parameters
  }

  return null;
}

export function useAuthenticatedJsonGet<T>(urlDetails: UrlDetails | null, cache = true): [T | null, boolean] {
  const token = useToken();
  const url = urlDetails?.url || '';
  const requestConfig = getRequestConfig(token, urlDetails, true);
  const [results, loading] = useJsonGet<T | null>(url, requestConfig, cache);

  return [results, loading];
}

export function useUnauthenticatedJsonGet<T>(urlDetails: UrlDetails | null, cache = true): [T | null, boolean] {
  const url = urlDetails?.url || '';
  const requestConfig = getRequestConfig(null, urlDetails, false);
  const [results, loading] = useJsonGet<T | null>(url, requestConfig, cache);

  return [results, loading];
}

export async function jsonDelete<T>(url: string, requestConfig: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  requestConfig.method = 'delete';

  const id = getUrlId(url, requestConfig);
  await cachedApis.storage.remove(id);

  const request = apis.delete<T>(url, requestConfig);
  request.catch(handleAxiosError);

  return request;
}

export async function blobRequest(url: string, requestConfig: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
  requestConfig.responseType = 'blob';

  const id = getUrlId(url, requestConfig);
  const combinedConfig = {
    ...requestConfig,
    id: id,
  };

  const request = cachedApis.get<Blob>(url, combinedConfig);
  request.catch(handleAxiosError);

  return request;
}

function getUrlId(url: string, requestConfig: AxiosRequestConfig): string {
  const config: AxiosRequestConfig = {
    url: url,
    params: requestConfig?.params,
  };
  try {
    axios.getUri(config);
  } catch {
    return undefined;
  }
}
