import React from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';

function saveKey(apiKey: string) {
  if (!apiKey) {
    return;
  }

  // Set the value, then reload the page
  window.localStorage.setItem('subscriptionKey', apiKey);
  if (window.location.pathname.includes('test')) {
    window.location.href = '/';
  } else {
    window.location.reload();
  }
}

function clearKey() {
  window.localStorage.removeItem('subscriptionKey');
  window.location.reload();
}

export const TestHarness: React.FunctionComponent<{}> = () => {
  const [subscriptionKey, setSubscriptionKey] = React.useState(window.localStorage.getItem('subscriptionKey') || '');

  const changeSubscriptionKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const subscriptionKey = event.currentTarget.value || '';
    setSubscriptionKey(subscriptionKey);
  };

  const save = (): void => {
    saveKey(subscriptionKey);
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1>Setup Test Environment Information</h1>
            <br />
            <p>An api key is needed to interact with pages in the test environment, please enter one to continue</p>
            <p>This page will not appear in production or on subsequent visits unless you clear localStorage.</p>
            <hr />
            <Form>
              <Form.Group>
                <Form.Label>Subscription Key</Form.Label>
                <Form.Control defaultValue={subscriptionKey} onChange={changeSubscriptionKey}></Form.Control>
              </Form.Group>
              <Button onClick={save}>Save & Continue</Button>
              <Button className="ml-3" onClick={clearKey}>
                Clear
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
