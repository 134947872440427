import React, { useEffect, useState } from 'react';
import { AccountList } from '../../components/AccountList/AccountList';
import { Spinner } from '../../components/Spinner/Spinner';
import { AccountContext, AccountContextType } from './AccountContext';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { Account, ApiResponse } from '../../components/AccountList/account';
import { isEmployee, UrlDetails, useAuthenticatedJsonGet } from '../../util/AuthManager';
import { config } from '../../util/config';
import { filterAccounts } from '../../components/AccountList/AccountListWrapper';
import { useProductType } from '../../hooks/parameters/useProductType';
import { equalJson } from '../../util/objects';
import { defaultAccount } from './useAccountList';

interface Props {
  children?: React.ReactNode;
}

export function useURL(): UrlDetails {
  const [url, setURL] = useState<UrlDetails>();
  const [accountNumber] = useAccountNumber();
  const employee = isEmployee();

  useEffect(() => {
    let newURL = undefined;

    if (employee) {
      if (accountNumber) {
        newURL = { url: `${config.apiBase}/authenticated/accounts/${accountNumber}` };
      }
    } else {
      newURL = { url: `${config.apiBase}/authenticated/accounts` };
    }

    setURL(newURL);
  }, [employee, accountNumber]);

  return url;
}

export const AccountContextWrapper: React.FC<Props> = (props) => {
  const [productType, setProductType] = useProductType();
  const [accountNumber, setAccountNumber] = useAccountNumber();
  const [accountList, setAccountList] = useState<AccountList>();
  const [filteredAccountList, setFilteredAccountList] = useState<AccountList>();
  const [account, setAccount] = useState<Account>();
  const url = useURL();
  const [results, loading] = useAuthenticatedJsonGet<ApiResponse>(url);

  useEffect(() => {
    // Don't do anything until we have results
    if (loading) {
      return;
    }

    // Do nothing if the same list of accounts
    const accounts = results?.accounts || [];
    if (equalJson(accounts, accountList?.data)) {
      return;
    }

    const newAccountList = new AccountList(accounts);
    setAccountList(newAccountList);
  }, [results, loading, productType, accountList, setAccountList]);

  useEffect(() => {
    const filteredAccounts = filterAccounts(accountList?.data, productType);
    const newFilteredAccountList = new AccountList(filteredAccounts);
    setFilteredAccountList(newFilteredAccountList);
  }, [accountList, productType, setFilteredAccountList]);

  useEffect(() => {
    const selectedAccount = defaultAccount(filteredAccountList, accountNumber);
    const newAccountNumber = selectedAccount?.accountNumber;

    // Do nothing if no account should be selected
    if (!newAccountNumber) {
      return;
    }

    // Account was already selected
    if (newAccountNumber === accountNumber && newAccountNumber === account?.accountNumber) {
      return;
    }

    // Selected account changed
    setAccountNumber(selectedAccount.accountNumber);
    setAccount(selectedAccount);
    setProductType(selectedAccount?.defaultProductType);
  }, [filteredAccountList, account, accountNumber, setAccountNumber, setAccount, setProductType]);

  const context: AccountContextType = {
    account: account,
    accountList: accountList,
    filteredAccountList: filteredAccountList,
  };

  if (loading) {
    return <Spinner size="4x" className="fullPageSpinner" />;
  }

  return (
    <React.Fragment>
      <AccountContext.Provider value={context}>{props.children}</AccountContext.Provider>
    </React.Fragment>
  );
};
