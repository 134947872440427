import React from 'react';
import { differenceInDays, endOfMonth, format } from 'date-fns';

interface Props {
  x: number;
  y: number;
  className?: string;
  children?: React.ReactNode;
}
function Tick(props: Props): JSX.Element {
  return (
    <text x={props.x} y={props.y + 12} textAnchor="middle" orientation="bottom" className={props.className}>
      {props.children}
    </text>
  );
}

function nearEndOfMonth(date: Date): boolean {
  const monthEndDate = endOfMonth(date);
  const difference = Math.abs(differenceInDays(date, monthEndDate));
  return difference <= 3;
}

function showFull(index: number, ticks: number[]): boolean {
  if (index < 0) {
    return false;
  }

  const midpoint = ticks[index];
  const date = new Date(midpoint);

  // Always show the first value, unless it's near the end of the month
  if (index === 0 && !nearEndOfMonth(date)) {
    return true;
  }

  // Always show first day of the month
  if (date.getDate() === 1) {
    return true;
  }

  return false;
}

function showPartial(index: number, ticks: number[]): boolean {
  if (index < 0) {
    return false;
  }

  const midpoint = ticks[index];
  const date = new Date(midpoint);
  const dayOfMonth = date.getDate();

  if (ticks.length > 60) {
    return [15].indexOf(dayOfMonth) !== -1;
  }

  if (ticks.length > 31) {
    return [10, 20].indexOf(dayOfMonth) !== -1;
  }

  const month = date.getMonth();
  const midpointEnd = ticks[ticks.length - 1];
  const dateEnd = new Date(midpointEnd);
  const monthEnd = dateEnd.getMonth();

  // Don't show a label on the 28th if we span more than 1 month
  if (month !== monthEnd) {
    return [7, 14, 21].indexOf(dayOfMonth) !== -1;
  }

  return [7, 14, 21, 28].indexOf(dayOfMonth) !== -1;
}

export function XAxisLabelDateRange(midpoint: number, ticks: number[], x: number, y: number): JSX.Element | null {
  const index = ticks.indexOf(midpoint);
  const date = new Date(midpoint);

  if (showFull(index, ticks)) {
    return (
      <Tick x={x} y={y}>
        {format(date, 'M/d')}
      </Tick>
    );
  }

  if (showPartial(index, ticks)) {
    return (
      <Tick x={x} y={y}>
        {format(date, 'd')}
      </Tick>
    );
  }

  // Immediately after or before a full date
  if (showFull(index - 1, ticks) || showFull(index + 1, ticks)) {
    if (ticks.length > 31) {
      return (
        <Tick x={x} y={y}>
          &nbsp;
        </Tick>
      );
    }

    return (
      <Tick x={x} y={y}>
        <tspan className="minus d-none d-lg-inline">-</tspan>
      </Tick>
    );
  }

  // Slightly after or before a full date
  if (showFull(index - 2, ticks) || showFull(index + 2, ticks)) {
    if (ticks.length > 31) {
      <Tick x={x} y={y}>
        &nbsp;
      </Tick>;
    }

    return (
      <Tick x={x} y={y}>
        <tspan className="minus d-none d-sm-inline">-</tspan>
      </Tick>
    );
  }

  // Immediately after or before a partial
  if (showPartial(index - 1, ticks) || showPartial(index + 1, ticks)) {
    if (ticks.length > 31) {
      <Tick x={x} y={y}>
        &nbsp;
      </Tick>;
    }

    return (
      <Tick x={x} y={y}>
        <tspan className="minus d-none d-md-inline">-</tspan>
      </Tick>
    );
  }

  return (
    <Tick x={x} y={y}>
      <tspan className="minus">-</tspan>
    </Tick>
  );
}
