import React, { useContext } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { AccountContext } from '../../contexts/AccountContext/AccountContext';

interface SearchFilterProps {
  filter: string;
  setFilter: (filter: string) => void;
}

export const SearchFilter: React.FunctionComponent<SearchFilterProps> = (props: SearchFilterProps) => {
  const context = useContext(AccountContext);
  const accountList = context.accountList;

  // Set how many acounts are needed in the list before the filter appears
  const searchThreshold = 10;

  const resetFilter = () => {
    props.setFilter('');
  };

  const updateFilter = (event: React.FormEvent<HTMLInputElement>): void => {
    let value = event.currentTarget.value || '';
    value = value.trim();
    value = value.toUpperCase();
    props.setFilter(value);
    event.stopPropagation();
  };

  // Hide search filter if less than the threshold
  if (!accountList || accountList?.accounts.length < searchThreshold) {
    return null;
  }

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col>
          <Form.Control
            onKeyUp={updateFilter}
            placeholder="Search by account number or service address"
            className="form-control"
          />
        </Col>
        <Col xs={{ span: 'auto' }}>
          <Button onClick={resetFilter} variant="link">
            Reset
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};
