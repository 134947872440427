import React from 'react';

export interface DateRange {
  start: Date;
  end: Date;
}

export interface DateContextType {
  dateLimit?: DateRange;
  dateRange?: DateRange;
  setDateRange: (dateRange?: DateRange) => void;
  interval?: string;
  setInterval: (interval: string) => void;
}

export const DateContext = React.createContext<DateContextType>({
  setDateRange: (_dateRange: DateRange) => {
    // implementation will fill this in
  },
  setInterval: (_interval: string) => {
    // implementation will fill this in
  },
});
