import React, { SyntheticEvent, ChangeEvent } from 'react';
import { Col, Form } from 'react-bootstrap';

function yearOptions(years: number[], selectedYear: string): JSX.Element[] {
  return years.map((year) => {
    const yearString = '' + year;

    let className = 'option';
    if (yearString === selectedYear) {
      className += ' selected';
    }

    return (
      <option key={yearString} value={yearString} className={className}>
        {yearString}
      </option>
    );
  });
}

interface Props {
  years: number[];
  selectedYear: string;
  setYear: (year: string) => void;
  children?: React.ReactNode;
}

export const YearOptions: React.FunctionComponent<Props> = (props: Props) => {
  function changeYear(event: ChangeEvent<HTMLSelectElement>): void {
    props.setYear(event.currentTarget.value);
    event.stopPropagation();
  }

  if (props.years.length === 0) {
    return null;
  }

  if (props.years.length === 1) {
    return (
      <React.Fragment>
        <Col xs={{ span: 'auto' }}>
          <Form.Group as="fieldset">
            <Form.Label>Year</Form.Label>
            <p className="custom-control pl-0">{props.selectedYear}</p>
          </Form.Group>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }}>
        <Form.Group as="fieldset">
          <Form.Label htmlFor="year">Year</Form.Label>
          <Form.Control
            id="year"
            as="select"
            value={props.selectedYear}
            onChange={changeYear}
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
            data-track-detail="usage peaktimesavings"
            data-track-action="click"
            data-track-sub-action="change year"
          >
            {yearOptions(props.years, props.selectedYear)}
          </Form.Control>
        </Form.Group>
      </Col>
    </React.Fragment>
  );
};
