import { useState } from 'react';
import { equalJson } from '../util/objects';

export function useJsonEqualState<T>(initialValue?: T): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(initialValue);

  const updateValue = (newValue: T) => {
    // Only update the underlying value if it's actually different
    if (equalJson(value, newValue)) {
      return;
    }
    setValue(newValue);
  };

  return [value, updateValue];
}
