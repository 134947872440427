import { useEffect, useState } from 'react';
import { Account } from '../../components/AccountList/account';
import { AccountList } from '../../components/AccountList/AccountList';
import { isEmployee, UrlDetails } from '../../util/AuthManager';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { config } from '../../util/config';

export function defaultAccount(accountList: AccountList, defaultValue: string): Account {
  if (!accountList?.hasAccounts) {
    return undefined;
  }

  const account = accountList.getAccount(defaultValue);
  if (account) {
    return account;
  }

  return accountList.accounts[0];
}

export function useURL(): UrlDetails {
  const [url, setURL] = useState<UrlDetails>(null);
  const [accountNumber] = useAccountNumber();
  const employee = isEmployee();

  useEffect(() => {
    let newURL = null;

    if (employee) {
      if (accountNumber) {
        newURL = { url: `${config.apiBase}/authenticated/accounts/${accountNumber}` };
      }
    } else {
      newURL = { url: `${config.apiBase}/authenticated/accounts` };
    }

    setURL(newURL);
  }, [employee, accountNumber]);

  return url;
}
