import { uniqueValues } from '../../util/utils';
import { ApiAccount, Account } from './account';

function accounts(data: ApiAccount[]): Account[] {
  const accountMap = new Map<string, Account>();

  // Get list of account numbers
  let accountNumbers = data.map((record) => {
    return record.accountNumber;
  });
  accountNumbers = uniqueValues(accountNumbers);

  // Get account objects for each account number
  for (const accountNumber of accountNumbers) {
    if (!accountMap.has(accountNumber)) {
      const filtered = data.filter((record) => {
        return record.accountNumber === accountNumber;
      });
      accountMap.set(accountNumber, new Account(filtered));
    }
  }

  return Array.from(accountMap.values());
}

function hasAccounts(accounts: Account[]): boolean {
  return accounts?.length > 0;
}

function hasMultipleAccounts(accounts: Account[]): boolean {
  return accounts?.length > 1;
}

function gasAccounts(accounts: Account[]): number {
  if (!accounts?.length) {
    return 0;
  }
  const filtered = accounts.filter((account) => {
    return account.hasGasService(true);
  });
  return filtered.length;
}

function electricAccounts(accounts: Account[]): number {
  if (!accounts?.length) {
    return 0;
  }
  const filtered = accounts.filter((account) => {
    return account.hasElectricService(true);
  });
  return filtered.length;
}

export class AccountList {
  readonly data: ApiAccount[];
  readonly accounts: Account[];
  readonly hasAccounts: boolean;
  readonly hasMultipleAccounts: boolean;
  readonly gasAccounts: number;
  readonly electricAccounts: number;
  readonly amiAccounts: number;

  constructor(data: ApiAccount[]) {
    this.data = data || [];
    this.accounts = accounts(this.data);
    this.hasAccounts = hasAccounts(this.accounts);
    this.hasMultipleAccounts = hasMultipleAccounts(this.accounts);
    this.gasAccounts = gasAccounts(this.accounts);
    this.electricAccounts = electricAccounts(this.accounts);
    this.amiAccounts = this.gasAccounts + this.electricAccounts;
  }

  public getAccount(accountNumber: string | undefined | null): Account | null {
    if (!accountNumber) {
      return null;
    }

    const filtered = this.accounts.filter((record) => {
      return record.accountNumber === accountNumber;
    });

    if (!filtered.length) {
      return null;
    }

    return filtered[0];
  }
}
