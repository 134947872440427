import React from 'react';
import { subMonths, startOfMonth, differenceInDays } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { SelectDateRange } from '../Dates/SelectDateRange';

interface Props {
  children?: React.ReactNode;
}

export const ReportDateRange: React.FunctionComponent<Props> = (props: Props) => {
  const [, dateLimitMax] = useDateLimits();

  const earliest = startOfMonth(subMonths(dateLimitMax, 13));
  const days = Math.abs(differenceInDays(earliest, dateLimitMax));

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h5>Date Range</h5>
        </Col>
      </Row>
      <SelectDateRange dayLimit={days}>{props.children}</SelectDateRange>
    </React.Fragment>
  );
};
