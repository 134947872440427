import { useValidatedStringQueryParam } from './useValidatedStringQueryParam';

function isValidProductType(value: string): boolean {
  const normalized = value?.trim();

  const validValues = ['electric', 'gas'];

  return validValues.includes(normalized);
}

export function useProductType(): [string, (string) => void] {
  const [productType, setProductType] = useValidatedStringQueryParam('productType', isValidProductType);

  return [productType, setProductType];
}
