import React, { SyntheticEvent, ChangeEvent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { getDate, isBefore } from '../../common/dates/dates';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { useMeterNumber } from '../../hooks/parameters/useMeterNumber';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';
import { ApiAccount } from '../AccountList/account';

function meterOption(meterNumber: string): JSX.Element {
  const text = `${meterNumber}`;

  return (
    <option key={meterNumber} value={meterNumber}>
      {text}
    </option>
  );
}


function sortRecent(a: ApiAccount, b: ApiAccount): number {
  let dateA = getDate(a.lastEffectiveDate)?.getTime();
  let dateB = getDate(b.lastEffectiveDate)?.getTime();

  if (dateA === dateB) {
    dateA = getDate(a.effectiveDate)?.getTime();
    dateB = getDate(b.effectiveDate)?.getTime();
  }

  if (!dateA) {
    return -1;
  }
  if (!dateB) {
    return 1;
  }

  return dateB - dateA;
}

function isInactiveMeter(apiAccount: ApiAccount): boolean {
  const date = getDate(apiAccount.lastEffectiveDate);
  if (!date) {
    return false;
  }
  return isBefore(date, new Date());
}

function isMeterWithNoData(apiAccount: ApiAccount, dateLimitMin: Date): boolean {
  const date = getDate(apiAccount.lastEffectiveDate);
  if (!date) {
    return false;
  }
  return isBefore(date, dateLimitMin);
}

function activeMeterGroup(activeMeters: ApiAccount[], inactiveMeters: ApiAccount[]): JSX.Element[] {
  if (!activeMeters?.length) {
    return [];
  }

  const uniqueActiveMeters = [...new Set(activeMeters.map(apiAccount => apiAccount.meterNumber))];
  const meterList = uniqueActiveMeters?.map(meterOption);

  if (!inactiveMeters?.length) {
    return meterList;
  }

  return [
    <optgroup key="active" label="Active Meters">
      {meterList}
    </optgroup>,
  ];
}

function inactiveMeterGroup(activeMeters: ApiAccount[], inactiveMeters: ApiAccount[]): JSX.Element[] {
  if (!inactiveMeters?.length) {
    return [];
  }

  const uniqueInactiveMeters = [...new Set(inactiveMeters.map(apiAccount => apiAccount.meterNumber))];
  const meterList = uniqueInactiveMeters?.map(meterOption);

  return [
    <optgroup key="inactive" label="Inactive Meters">
      {meterList}
    </optgroup>,
  ];
}

function optionList(apiAccountList: ApiAccount[], dateLimitMin: Date): JSX.Element[] {
  const optionsList: JSX.Element[] = [];

  optionsList.push(
    <option key="all" value="all">
      All Meters
    </option>
  );

  const filteredAccounts = apiAccountList.filter((apiAccount) => {
    return !isMeterWithNoData(apiAccount, dateLimitMin);
  });

  const activeMeters = [];
  const inactiveMeters = [];

  for (const apiAccount of filteredAccounts) {
    const inactive = isInactiveMeter(apiAccount);
    if (inactive) {
      inactiveMeters.push(apiAccount);
    } else {
      activeMeters.push(apiAccount);
    }
  }

  // Put most recent meters first
  activeMeters.sort(sortRecent);
  inactiveMeters.sort(sortRecent);

  const activeMeterBlock = activeMeterGroup(activeMeters, inactiveMeters);
  optionsList.push(...activeMeterBlock);

  const inactiveMeterBlock = inactiveMeterGroup(activeMeters, inactiveMeters);
  optionsList.push(...inactiveMeterBlock);
  console.log('optionsList',optionsList);

  return optionsList;
}

export const MeterOptions: React.FunctionComponent = () => {
  const account = useSelectedAccount();
  const [meterNumber, setMeterNumber] = useMeterNumber();
  const [dateLimitMin] = useDateLimits();

  function changeMeter(event: ChangeEvent<HTMLSelectElement>): void {
    let newValue: string | undefined = event.currentTarget.value;
    if (newValue === 'all') {
      newValue = undefined;
    }

    setMeterNumber(newValue);
    event.stopPropagation();
  }

  let meter = meterNumber;
  if (!meter) {
    meter = 'all';
  }

  const apiAccountList = account?.apiAccountList || [];

  // No need to select if only a single meter
  if (apiAccountList.length < 2) {
    return null;
  }

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }}>
        <Form.Group as="fieldset">
          <Form.Label htmlFor="meter">Meter</Form.Label>
          <Form.Control
            id="meter"
            as="select"
            value={meter}
            onChange={changeMeter}
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
            data-track-detail="usage shiftandsave"
            data-track-action="click"
            data-track-sub-action="change meter"
          >
            {optionList(apiAccountList, dateLimitMin)}
          </Form.Control>
        </Form.Group>
      </Col>
    </React.Fragment>
  );
};
