import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SelectAccount } from './SelectAccount';
import { CustomerAddress } from './CustomerAddress';
import './CustomerInformation.scss';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';

export const CustomerInformation: React.FunctionComponent = () => {
  const account = useSelectedAccount();

  if (!account) {
    return null;
  }

  // TODO: match mobile comp

  return (
    <React.Fragment>
      <Container className="customerInformation">
        <Row>
          <Col xs={{ span: 12 }} sm={{ span: 6 }}>
            <h5>{account.customerName}</h5>
            <CustomerAddress />
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 6 }} className="accountNumber">
            <SelectAccount />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
