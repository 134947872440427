import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { ApiAccount } from './account';
import { AccountList } from './AccountList';
import { Spinner } from '../Spinner/Spinner';
import { Header } from '../Header/Header';
import { AccountContext } from '../../contexts/AccountContext/AccountContext';

interface Props {
  pageName: string;
  includeLinks: boolean;
  productTypeFilter?: string;
  children?: React.ReactNode;
}

export function filterAccounts(accounts: ApiAccount[], productTypeFilter?: string): ApiAccount[] {
  if (!accounts?.length) {
    return undefined;
  }
  if (!productTypeFilter) {
    return accounts;
  }
  return accounts.filter((account) => {
    return account.productType === productTypeFilter;
  });
}

function noAccountMessage(
  accountList: AccountList,
  filteredAccountList: AccountList,
  productTypeFilter?: string
): JSX.Element | null {
  if (!filteredAccountList?.hasAccounts) {
    if (!accountList?.hasAccounts) {
      // No accounts
      return (
        <React.Fragment>Active electric or gas service is required to view or download usage data.</React.Fragment>
      );
    }

    // No gas accounts
    if (productTypeFilter === 'gas') {
      return <React.Fragment>Active gas service is required to view or download gas usage data.</React.Fragment>;
    }

    // No electric accounts
    return (
      <React.Fragment>Active electric service is required to view or download electric usage data.</React.Fragment>
    );
  }

  // Assuming we have at least one account, check if we have AMI accounts
  return noAmiAccountMessage(accountList, filteredAccountList, productTypeFilter);
}

function noAmiAccountMessage(
  accountList: AccountList,
  filteredAccountList: AccountList,
  productTypeFilter?: string
): JSX.Element | null {
  if (!filteredAccountList?.amiAccounts) {
    if (!accountList?.amiAccounts) {
      // No accounts with an AMI meter
      return (
        <React.Fragment>
          Active electric or gas service with an AMI meter is required to view or download usage data.
        </React.Fragment>
      );
    }

    // No gas account
    if (productTypeFilter === 'gas') {
      return (
        <React.Fragment>
          Active gas service with an AMI meter is required to view or download gas usage data.
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        Active electric service with an AMI meter is required to view or download electric usage data.
      </React.Fragment>
    );
  }

  return null;
}

export const AccountListWrapper: React.FunctionComponent<Props> = (props: Props) => {
  const accountContext = useContext(AccountContext);
  const accountList = accountContext.accountList;
  const filteredAccountList = accountContext.filteredAccountList;

  if (!accountList || !filteredAccountList) {
    return (
      <React.Fragment>
        <Header pageName={props.pageName} includeLinks={false} />
        <Spinner size="4x" className="fullPageSpinner" />
      </React.Fragment>
    );
  }

  const errorMessage = noAccountMessage(accountList, filteredAccountList, props.productTypeFilter);
  if (errorMessage) {
    return (
      <React.Fragment>
        <Header pageName={props.pageName} includeLinks={false} />
        <Container className="bodyContent">{errorMessage}</Container>
      </React.Fragment>
    );
  }

  const account = accountContext.account;
  if (!account) {
    return (
      <React.Fragment>
        <Header pageName={props.pageName} includeLinks={false} />
        <Spinner size="4x" className="fullPageSpinner" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header pageName={props.pageName} includeLinks={props.includeLinks} />
      {props.children}
    </React.Fragment>
  );
};
