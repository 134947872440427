import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DeleteLinkPrompt } from './DeleteLinkPrompt';
import './ManageSharedLink.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '../Spinner/Spinner';
import { SharedLink } from './sharedLink';
import { callSharedLinkApi } from './api';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';
import { useToken } from '../../contexts/TokenContext/useToken';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';

const instructions = (
  <React.Fragment>
    <Row className="mb-3">
      <Col>
        You can also generate a link to view and share up-to-date energy usage data with a third party from the past 13
        months. A third party is a person or entity that has no contractual relationship with DTE Energy to perform
        services or act on behalf of DTE. Your link will remain active until you delete the link or close your account.
        Once deleted, those who have the link will no longer have access to your energy data.
      </Col>
    </Row>
  </React.Fragment>
);

interface State {
  sharedLink: SharedLink | null;
  loading: boolean;
  updating: boolean;
  lastContext: string;
}

interface CopyLinkProps {
  url: string;
}

const CopyLink: FunctionComponent<CopyLinkProps> = (props: CopyLinkProps) => {
  return (
    <CopyToClipboard text={props.url}>
      <div className="copy-link">
        <FontAwesomeIcon
          icon={faCopy}
          title="Copy Link"
          data-track="usage"
          data-track-detail="usage report"
          data-track-action="click"
          data-track-sub-action="copy link"
        />

        <Button
          variant="link"
          className="pl-1 copySharedLink"
          data-track="usage"
          data-track-detail="usage report"
          data-track-action="click"
          data-track-sub-action="copy link"
        >
          Copy Link
        </Button>
      </div>
    </CopyToClipboard>
  );
};

interface GenerateLinkProps {
  generateLink: () => void;
}

const GenerateLink: React.FunctionComponent<GenerateLinkProps> = (props: GenerateLinkProps) => {
  return (
    <button
      className="btn btn-primary"
      onClick={props.generateLink}
      data-track="usage"
      data-track-detail="usage report"
      data-track-action="click"
      data-track-sub-action="get link"
    >
      Generate Link
    </button>
  );
};

const disclaimer = (
  <Row className="finePrint mt-3">
    <Col>
      Customers have the right to share their own account information with third parties of their choice to obtain
      services or products provided by those third parties. Please be aware the information you share with a third party
      is your responsibility. We are not responsible for loss, theft, alteration or misuse of the information by you or
      third parties after the information has been transferred.
    </Col>
  </Row>
);

interface LinkForm {
  sharedLink: SharedLink;
  generateLink: () => void;
  deleteLink: () => void;
}

const ModifyLink: React.FunctionComponent<LinkForm> = (props: LinkForm) => {
  const url = props.sharedLink?.url;

  // Already have a link, show that
  return (
    <Row className="align-items-center">
      <Col md={{}} sm={{ span: 12 }} xs={{ span: 12 }}>
        <input type="text" readOnly value={url} className="form-control" />
      </Col>
      <Col md={{ span: 'auto' }} sm={{ span: 6 }} xs={{ span: 6 }}>
        <CopyLink url={url} />
      </Col>
      <Col md={{ span: 'auto' }} sm={{ span: 6 }} xs={{ span: 6 }}>
        <DeleteLinkPrompt deleteSharedLink={props.deleteLink} sharedLink={props.sharedLink} />
      </Col>
    </Row>
  );
};

const CreateLink: React.FunctionComponent<LinkForm> = (props: LinkForm) => {
  return (
    <Row>
      <Col className="text-center">
        <GenerateLink generateLink={props.generateLink} />
      </Col>
    </Row>
  );
};

const LinkForm: React.FunctionComponent<LinkForm> = (props: LinkForm) => {
  const url = props.sharedLink?.url;

  let manageLink;
  if (!url) {
    // No link, show generate button as long as there's data
    manageLink = (
      <CreateLink deleteLink={props.deleteLink} generateLink={props.generateLink} sharedLink={props.sharedLink} />
    );
  } else {
    // Already have a link, show that
    manageLink = (
      <ModifyLink deleteLink={props.deleteLink} generateLink={props.generateLink} sharedLink={props.sharedLink} />
    );
  }

  return (
    <React.Fragment>
      <h2>Generate Link</h2>
      <Container>
        {instructions}
        {manageLink}
        {disclaimer}
      </Container>
    </React.Fragment>
  );
};

// TODO: wrap with something that gets the data and convert to function component
export const ManageSharedLink: React.FC = () => {
  const token = useToken();
  const [accountNumber] = useAccountNumber();
  const account = useSelectedAccount();
  const canManage = account?.canManageSharedLink;
  const [loading, setLoading] = useState(true);
  const [sharedLink, setSharedLink] = useState<SharedLink>();

  const updateSharedLink = function (data: SharedLink) {
    setSharedLink(data);
    setLoading(false);
  };

  const callApi = useCallback(
    (operation: 'get' | 'post' | 'delete') => {
      if (!token || !accountNumber) {
        return;
      }

      setLoading(true);
      callSharedLinkApi(token, accountNumber, updateSharedLink, operation);
    },
    [token, accountNumber]
  );

  useEffect(() => {
    callApi('get');
  }, [token, accountNumber, callApi]);

  const deleteLink = () => {
    callApi('delete');
  };

  const generateLink = () => {
    callApi('post');
  };

  if (!canManage) {
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  return <LinkForm sharedLink={sharedLink} deleteLink={deleteLink} generateLink={generateLink} />;
};
