import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { SmartRadioGroup } from '../SmartRadioGroup/SmartRadioGroup';

interface FileTypeProps {
  fileType: string;
  setFileType: (fileType: string) => void;
}

const choices = [
  {
    label: <React.Fragment>CSV &ndash; Spreadsheet format</React.Fragment>,
    value: 'csv',
  },
  {
    label: <React.Fragment>XML &ndash; Machine-readable format</React.Fragment>,
    value: 'xml',
  },
];

export const FileTypeOptions: React.FunctionComponent<FileTypeProps> = (props: FileTypeProps) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h5>File Type</h5>
        </Col>
      </Row>
      <SmartRadioGroup
        name="fileType"
        defaultChoice={props.fileType}
        choices={choices}
        onChoiceChange={props.setFileType}
      />
    </React.Fragment>
  );
};
