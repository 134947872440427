import { parseISO } from 'date-fns';
import { UrlDetails } from '../../util/AuthManager';
import { uniqueValues } from '../../util/utils';
import { PeakTimeSavingsResponse, PeakTimeSavingsEventRecord } from './PeakTimeSavingsData';

// June through September
const summerMonths = [6, 7, 8, 9];

export class PeakTimeSavingsEvent {
  readonly date: Date;
  readonly baseline: number;
  readonly actual: number;
  readonly reduced: number;
  readonly credit: number;
  readonly performanceVsAverage: number;
  readonly isSummer: boolean;
  readonly season: string;

  constructor(data: PeakTimeSavingsEventRecord) {
    this.date = parseISO(data.date);
    this.baseline = data.baseline;
    this.actual = data.actual;
    this.reduced = data.reduced;
    this.credit = data.credit;
    this.performanceVsAverage = data.performanceVsAverage;
    this.isSummer = summerMonths.includes(this.date.getMonth() + 1);
    this.season = this.isSummer ? 'summer' : 'nonsummer';
  }
}

function getEvents(data: PeakTimeSavingsEventRecord[]): PeakTimeSavingsEvent[] {
  return data.map((record) => {
    return new PeakTimeSavingsEvent(record);
  });
}

function sameSeason(events: PeakTimeSavingsEvent[], season: string): PeakTimeSavingsEvent[] {
  if (season === 'all') {
    return events;
  }

  return events.filter((event) => {
    return event.season === season;
  });
}

function sameYear(events: PeakTimeSavingsEvent[], year: string): PeakTimeSavingsEvent[] {
  return events.filter((event) => {
    return '' + event.date.getFullYear() === year;
  });
}

export class PeakTimeSavingsHelper {
  readonly data: PeakTimeSavingsResponse;
  readonly enrolled: boolean;
  readonly creditRate: number | undefined;
  readonly product: string | undefined;
  readonly url: UrlDetails;
  readonly events: PeakTimeSavingsEvent[];

  constructor(url: UrlDetails, data: PeakTimeSavingsResponse) {
    this.url = url;
    this.data = data;
    this.enrolled = data.enrolled;
    this.creditRate = data.creditRate;
    this.product = data.product;
    this.events = getEvents(data.events);
  }

  public years(): number[] {
    let yearList = this.events.map((event) => {
      return event.date.getFullYear();
    });
    yearList = uniqueValues(yearList);
    yearList = yearList.sort();

    return yearList;
  }

  public summerEvents(year: string): PeakTimeSavingsEvent[] {
    const filtered = sameYear(this.events, year);

    return filtered.filter((event) => {
      return event.isSummer;
    });
  }

  public nonSummerEvents(year: string): PeakTimeSavingsEvent[] {
    const filtered = sameYear(this.events, year);

    return filtered.filter((event) => {
      return event.isSummer;
    });
  }

  public seasons(year: string): string[] {
    // Only provide a choice if there's both winter and summer
    if (this.nonSummerEvents(year).length && this.summerEvents(year).length) {
      return ['all', 'nonsummer', 'summer'];
    }

    return ['all'];
  }

  public filter(year: string, season: string): PeakTimeSavingsEvent[] {
    let filtered = sameYear(this.events, year);
    filtered = sameSeason(filtered, season);

    // Sort by date
    filtered = filtered.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });

    return filtered;
  }
}
