import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { UsageData } from '../UsageData/UsageData';
import { formatDigits } from '../../util/utils';

function plural(value: number, unit: string): string {
  let text = value + ' ' + unit;
  if (value > 1) {
    text += 's';
  }
  return text;
}

function getMissingText(record: UsageData): JSX.Element {
  // All data is missing
  if (record.hasMissingData) {
    return <b>this {record.interval}</b>;
  }

  // Partial data is missing
  const days = Math.floor(record.missingHours / 24);
  const hours = record.missingHours % 24;

  const missing = new Array<string>();
  if (days) {
    missing.push(plural(days, 'day'));
  }
  if (hours) {
    missing.push(plural(hours, 'hour'));
  }
  const missingText = missing.join(' and ');

  return (
    <React.Fragment>
      <b>{missingText}</b> this {record.interval}
    </React.Fragment>
  );
}

function noDataMessage(record: UsageData): JSX.Element {
  const missingText = getMissingText(record);

  return (
    <React.Fragment>
      No usage data
      <br />
      is available for
      <br />
      {missingText}
    </React.Fragment>
  );
}

export function formatUsage(usageValue: number | null, range: [number, number]): string {
  if (usageValue === null) {
    return 'No Data';
  }

  // Don't return decimal places if over 100
  const min = range[0];
  if (min >= 100) {
    return formatDigits(0).format(usageValue);
  }

  // Return 2 decimal places if the largest value is 1
  // or if the value is small enough that it will otherwise round down to 0.0
  const max = range[1];
  if (max <= 1 || (usageValue > 0 && usageValue < 0.05)) {
    return formatDigits(2).format(usageValue);
  }

  return formatDigits(1).format(usageValue);
}

interface Props {
  record: UsageData | null;
  className: string;
  range: [number, number];
}

export function UsageCell(props: Props): JSX.Element | null {
  const record = props.record;
  const className = props.className;
  const range = props.range;

  // Didn't get back anything for this time period
  if (!record) {
    return <td className={className + ' noUsage'}>-</td>;
  }

  const usage = record.usage;
  const formatted = formatUsage(usage, range);

  if (record.hasPartialData) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tooltip = (props: any) => {
      return (
        <Popover {...props} aria-hidden="true">
          <Popover.Content>{noDataMessage(record)}</Popover.Content>
        </Popover>
      );
    };

    return (
      <td className={className + ' missingData'}>
        <OverlayTrigger overlay={tooltip} placement="top">
          {({ ref, ...triggerHandler }) => (
            <div {...triggerHandler}>
              {formatted}{' '}
              <span aria-hidden="true" ref={ref}>
                *
              </span>
              <span className="sr-only">{noDataMessage(record)}</span>
            </div>
          )}
        </OverlayTrigger>
      </td>
    );
  }

  return <td className={className}>{formatted}</td>;
}
