import React, { useState } from 'react';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { UsageTable } from '../../components/PeakTimeSavingsTable/UsageTable';
import { GraphLegend } from '../../components/PeakTimeSavingsGraph/GraphLegend';

import 'react-datepicker/dist/react-datepicker.css';
import './GraphSelection.scss';
import '../../components/PeakTimeSavingsGraph/PeakTimeGraph.scss';
import { Spinner } from '../../components/Spinner/Spinner';
import {
  PeakTimeSavingsHelper,
  PeakTimeSavingsEvent,
} from '../../components/PeakTimeSavingsData/PeakTimeSavingsHelper';
import { EventBars } from '../../components/PeakTimeSavingsGraph/PeakTimeSavingsBar';
import { format } from 'date-fns';
import { formatDigits } from '../../util/utils';
import { YearOptions } from '../../components/PeakTimeSavingsGraph/YearOptions';
import { SeasonOptions } from '../../components/PeakTimeSavingsGraph/SeasonOptions';
import { PeakTimeSavingsSummary } from '../../components/PeakTimeSavingsSummary/PeakTimeSavingsSummary';
import { config } from '../../util/config';
import { patternStripeBackward } from '../../components/Patterns';

interface Props {
  graphData: PeakTimeSavingsHelper | undefined;
  children?: React.ReactNode;
}

interface DisplayProps {
  graphData: PeakTimeSavingsHelper;
  children?: React.ReactNode;
}

function graphBars(events: PeakTimeSavingsEvent[]): JSX.Element[] {
  const max = axisMax(events);

  return events.map((event, index) => {
    return (
      <td key={index}>
        <EventBars baseline={event.baseline} actual={event.actual} axisMax={max} />
      </td>
    );
  });
}

function graphDates(events: PeakTimeSavingsEvent[]): JSX.Element[] {
  return events.map((event, index) => {
    return <td key={index}>{format(event.date, 'M/d')}</td>;
  });
}

function graphCredits(events: PeakTimeSavingsEvent[]): JSX.Element[] {
  return events.map((event, index) => {
    let digits = 2;
    if (event.credit >= 100) {
      digits = 0;
    }
    const credit = formatDigits(digits).format(event.credit);
    return <td key={index}>${credit}</td>;
  });
}

function axisMax(events: PeakTimeSavingsEvent[]): number {
  const values = events.map((event) => {
    return Math.max(event.baseline, event.actual);
  });

  const value = Math.ceil(Math.max(...values) * 1.1);
  if (value <= 4) {
    return 4;
  }

  const remainder = value % 4;
  return value + (4 - remainder);
}

function graphAxis(events: PeakTimeSavingsEvent[]): React.ReactElement {
  const max = axisMax(events);
  const ticks = [];
  const tickInterval = max / 4;
  for (let i = max; i >= 0; i -= tickInterval) {
    ticks.push(
      <div className="axisLabel" key={i}>
        {i}
      </div>
    );
    if (i > 0) {
      ticks.push(
        <div className="minus" key={i + '-minus'}>
          -
        </div>
      );
    }
  }

  return <div className="graphAxis">{ticks}</div>;
}

// TODO: rate should be dynamic
interface IntroProps {
  graphData: PeakTimeSavingsHelper | undefined;
  children?: React.ReactNode;
}

function manageUrl(product: string): string {
  return `${config.commerceNonCommodityBase}/dte/en/p/${product}`;
}

const Introduction: React.FunctionComponent<IntroProps> = (props: IntroProps) => {
  const creditRate = props.graphData?.creditRate;
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  let rateDetails = null;
  if (creditRate !== undefined) {
    rateDetails = (
      <React.Fragment>
        <b>You earn ${formatDigits(2).format(creditRate)} for each kWh reduced</b> from 3 p.m. to 7 p.m. during Peak
        Time Events.
      </React.Fragment>
    );
  }

  const product = props.graphData?.product;
  let manageLink = null;
  if (product !== undefined) {
    manageLink = (
      <React.Fragment>
        For more information and to manage your alert preferences, visit the{' '}
        <a href={manageUrl(product)}>Peak Time Savings page</a>.
      </React.Fragment>
    );
  }

  let preamble = (
    <React.Fragment>
      In the chart below, your <b>Actual</b> home energy use in kilowatt hours (kWh) during Peak Time Events is compared
      with your{' '}
      <Button
        className="inlineLink"
        variant="link"
        onClick={openModal}
        data-track="peakTimeSavings"
        data-track-detail="peakTimeSavings show-baseline-advisement"
        data-track-action="click"
        data-track-sub-action="show baseline-advisement"
      >
        Baseline kWh Use
      </Button>
      . The savings credit you earned shows below the comparison. You can also review your total savings and total kWh
      reduced during Peak Time Events and how your savings compares with the average savings of all program
      participants. {rateDetails}
    </React.Fragment>
  );
  // Alternate welcome text if no events yet
  if (!props.graphData?.events.length) {
    preamble = (
      <React.Fragment>
        Welcome to the Peak Time Savings program! {rateDetails} Following the first Peak Time Event, you will be able to
        review credits you’ve earned, total saving and total kWh reduced during Peak Time Events and how your savings
        compares with the average savings among all program participants.
      </React.Fragment>
    );
  }

  return (
    <Row>
      <Col>
        <h2>Review Your Progress</h2>
        <p className="pt-3">{preamble}</p>
        <p>
          There is a yearly maximum of 20 events. This pilot program runs through September 2023. {manageLink} If you
          have questions, please contact our Peak Time Savings Experts at <a href="tel: 833.699.1575">833.699.1575</a>.
        </p>
        <Modal
          className="baselineExplanation"
          centered
          size="lg"
          show={showModal}
          animation={false}
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Baseline kWh Use</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            We take your three highest kWh readings from 3 p.m. to 7 p.m. in the previous five weekdays (excluding
            holidays) before a Peak Time Event. The average of those readings is your <b>Baseline kWh Use</b>.
          </Modal.Body>
        </Modal>
        <hr />
      </Col>
    </Row>
  );
};

interface EventTableProps {
  events: PeakTimeSavingsEvent[];
  season: string;
  children?: React.ReactNode;
}

const noEvents = new Map<string, React.ReactElement>([
  [
    'all',
    <div className="peakSavingsGraphWrapper" key="all">
      <table className="peakSavingsGraph" style={{ width: '100%' }}>
        <thead aria-hidden="true">
          <tr>
            <th className="fixed units">kWh</th>
          </tr>
        </thead>
        <tbody>
          <tr className="peakSavingsEvents">
            <th className="fixed" aria-hidden="true">
              {graphAxis([])}
            </th>
            <td colSpan={7} className="noData">
              Return here to review your progress throughout the program.
            </td>
          </tr>
          <tr>
            <td colSpan={8} style={{ height: '1rem' }}></td>
          </tr>
          <tr className="eventCredits" aria-hidden="true">
            <th className="fixed">credit</th>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
          </tr>
        </tbody>
      </table>
    </div>,
  ],
  [
    'summer',
    <div className="peakSavingsGraphWrapper" key="summer">
      <table className="peakSavingsGraph" style={{ width: '100%' }}>
        <thead aria-hidden="true">
          <tr>
            <th className="fixed units">kWh</th>
          </tr>
        </thead>
        <tbody>
          <tr className="peakSavingsEvents">
            <th className="fixed" aria-hidden="true">
              {graphAxis([])}
            </th>
            <td className="noData">No events for Summer.</td>
          </tr>
          <tr>
            <td colSpan={2} style={{ height: '1rem' }}></td>
          </tr>
          <tr className="eventCredits" aria-hidden="true">
            <th className="fixed">credit</th>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>,
  ],
  [
    'nonsummer',
    <div className="peakSavingsGraphWrapper" key="nonsummer">
      <table className="peakSavingsGraph" style={{ width: '100%' }}>
        <thead aria-hidden="true">
          <tr>
            <th className="fixed units">kWh</th>
          </tr>
        </thead>
        <tbody>
          <tr className="peakSavingsEvents">
            <th className="fixed" aria-hidden="true">
              {graphAxis([])}
            </th>
            <td className="noData">No events for Non-Summer.</td>
          </tr>
          <tr>
            <td colSpan={2} style={{ height: '1rem' }}></td>
          </tr>
          <tr className="eventCredits" aria-hidden="true">
            <th className="fixed">credit</th>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>,
  ],
]);

export const EventTable: React.FunctionComponent<EventTableProps> = (props: EventTableProps) => {
  const events = props.events;
  const season = props.season;

  // Display placeholder
  if (!events.length) {
    return noEvents.get(season) || null;
  }

  const tableWidth = Math.min(events.length / 7, 1);
  const tableStyle = {
    width: formatDigits(2).format(tableWidth * 100) + '%',
  };

  return (
    <React.Fragment>
      <GraphLegend />
      <div className="peakSavingsGraphWrapper">
        <table className="peakSavingsGraph" style={tableStyle}>
          <thead>
            <tr>
              <th className="fixed units">kWh</th>
            </tr>
          </thead>
          <tbody>
            <tr className="peakSavingsEvents">
              <th className="fixed">{graphAxis(events)}</th>
              {graphBars(events)}
            </tr>
            <tr className="eventDates">
              <th className="fixed">
                <span className="sr-only">Date</span>
              </th>
              {graphDates(events)}
            </tr>
            <tr className="eventCredits">
              <th className="fixed">credit</th>
              {graphCredits(events)}
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export const DataDisplay: React.FunctionComponent<DisplayProps> = (props: DisplayProps) => {
  const graphData = props.graphData;
  const years = graphData.years();
  const [year, setYear] = useState('' + Math.max(...years));

  const seasons = graphData.seasons(year);
  const [season, setSeason] = useState<string>('all');
  const events = props.graphData.filter(year, season);

  let selection = null;
  if (graphData.events.length) {
    selection = (
      <Form className="usageOptions">
        <Form.Row>
          <YearOptions selectedYear={year} setYear={setYear} years={years} />
          <SeasonOptions selectedSeason={season} setSeason={setSeason} seasons={seasons} />
        </Form.Row>
      </Form>
    );
  }

  return (
    <React.Fragment>
      <Introduction graphData={graphData} />
      {selection}
      <EventTable events={events} season={season} />
      <PeakTimeSavingsSummary events={events} />
      <UsageTable events={events} />
    </React.Fragment>
  );
};

function _Patterns(): JSX.Element {
  return (
    <svg className="patterns" aria-hidden="true">
      <defs>{patternStripeBackward('baseline', 'baseline')}</defs>
    </svg>
  );
}
const Patterns = React.memo(_Patterns);

export const GraphSelection: React.FunctionComponent<Props> = (props: Props) => {
  if (!props.graphData) {
    return (
      <React.Fragment>
        <Introduction graphData={undefined} />
        <Spinner className="peakSavingsEvents" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Patterns />
          <DataDisplay graphData={props.graphData} />
        </Col>
      </Row>
    </React.Fragment>
  );
};
