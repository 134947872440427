import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { useProductType } from '../../hooks/parameters/useProductType';
import { formatISO } from '../../util/dates';
import { getHref } from '../../util/url';
import './DownloadDataInfo.scss';

export function useDownloadLink(): string {
  const [accountNumber] = useAccountNumber();
  const [{ start: startDate, end: endDate }] = useSelectedDateRange();
  const [productType] = useProductType();

  const start = formatISO(startDate);
  const end = formatISO(endDate);

  const params = {
    startDate: start,
    endDate: end,
    productType: productType,
    accountNumber: accountNumber,
  };

  const link = getHref('./download', params);
  return link;
}

export const DownloadDataInfo: React.FunctionComponent = () => {
  const link = useDownloadLink();

  return (
    <Row>
      <Col>
        <Container className="downloadDataInfo">
          <Row>
            <Col xs="auto">
              <h3>Download or share usage data</h3>
              Your usage report can be generated for download or third-party sharing.
            </Col>
            <Col className="text-center">
              <Link
                to={link}
                className="btn btn-primary align-middle"
                data-track="usage"
                data-track-detail="usage report"
                data-track-action="click"
                data-track-sub-action="download data get started"
              >
                Get Started
              </Link>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};
