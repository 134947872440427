import queryString from 'query-string';
import { To, useLocation } from 'react-router-dom';

export function useNewLink(pathname?: string, params?: Record<string, string>): To {
  const location = useLocation();

  let newPath = location.pathname;
  if (pathname?.length) {
    newPath = pathname;
  }

  let search = location.search;
  if (params) {
    const newParams = { ...queryString.parse(location.search), ...params };
    search = `?${queryString.stringify(newParams)}`;
  }

  const newLink = {
    pathname: newPath,
    search: search,
  };
  return newLink;
}
