import { useValidatedStringQueryParam } from './useValidatedStringQueryParam';

function isValidAccountNumber(value: string): boolean {
  const normalized = value?.trim();

  if (normalized?.length !== 12) {
    return false;
  }

  if (!Number(normalized)) {
    return false;
  }

  return true;
}

export function useAccountNumber(): [string, (string) => void] {
  const [accountNumber, setAccountNumber] = useValidatedStringQueryParam('accountNumber', isValidAccountNumber);
  return [accountNumber, setAccountNumber];
}
