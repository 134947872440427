import React from 'react';
import { formatDigits } from '../../util/utils';

const temperatureFormat = formatDigits(0);

function renderHourlyTemperature(average: number | undefined): JSX.Element | null {
  const avgString = average ? temperatureFormat.format(average) + '°' : '-';

  return <td className="temperature">{avgString}</td>;
}

function renderTemperatureRange(low: number | undefined, high: number | undefined): JSX.Element | null {
  const lowString = low ? temperatureFormat.format(low) + '°' : '-';
  const highString = high ? temperatureFormat.format(high) + '°' : '-';

  return (
    <React.Fragment>
      <td className="temperature low">
        <span>{lowString}</span>
      </td>
      <td className="temperature high">
        <span>{highString}</span>
      </td>
    </React.Fragment>
  );
}

interface Props {
  interval: string;
  high?: number;
  low?: number;
  average?: number;
}
export function TemperatureCell(props: Props): JSX.Element | null {
  const interval = props.interval;

  if (interval === 'hour') {
    return renderHourlyTemperature(props.average);
  }

  return renderTemperatureRange(props.low, props.high);
}
