import memoize from 'fast-memoize';
import { sum } from 'd3-array';

export function midpoint(start: number, end: number): number {
  return Math.round((start + end) / 2);
}

function _formatDigits(digits: number): Intl.NumberFormat {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    useGrouping: true,
  });
}
export const formatDigits = memoize(_formatDigits);

export const costFormat = formatDigits(2);

export function roundByRange(dataValue: number, range: number): string {
  let rounded = Math.round(dataValue * 100) / 100;

  if (rounded && range < 2) {
    return formatDigits(2).format(dataValue);
  }

  rounded = Math.round(dataValue * 10) / 10;
  if (!rounded || range > 10) {
    return formatDigits(0).format(dataValue);
  }

  return formatDigits(1).format(dataValue);
}

export function roundCostByRange(dataValue: number, range: number): string {
  if (range < 40) {
    return formatDigits(2).format(dataValue);
  }

  return formatDigits(0).format(dataValue);
}

export function createUUID(): string {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function notNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function uniqueValues<T>(values: T[]): T[] {
  return values.filter((item, index) => values.indexOf(item) === index);
}

/**
 * JSON stringify for 2 values are equal
 *
 * @param valueA
 * @param valueB
 */
function _jsonEqual<T>(valueA: T | null | undefined, valueB: T | null | undefined): boolean {
  return JSON.stringify(valueA) === JSON.stringify(valueB);
}
export const jsonEqual = memoize(_jsonEqual);

export function sumOrNull(values: Array<number | null | undefined>): number | null {
  const filtered = values.filter(notNullOrUndefined);
  if (!filtered.length) {
    return null;
  }
  const hasNulls = filtered.length < values.length;
  const total = sum(filtered) || 0;
  if (!total && hasNulls) {
    return null;
  }

  return total;
}

export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
