import React, { useContext } from 'react';
import { Row, Col, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AccountContext } from '../../contexts/AccountContext/AccountContext';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { useProductType } from '../../hooks/parameters/useProductType';
import { config } from '../../util/config';
import { getHref } from '../../util/url';
import './Header.scss';

interface Props {
  pageName: string;
  includeLinks: boolean;
}

export const Navigation: React.FunctionComponent = () => {
  const [accountNumber] = useAccountNumber();
  const accountContext = useContext(AccountContext);
  const accountList = accountContext.accountList;
  const [productType] = useProductType();

  if (!accountList) {
    return null;
  }

  // TODO: transfer date selection between things
  const params: Record<string, string> = {
    accountNumber: accountNumber,
  };

  const links = [];

  if (accountList?.electricAccounts) {
    const electricParams = {
      ...params,
      interval: 'day',
    };
    const electricDashboardLink = getHref('./electricDashboard', electricParams);
    links.push(
      <Link key="electric" to={electricDashboardLink} className="nav-link">
        Electric Dashboard
      </Link>
    );

    // Can't have AMI gas without AMI electric
    if (accountList?.gasAccounts) {
      const gasParams = {
        ...params,
        interval: 'day',
      };
      const gasDashboardLink = getHref('./gasDashboard', gasParams);
      links.push(
        <Link key="gas" to={gasDashboardLink} className="nav-link">
          Gas Dashboard
        </Link>
      );
    }

    const downloadParams = {
      ...params,
      productType: productType,
    };
    const downloadLink = getHref('./download', downloadParams);
    links.push(
      <Link key="download" to={downloadLink} className="nav-link">
        Download Data
      </Link>
    );
  }

  return (
    <div className="menu">
      <Navbar className="justify-content-center">
        <Nav>{links}</Nav>
      </Navbar>
    </div>
  );
};

export const Header: React.FunctionComponent<Props> = (props: Props) => {
  const pageName = props.pageName;

  // Also update document title when we update header
  document.title = `${pageName} | DTE Energy`;

  let navigation = null;
  if (props.includeLinks) {
    navigation = <Navigation />;
  }

  return (
    <React.Fragment>
      <div className="header">
        <Row className="align-items-center" noGutters={true}>
          <Col sm={{ order: 1 }} xs={{ span: 12, order: 2 }}>
            <h1>{pageName}</h1>
          </Col>
          <Col sm={{ span: 'auto', order: 2 }} xs={{ span: 12, order: 1 }}>
            <a href={config.dteHomepage} className="logo">
              <span className="sr-only">DTE Energy Homepage</span>
            </a>
          </Col>
          <Col className="backNavigation text-right d-none d-sm-block" sm={{ order: 3 }}>
            <a className="link" href={config.webBase}>
              Visit dteenergy.com
            </a>
          </Col>
        </Row>
      </div>
      {navigation}
    </React.Fragment>
  );
};
