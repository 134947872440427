import React from 'react';
import { path } from 'd3-path';
import { createUUID, jsonEqual } from '../../util/utils';

const graphHeight = 200;

function barPath(height: number, width: number, x: number, adjustBottom: boolean): string {
  const strokeWidth = 3;
  const radius = 3;

  if (height < radius) {
    height = radius + 2 * strokeWidth;
  }

  let bottom = graphHeight;
  if (adjustBottom) {
    bottom -= 2;
  }
  const top = graphHeight - height + strokeWidth;
  const left = x + strokeWidth;
  const right = x + width - strokeWidth;

  const barPath = path();
  barPath.moveTo(left, bottom);
  if (height < radius) {
    barPath.lineTo(left, top);
    barPath.lineTo(right, top);
    barPath.lineTo(right, bottom);
  } else {
    barPath.lineTo(left, top + radius);
    barPath.quadraticCurveTo(left, top, left + radius, top);
    barPath.lineTo(right - radius, top);
    barPath.quadraticCurveTo(right, top, right, top + radius);
    barPath.lineTo(right, bottom);
  }
  barPath.closePath();

  return barPath.toString();
}

interface Props {
  baseline: number;
  actual: number;
  axisMax: number;
  childNodes?: React.ReactNode;
}

const _EventBars: React.FunctionComponent<Props> = (props: Props) => {
  const baseline = props.baseline;
  const actual = props.actual;
  const axisMax = props.axisMax;

  const baselinePath = barPath((baseline / axisMax) * graphHeight, 30, 0, true);
  const baselineBar = <path className="baseline" d={baselinePath} fill={'url(#pattern-stripe-backward-baseline)'} />;

  const actualPath = barPath((actual / axisMax) * graphHeight, 36, 15, false);
  const actualBar = <path className="actual" d={actualPath} />;

  const descriptionId = 'savingsBar-' + createUUID();

  return (
    <React.Fragment>
      <svg
        className="peakSavingsEvent"
        viewBox="0 0 50 200"
        preserveAspectRatio="none"
        aria-describedby={descriptionId}
      >
        {baselineBar}
        {actualBar}
      </svg>
      <div id={descriptionId} className="sr-only">
        Baseline: {baseline} kilowatt hours
        <br />
        Actual: {actual} kilowatt hours
      </div>
    </React.Fragment>
  );
};
export const EventBars = React.memo(_EventBars, jsonEqual);
