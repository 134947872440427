import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';
import { config } from './config';

// Production config
let clientId = '1982c12f-e9bc-4ea7-b870-df5cc209483e';
let tenantId = '8e61d5fe-7749-4e76-88ee-6d8799ae8143';
let redirectUri = 'https://usage.dteenergy.com/cr/shiftandsave';

if (!config.isProd) {
  // Test config
  clientId = 'b91bcf19-302f-4ef5-8a18-786d9e14e610';
  tenantId = '8e61d5fe-7749-4e76-88ee-6d8799ae8143';
  redirectUri = 'https://' + window?.location?.hostname + '/cr/shiftandsave';
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: 'https://login.microsoftonline.com/' + tenantId,
    // TODO: allow for either /cr/ or /cr/shiftandsave
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['openid'],
  extraQueryParameters: { domain_hint: 'dteenergy.com' },
};
