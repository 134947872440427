export interface ApiAddress {
  addressLine1: string;
  addressLine2: string | undefined;
  city: string;
  state: string;
  zipcode: string;
}

function capitalizeAddressPart(value: string | undefined | null): string {
  let returnValue = value || '';

  // TODO: improve upon how we're handling capitalization
  returnValue = returnValue
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return returnValue;
}

function state(address: ApiAddress) {
  return address.state?.toUpperCase() || '';
}

function zipcode(address: ApiAddress): string {
  return address.zipcode?.substring(0, 5) || '';
}

function formatCondensed(address: ApiAddress): string {
  // If we don't have an address, don't try to display one
  if (!address) {
    return '';
  }

  const addressParts = [];
  addressParts.push(capitalizeAddressPart(address.addressLine1));
  const line2 = address.addressLine2;
  if (line2) {
    addressParts.push(', ');
    addressParts.push(capitalizeAddressPart(line2));
  }
  addressParts.push(', ');
  addressParts.push(capitalizeAddressPart(address.city));
  addressParts.push(', ');
  addressParts.push(state(address));
  addressParts.push(' ');
  addressParts.push(zipcode(address));

  return addressParts.join('');
}

export class Address {
  readonly addressLine1: string = '';
  readonly addressLine2: string = '';
  readonly city: string = '';
  readonly state: string = '';
  readonly zipcode: string = '';
  readonly formatCondensed: string = '';

  constructor(address: ApiAddress) {
    if (address) {
      this.addressLine1 = capitalizeAddressPart(address.addressLine1);
      this.addressLine2 = capitalizeAddressPart(address.addressLine2);
      this.city = capitalizeAddressPart(address.city);
      this.state = state(address);
      this.zipcode = zipcode(address);
      this.formatCondensed = formatCondensed(address);
    }
  }

  public matchesFilter(filter: string): boolean {
    if (!filter) {
      return true;
    }

    return this.formatCondensed.toUpperCase().includes(filter);
  }
}
