import React from 'react';
import { Account } from '../../components/AccountList/account';
import { AccountList } from '../../components/AccountList/AccountList';

export interface AccountContextType {
  accountList?: AccountList;
  filteredAccountList?: AccountList;
  account?: Account;
}

export const AccountContext = React.createContext<AccountContextType>({});
