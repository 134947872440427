import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { PeakTimeSavingsEvent } from '../PeakTimeSavingsData/PeakTimeSavingsHelper';
import './PeakTimeSavingsSummary.scss';

// TODO: ensure that totals add up correctly after rounding
const formatDigits = (digits: number): Intl.NumberFormat => {
  let rounded = digits;

  if (digits === 0) {
    rounded = Math.round(digits);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: rounded,
    minimumFractionDigits: rounded,
    useGrouping: true,
  });
};

const usageUnit = (
  <React.Fragment>
    <span className="sr-only">kilowatt hours</span>
    <span aria-hidden="true">kWh</span>
  </React.Fragment>
);

interface Props {
  events: PeakTimeSavingsEvent[];
}

function spacer(): JSX.Element {
  const breakpoint = '-lg';
  const className = 'd' + breakpoint + '-none';
  return <Col xs={{ span: 12 }} className={className}></Col>;
}

function rebateValue(events: PeakTimeSavingsEvent[]): number {
  let total = 0;

  for (const event of events) {
    total += event.credit;
  }

  return total;
}

function formatRebate(events: PeakTimeSavingsEvent[]): JSX.Element {
  const total = rebateValue(events);

  // Round values > $100
  let digits = 2;
  if (total > 100) {
    digits = 0;
  }

  let value = formatDigits(digits).format(total);
  value = '$' + value;

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }} className="icon rebate">
        <span className="value">{value}</span>
        <br />
        <span>Savings</span>
      </Col>
    </React.Fragment>
  );
}

function reducedValue(events: PeakTimeSavingsEvent[]): number {
  let total = 0;

  for (const event of events) {
    total += event.reduced;
  }

  return total;
}

function formatReduced(events: PeakTimeSavingsEvent[]): JSX.Element {
  const total = reducedValue(events);
  const value = formatDigits(0).format(total);

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }} className="icon electricReduced">
        <span className="value">{value}</span> <span className="units">{usageUnit}</span>
        <br />
        <span>Reduced</span>
      </Col>
    </React.Fragment>
  );
}

function comparisonValue(events: PeakTimeSavingsEvent[]): number {
  let total = 0;

  for (const event of events) {
    total += event.performanceVsAverage;
  }

  const average = total / (events.length || 1);

  return Math.round(average);
}

function formatComparison(events: PeakTimeSavingsEvent[]): JSX.Element {
  const total = comparisonValue(events);
  let value;

  // Cap the value at 999
  if (total >= 1000) {
    value = '999+';
  } else if (total < 0) {
    value = formatDigits(0).format(-1 * total);
  } else {
    value = formatDigits(0).format(total);
  }
  value = value + '%';

  let description = 'Above Average';
  let className = 'icon comparePositive';
  if (total < 0) {
    className = 'icon compareNegative';
    description = 'Below Average';
  }

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }} className={className}>
        <span className="value">{value}</span>
        <br />
        <span>{description}</span>
      </Col>
    </React.Fragment>
  );
}

export const PeakTimeSavingsSummary: React.FunctionComponent<Props> = (props: Props) => {
  const events = props.events;

  const breakpoint = '-lg';
  let className = '';
  className += ' justify-content' + breakpoint + '-around';
  className += ' break' + breakpoint;

  let wrapperClass = 'peakTimeSavingsSummary';
  wrapperClass += ' justify-content-center';

  return (
    <div className={wrapperClass}>
      <Row className={className}>
        {formatRebate(events)}
        {spacer()}
        {formatReduced(events)}
        {spacer()}
        {formatComparison(events)}
      </Row>
    </div>
  );
};
