import React from 'react';
import { SelectAccountModal } from './SelectAccountModal';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';

export const SelectAccount: React.FunctionComponent = () => {
  const account = useSelectedAccount();
  if (!account) {
    return null;
  }

  return (
    <React.Fragment>
      <h5>Account Number</h5>
      {account.formattedAccountNumber}
      <SelectAccountModal />
    </React.Fragment>
  );
};
