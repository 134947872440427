import React from 'react';
import { Spinner } from '../../components/Spinner/Spinner';
import { TokenContext } from './TokenContext';
import { AccountContextWrapper } from '../AccountContext/AccountContextWrapper';

interface Props {
  token: string;
  children?: React.ReactNode;
}

export const TokenContextWrapper: React.FC<Props> = (props) => {
  if (!props.token) {
    return <Spinner size="4x" className="fullPageSpinner" />;
  }

  const context = {
    token: props.token,
  };

  return (
    <React.Fragment>
      <TokenContext.Provider value={context}>
        <AccountContextWrapper>{props.children}</AccountContextWrapper>
      </TokenContext.Provider>
    </React.Fragment>
  );
};
