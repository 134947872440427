import { useEffect, useState } from 'react';
import { UsageGraphHelper } from './TimeOfUseDataHelper';
import { UrlDetails, useAuthenticatedJsonGet } from '../../util/AuthManager';
import { config } from '../../util/config';
import { validateAccountNumber, validateProductType, validateInterval } from '../../util/validation';
import { formatISO } from '../../util/dates';
import { jsonEqual } from '../../util/utils';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { useInterval } from '../../hooks/parameters/useInterval';
import { useProductType } from '../../hooks/parameters/useProductType';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { UsageRecord } from '../UsageData/UsageRecord';
import { useMeterNumber } from '../../hooks/parameters/useMeterNumber';

interface ApiResponse {
  usage: UsageRecord[];
}

function useURL(): UrlDetails | null {
  const [url, setURL] = useState<UrlDetails | null>(null);
  const [accountNumber] = useAccountNumber();
  const [meterNumber] = useMeterNumber();
  const [interval] = useInterval();
  const [productType] = useProductType();
  const [{ start: startDate, end: endDate }] = useSelectedDateRange();

  useEffect(() => {
    try {
      const validAccountNumber = validateAccountNumber(accountNumber);
      // TODO: validate meter number
      const validMeterNumber = meterNumber;
      const validProductType = validateProductType(productType);
      const validInterval = validateInterval(interval, validProductType);
      if (!startDate) {
        setURL(null);
        return;
      }

      if (!endDate) {
        setURL(null);
        return;
      }

      let url = `${config.apiBase}/authenticated/accounts/${validAccountNumber}/usage/intervals/${validInterval}/${validProductType}`;
      if (validMeterNumber) {
        url += `/meters/${validMeterNumber}`;
      }
      url += '/timeOfDay';

      const params = {
        startDate: formatISO(startDate),
        endDate: formatISO(endDate),
      };
      setURL({
        url: url,
        params: params,
      });
      return;
    } catch {
      // At least one parameter was invalid
    }

    setURL(null);
  }, [accountNumber, meterNumber, productType, interval, startDate, endDate]);

  return url;
}

export function useTimeOfDayData(): UsageGraphHelper | undefined {
  const [interval] = useInterval();
  const url = useURL();
  const [results, loading] = useAuthenticatedJsonGet<ApiResponse>(url);
  const [usage, setUsage] = useState<UsageGraphHelper | undefined>(undefined);

  useEffect(() => {
    if (url) {
      // Don't do anything until we have results
      const data = results?.usage || [];
      setUsage(new UsageGraphHelper(url, interval, data));
    } else {
      setUsage(undefined);
    }
  }, [results, url, interval]);

  if (loading || !jsonEqual(usage?.url, url)) {
    return undefined;
  }

  return usage;
}
