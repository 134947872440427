import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useMode } from '../../hooks/parameters/useMode';
import { UsageData } from '../TimeOfUseData/TimeOfUseData';
import { UsageGraphHelper } from '../TimeOfUseData/TimeOfUseDataHelper';
import { formatUsage } from '../TimeOfUseTable/UsageCell';
import './UsageSummary.scss';

// TODO: ensure that totals add up correctly after rounding
const formatDigits = (digits: number): Intl.NumberFormat => {
  let rounded = digits;

  if (digits === 0) {
    rounded = Math.round(digits);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: rounded,
    minimumFractionDigits: rounded,
    useGrouping: true,
  });
};

export function totalCost(data: UsageData[]): number {
  let total = 0;

  for (const record of data) {
    total += record.costEstimate || 0;
  }

  return total;
}

export function totalUsage(data: UsageData[]): number {
  let total = 0;

  const values = data.map((record) => record.usage || 0);
  const min = Math.min(...values);
  const max = Math.max(...values);

  // Need to add together the rounded values that the customer sees
  for (const value of values) {
    const usage = parseFloat(formatUsage(value, [min, max]));
    total += usage;
  }

  // Round total usage here so individual values add up correctly
  return Math.round(total);
}

const usageUnit = (
  <React.Fragment>
    <span className="sr-only">kilowatt hours</span>
    <span aria-hidden="true">kWh</span>
  </React.Fragment>
);

interface Props {
  usage: UsageGraphHelper;
}

function columnCount(values: SummaryValues): number {
  // At least one for the total
  let count = 1;

  if (hasTimeOfUseData(values)) {
    count += 2;
  }

  if (hasStandardData(values)) {
    count += 1;
  }

  return count;
}

function getBreakpoint(values: SummaryValues): string {
  const columns = columnCount(values);

  if (columns >= 3) {
    return '-lg';
  }

  if (columns === 2) {
    return '-sm';
  }

  // No breakpoint needed for only 1 column
  return '';
}

function spacer(values: SummaryValues): JSX.Element {
  let spacer = null;

  const columns = columnCount(values);
  let breakpoint = '';
  if (columns >= 3) {
    breakpoint = '-lg';
  }

  if (columns === 2) {
    breakpoint = '-md';
  }

  let className = '';
  if (breakpoint) {
    className = 'd' + breakpoint + '-none';
    spacer = <Col xs={{ span: 12 }} className={className}></Col>;
  }

  return spacer;
}

function formatSummaryBlock(
  mode: string,
  total: number,
  label: string,
  className: string,
  digits: number
): JSX.Element {
  let value = formatDigits(digits).format(total);

  let modeDescription;
  let units = null;

  if (mode === 'usage') {
    modeDescription = 'Used';
    units = <span className="units">{usageUnit}</span>;
  } else {
    value = '$' + value;
    modeDescription = 'Cost';
  }

  const name = 'icon ' + className;

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }} className={name}>
        <span className="value">{value}</span> {units}
        <br />
        <span className="sr-only">
          {label} {modeDescription}
        </span>
        <span aria-hidden="true">
          {label}
          <span className="d-none d-sm-inline"> {modeDescription}</span>
        </span>
      </Col>
    </React.Fragment>
  );
}

function summaryPeak(mode: string, values: SummaryValues): JSX.Element {
  if (values.superOffPeak === undefined && values.offpeak === undefined && values.peak === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      {formatSummaryBlock(mode, values.peak || 0, 'Peak', 'peak', values.digits)}
      {spacer(values)}
    </React.Fragment>
  );
}

function summarySuperOffpeak(mode: string, values: SummaryValues): JSX.Element {
  if (values.superOffPeak === undefined && values.offpeak === undefined && values.peak === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      {formatSummaryBlock(mode, values.superOffPeak || 0, 'Super Off-Peak', 'superOffpeak', values.digits)}
      {spacer(values)}
    </React.Fragment>
  );
}

function summaryOffpeak(mode: string, values: SummaryValues): JSX.Element {
  if (values.superOffPeak === undefined && values.offpeak === undefined && values.peak === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      {formatSummaryBlock(mode, values.offpeak || 0, 'Off-Peak', 'offpeak', values.digits)}
      {spacer(values)}
    </React.Fragment>
  );
}

function summaryStandard(mode: string, values: SummaryValues): JSX.Element {
  if (values.standard === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      {formatSummaryBlock(mode, values.standard || 0, 'Other', 'standard', values.digits)}
      {spacer(values)}
    </React.Fragment>
  );
}

function summaryTotal(mode: string, values: SummaryValues): JSX.Element {
  if (values.total === undefined) {
    return null;
  }

  return (
    <React.Fragment>{formatSummaryBlock(mode, values.total || 0, 'Total', 'total', values.digits)}</React.Fragment>
  );
}

function totalValue(mode: string, data: UsageData[]): number {
  if (mode === 'usage') {
    return totalUsage(data);
  }
  return totalCost(data);
}

// Needed to ensure that everything adds up properly
function displayValue(value: number | undefined, digits: number): number {
  const numberString = (value || 0).toFixed(digits);

  return Number.parseFloat(numberString);
}

function largeZeroOrUndefined(value: number | undefined): boolean {
  if (!value) {
    return true;
  }

  return value > 100;
}

function costDigits(values: SummaryValues): number {
  // Round if the total cost is more than $1,000
  if (values.total && values.total > 1000) {
    return 0;
  }

  // Lowest cost is > $100
  if (
    largeZeroOrUndefined(values.offpeak) &&
    largeZeroOrUndefined(values.peak) &&
    largeZeroOrUndefined(values.standard)
  ) {
    return 0;
  }

  return 2;
}

interface SummaryValues {
  superOffPeak?: number;
  offpeak?: number;
  peak?: number;
  standard?: number;
  total?: number;
  digits: number;
}
function summaryValues(mode: string, usage: UsageGraphHelper): SummaryValues {
  const values: SummaryValues = {
    digits: 4,
  };

  let total = 0;

  if (usage.hasTimeOfUseData) {
    values.superOffPeak = totalValue(mode, usage.superOffpeakUsage)
    values.offpeak = totalValue(mode, usage.offpeakUsage);
    values.peak = totalValue(mode, usage.peakUsage);
    total += values.superOffPeak;
    total += values.offpeak;
    total += values.peak;
  }

  if (usage.standardUsage.length) {
    values.standard = totalValue(mode, usage.standardUsage);
    total += values.standard;
  }

  values.total = total;

  return values;
}

function displayDigits(mode: string, values: SummaryValues): number {
  let digits = 0;
  if (mode === 'cost') {
    digits = costDigits(values);
  }
  return digits;
}

function hasTimeOfUseData(values: SummaryValues): boolean {
  return values.peak !== undefined || values.offpeak !== undefined;
}

function hasStandardData(values: SummaryValues): boolean {
  return values.standard !== undefined;
}

function displayValues(mode: string, usage: UsageGraphHelper): SummaryValues {
  const values = summaryValues(mode, usage);

  const digits = displayDigits(mode, values);
  const display: SummaryValues = { digits: digits };

  if (hasTimeOfUseData(values)) {
    const superOffPeak = displayValue(values.superOffPeak, digits);
    const offpeak = displayValue(values.offpeak, digits);
    const peak = displayValue(values.peak, digits);

    display.superOffPeak = superOffPeak;
    display.offpeak = offpeak;
    display.peak = peak;
  }

  display.total = displayValue(values.total, digits);

  return display;
}

export const UsageSummary: React.FunctionComponent<Props> = (props: Props) => {
  const usage = props.usage;
  const [mode] = useMode();

  if (!usage) {
    return null;
  }

  const values = displayValues(mode, usage);

  const breakpoint = getBreakpoint(values);
  let className = 'electric';
  className += ' justify-content' + breakpoint + '-around';
  className += ' break' + breakpoint;

  let wrapperClass = 'usageSummary';
  wrapperClass += ' justify-content-center';

  return (
    <div className={wrapperClass}>
      <Row className={className}>
        {summarySuperOffpeak(mode, values)}
        {summaryOffpeak(mode, values)}
        {summaryPeak(mode, values)}
        {summaryTotal(mode, values)}
      </Row>
    </div>
  );
};
