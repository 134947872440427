import { getDistance } from 'geolib';

// Longitude, Latitude for zipcodes in Michigan
const zipcodes = new Map<string, [number, number]>([
  ['48001', [-82.554, 42.631359]],
  ['48002', [-82.91582, 42.938385]],
  ['48003', [-83.04585, 42.931178]],
  ['48004', [-82.66522, 42.824095]],
  ['48005', [-82.91471, 42.842554]],
  ['48006', [-82.69327, 43.062285]],
  ['48007', [-83.297593, 42.606088]],
  ['48009', [-83.21527, 42.544084]],
  ['48012', [-83.292382, 42.604425]],
  ['48014', [-82.93255, 43.01185]],
  ['48015', [-83.02449, 42.479137]],
  ['48017', [-83.15112, 42.535534]],
  ['48019', [-83.612581, 42.755627]],
  ['48021', [-82.94641, 42.466086]],
  ['48022', [-82.79404, 43.013805]],
  ['48023', [-82.66859, 42.693915]],
  ['48025', [-83.25454, 42.524134]],
  ['48026', [-82.95044, 42.536402]],
  ['48027', [-82.68571, 42.952553]],
  ['48028', [-82.62325, 42.563129]],
  ['48030', [-83.09819, 42.459022]],
  ['48032', [-82.59617, 43.125531]],
  ['48033', [-83.2827, 42.4621]],
  ['48034', [-83.29078, 42.474234]],
  ['48035', [-82.90786, 42.554134]],
  ['48036', [-82.89837, 42.587117]],
  ['48037', [-83.149978, 42.567543]],
  ['48038', [-82.94459, 42.603172]],
  ['48039', [-82.50811, 42.710445]],
  ['48040', [-82.47973, 42.910198]],
  ['48041', [-82.80345, 42.93281]],
  ['48042', [-82.92958, 42.68347]],
  ['48043', [-82.88031, 42.596784]],
  ['48044', [-82.93416, 42.650265]],
  ['48045', [-82.83063, 42.582961]],
  ['48046', [-82.903065, 42.672322]],
  ['48047', [-82.77754, 42.670932]],
  ['48048', [-82.79758, 42.739751]],
  ['48049', [-82.59251, 43.03154]],
  ['48050', [-82.8092, 42.786198]],
  ['48051', [-82.83068, 42.682081]],
  ['48054', [-82.52595, 42.769956]],
  ['48059', [-82.48614, 43.088742]],
  ['48060', [-82.44402, 42.978974]],
  ['48061', [-82.66522, 42.824095]],
  ['48062', [-82.77538, 42.829226]],
  ['48063', [-82.68244, 42.85452]],
  ['48064', [-82.67684, 42.773535]],
  ['48065', [-83.02963, 42.826805]],
  ['48066', [-82.93851, 42.503285]],
  ['48067', [-83.13752, 42.488735]],
  ['48068', [-83.3863, 42.660091]],
  ['48069', [-83.14051, 42.472235]],
  ['48070', [-83.16812, 42.482585]],
  ['48071', [-83.10474, 42.501385]],
  ['48072', [-83.18532, 42.497035]],
  ['48073', [-83.16357, 42.518635]],
  ['48074', [-82.57049, 42.948521]],
  ['48075', [-83.2264, 42.465275]],
  ['48076', [-83.22829, 42.499335]],
  ['48079', [-82.50425, 42.833034]],
  ['48080', [-82.90232, 42.462036]],
  ['48081', [-82.89979, 42.497736]],
  ['48082', [-82.88809, 42.526965]],
  ['48083', [-83.11506, 42.556943]],
  ['48084', [-83.18405, 42.563505]],
  ['48085', [-83.116567, 42.597323]],
  ['48086', [-83.3863, 42.660091]],
  ['48088', [-82.982757, 42.516851]],
  ['48089', [-82.99866, 42.467086]],
  ['48090', [-82.903065, 42.672322]],
  ['48091', [-83.056, 42.465886]],
  ['48092', [-83.0597, 42.511293]],
  ['48093', [-82.99768, 42.514885]],
  ['48094', [-83.03847, 42.727731]],
  ['48095', [-83.02605, 42.779239]],
  ['48096', [-82.91658, 42.753378]],
  ['48097', [-82.81411, 43.131709]],
  ['48098', [-83.15235, 42.599133]],
  ['48099', [-83.173666, 42.587643]],
  ['48101', [-83.21027, 42.254788]],
  ['48103', [-83.79147, 42.280887]],
  ['48104', [-83.72297, 42.266805]],
  ['48105', [-83.70443, 42.307288]],
  ['48106', [-83.836571, 42.253502]],
  ['48107', [-83.836571, 42.253502]],
  ['48108', [-83.72671, 42.232807]],
  ['48109', [-83.71831, 42.291637]],
  ['48110', [-83.664277, 42.008164]],
  ['48111', [-83.49083, 42.194865]],
  ['48112', [-83.150823, 42.239933]],
  ['48113', [-83.836571, 42.253502]],
  ['48114', [-83.75725, 42.566097]],
  ['48115', [-83.911693, 42.160216]],
  ['48116', [-83.77718, 42.509476]],
  ['48117', [-83.40605, 42.05589]],
  ['48118', [-84.03445, 42.316922]],
  ['48120', [-83.15863, 42.306288]],
  ['48121', [-83.150823, 42.239933]],
  ['48122', [-83.18188, 42.281638]],
  ['48123', [-83.150823, 42.239933]],
  ['48124', [-83.25049, 42.294987]],
  ['48125', [-83.25987, 42.277388]],
  ['48126', [-83.18179, 42.333787]],
  ['48127', [-83.28332, 42.335687]],
  ['48128', [-83.26872, 42.320237]],
  ['48130', [-83.89907, 42.344837]],
  ['48131', [-83.67133, 41.965806]],
  ['48133', [-83.49818, 41.777302]],
  ['48134', [-83.28711, 42.107026]],
  ['48135', [-83.3382, 42.324338]],
  ['48136', [-83.150823, 42.239933]],
  ['48137', [-84.06078, 42.445724]],
  ['48138', [-83.16017, 42.130091]],
  ['48139', [-83.80332, 42.449117]],
  ['48140', [-83.58184, 41.876895]],
  ['48141', [-83.31414, 42.292582]],
  ['48143', [-83.83372, 42.454366]],
  ['48144', [-83.63047, 41.754298]],
  ['48145', [-83.44652, 41.846349]],
  ['48146', [-83.18097, 42.243488]],
  ['48150', [-83.36513, 42.365936]],
  ['48151', [-83.150823, 42.239933]],
  ['48152', [-83.36299, 42.427835]],
  ['48153', [-83.150823, 42.239933]],
  ['48154', [-83.37161, 42.396194]],
  ['48157', [-83.44141, 41.811196]],
  ['48158', [-84.03123, 42.14784]],
  ['48159', [-83.54756, 42.01968]],
  ['48160', [-83.68414, 42.081363]],
  ['48161', [-83.41669, 41.904793]],
  ['48162', [-83.38515, 41.940898]],
  ['48164', [-83.38785, 42.128069]],
  ['48165', [-83.62916, 42.501401]],
  ['48166', [-83.29818, 41.988228]],
  ['48167', [-83.48945, 42.425506]],
  ['48168', [-83.418524, 41.885451]],
  ['48169', [-83.93425, 42.455063]],
  ['48170', [-83.4856, 42.370837]],
  ['48173', [-83.21575, 42.073793]],
  ['48174', [-83.37223, 42.212372]],
  ['48175', [-83.578088, 42.405636]],
  ['48176', [-83.78387, 42.16489]],
  ['48177', [-83.579334, 41.807647]],
  ['48178', [-83.66673, 42.45644]],
  ['48179', [-83.26128, 42.049534]],
  ['48180', [-83.26481, 42.231788]],
  ['48182', [-83.57793, 41.773203]],
  ['48183', [-83.21446, 42.137071]],
  ['48184', [-83.3786, 42.276688]],
  ['48185', [-83.378, 42.335937]],
  ['48186', [-83.37464, 42.293787]],
  ['48187', [-83.47575, 42.33087]],
  ['48188', [-83.47401, 42.289354]],
  ['48189', [-83.78009, 42.422746]],
  ['48190', [-83.59684, 42.134658]],
  ['48191', [-83.57071, 42.121345]],
  ['48192', [-83.17402, 42.199989]],
  ['48193', [-83.1793, 42.1742]],
  ['48195', [-83.19812, 42.205289]],
  ['48197', [-83.63316, 42.223482]],
  ['48198', [-83.58215, 42.247039]],
  ['48201', [-83.05879, 42.343787]],
  ['48202', [-83.07837, 42.375237]],
  ['48203', [-83.09981, 42.421936]],
  ['48204', [-83.14254, 42.365337]],
  ['48205', [-82.98068, 42.433703]],
  ['48206', [-83.10849, 42.375787]],
  ['48207', [-83.02449, 42.352837]],
  ['48208', [-83.08994, 42.348937]],
  ['48209', [-83.11467, 42.307088]],
  ['48210', [-83.12785, 42.338137]],
  ['48211', [-83.04483, 42.379019]],
  ['48212', [-83.05826, 42.410037]],
  ['48213', [-82.99632, 42.395904]],
  ['48214', [-82.99314, 42.368137]],
  ['48215', [-82.95243, 42.378287]],
  ['48216', [-83.07805, 42.328338]],
  ['48217', [-83.1552, 42.277738]],
  ['48218', [-83.13676, 42.269288]],
  ['48219', [-83.24902, 42.425236]],
  ['48220', [-83.13398, 42.460986]],
  ['48221', [-83.14969, 42.426786]],
  ['48222', [-83.150823, 42.239933]],
  ['48223', [-83.24422, 42.394586]],
  ['48224', [-82.94348, 42.410487]],
  ['48225', [-82.9283, 42.438186]],
  ['48226', [-83.04888, 42.331821]],
  ['48227', [-83.1918, 42.388786]],
  ['48228', [-83.21722, 42.355437]],
  ['48229', [-83.14829, 42.250505]],
  ['48230', [-82.92426, 42.386087]],
  ['48231', [-83.150823, 42.239933]],
  ['48232', [-83.150823, 42.239933]],
  ['48233', [-83.150823, 42.239933]],
  ['48234', [-83.04422, 42.432686]],
  ['48235', [-83.19547, 42.427636]],
  ['48236', [-82.90003, 42.425836]],
  ['48237', [-83.17952, 42.467235]],
  ['48238', [-83.14152, 42.396736]],
  ['48239', [-83.29105, 42.379436]],
  ['48240', [-83.30197, 42.426285]],
  ['48242', [-83.35297, 42.20649]],
  ['48243', [-83.150823, 42.239933]],
  ['48244', [-83.150823, 42.239933]],
  ['48246', [-83.348152, 42.712944]],
  ['48254', [-83.150823, 42.239933]],
  ['48255', [-83.150823, 42.239933]],
  ['48258', [-83.150823, 42.239933]],
  ['48260', [-83.150823, 42.239933]],
  ['48264', [-83.150823, 42.239933]],
  ['48265', [-83.150823, 42.239933]],
  ['48266', [-83.150823, 42.239933]],
  ['48267', [-83.150823, 42.239933]],
  ['48268', [-83.150823, 42.239933]],
  ['48269', [-83.150823, 42.239933]],
  ['48272', [-83.150823, 42.239933]],
  ['48274', [-83.150823, 42.239933]],
  ['48275', [-83.150823, 42.239933]],
  ['48277', [-83.150823, 42.239933]],
  ['48278', [-83.150823, 42.239933]],
  ['48279', [-83.150823, 42.239933]],
  ['48288', [-83.150823, 42.239933]],
  ['48295', [-83.150823, 42.239933]],
  ['48297', [-83.150823, 42.239933]],
  ['48299', [-83.150823, 42.239933]],
  ['48301', [-83.28083, 42.546445]],
  ['48302', [-83.29705, 42.5863]],
  ['48303', [-83.3863, 42.660091]],
  ['48304', [-83.22956, 42.586677]],
  ['48305', [-82.911243, 42.569278]],
  ['48306', [-83.16584, 42.708151]],
  ['48307', [-83.12781, 42.662293]],
  ['48308', [-83.131426, 42.638533]],
  ['48309', [-83.18111, 42.662732]],
  ['48310', [-83.07158, 42.563644]],
  ['48311', [-82.903065, 42.672322]],
  ['48312', [-83.00446, 42.558151]],
  ['48313', [-82.99938, 42.598734]],
  ['48314', [-83.04844, 42.609789]],
  ['48315', [-82.99392, 42.662902]],
  ['48316', [-83.06154, 42.689632]],
  ['48317', [-83.04255, 42.639777]],
  ['48318', [-82.903065, 42.672322]],
  ['48320', [-83.33757, 42.611783]],
  ['48321', [-83.3863, 42.660091]],
  ['48322', [-83.37551, 42.541268]],
  ['48323', [-83.37159, 42.572442]],
  ['48324', [-83.39974, 42.595233]],
  ['48325', [-83.3863, 42.660091]],
  ['48326', [-83.24571, 42.661129]],
  ['48327', [-83.40273, 42.642764]],
  ['48328', [-83.35133, 42.642302]],
  ['48329', [-83.38967, 42.68828]],
  ['48330', [-83.363697, 42.675435]],
  ['48331', [-83.40875, 42.508809]],
  ['48332', [-83.3863, 42.660091]],
  ['48333', [-83.3863, 42.660091]],
  ['48334', [-83.35363, 42.5065]],
  ['48335', [-83.39853, 42.465142]],
  ['48336', [-83.3464, 42.458502]],
  ['48340', [-83.29585, 42.668532]],
  ['48341', [-83.30243, 42.626947]],
  ['48342', [-83.28211, 42.644581]],
  ['48343', [-83.3863, 42.660091]],
  ['48346', [-83.4044, 42.720966]],
  ['48347', [-83.3863, 42.660091]],
  ['48348', [-83.37805, 42.768373]],
  ['48350', [-83.52283, 42.747677]],
  ['48353', [-83.71792, 42.644709]],
  ['48356', [-83.58982, 42.66415]],
  ['48357', [-83.63297, 42.656281]],
  ['48359', [-83.28537, 42.722932]],
  ['48360', [-83.2612, 42.74628]],
  ['48361', [-83.3863, 42.660091]],
  ['48362', [-83.2471, 42.783379]],
  ['48363', [-83.17281, 42.765582]],
  ['48366', [-83.18412, 42.803277]],
  ['48367', [-83.14416, 42.838438]],
  ['48370', [-83.20932, 42.820082]],
  ['48371', [-83.27921, 42.823968]],
  ['48374', [-83.51766, 42.467945]],
  ['48375', [-83.45865, 42.462734]],
  ['48376', [-83.474785, 42.470984]],
  ['48377', [-83.47314, 42.511766]],
  ['48380', [-83.66826, 42.586597]],
  ['48381', [-83.59318, 42.571402]],
  ['48382', [-83.48997, 42.593035]],
  ['48383', [-83.54529, 42.657361]],
  ['48386', [-83.47193, 42.642431]],
  ['48387', [-83.311605, 42.7253]],
  ['48390', [-83.47969, 42.549483]],
  ['48391', [-83.3863, 42.660091]],
  ['48393', [-83.53378, 42.532251]],
  ['48396', [-83.537887, 42.523233]],
  ['48397', [-83.040222, 42.491736]],
  ['48398', [-83.3863, 42.660091]],
  ['48401', [-82.65433, 43.357999]],
  ['48410', [-82.945483, 43.558856]],
  ['48411', [-83.536923, 42.940019]],
  ['48412', [-83.16651, 43.053648]],
  ['48413', [-83.00181, 43.803691]],
  ['48414', [-84.0761, 42.869478]],
  ['48415', [-83.79486, 43.268788]],
  ['48416', [-82.98356, 43.214645]],
  ['48417', [-83.93995, 43.262162]],
  ['48418', [-83.95884, 42.816358]],
  ['48419', [-82.6415, 43.420791]],
  ['48420', [-83.726, 43.176375]],
  ['48421', [-83.3933, 43.154173]],
  ['48422', [-82.64573, 43.263998]],
  ['48423', [-83.5218, 43.029526]],
  ['48426', [-83.06289, 43.50461]],
  ['48427', [-82.71234, 43.519997]],
  ['48428', [-83.13471, 42.936145]],
  ['48429', [-83.99148, 42.918766]],
  ['48430', [-83.74074, 42.78459]],
  ['48432', [-83.01232, 43.89867]],
  ['48433', [-83.84883, 43.072177]],
  ['48434', [-82.60984, 43.66139]],
  ['48435', [-83.36195, 43.239123]],
  ['48436', [-83.89144, 42.870299]],
  ['48437', [-83.62016, 43.111009]],
  ['48438', [-83.48421, 42.911611]],
  ['48439', [-83.63585, 42.925677]],
  ['48440', [-83.40536, 42.952899]],
  ['48441', [-82.69274, 43.812371]],
  ['48442', [-83.5968, 42.800191]],
  ['48444', [-83.06067, 43.038967]],
  ['48445', [-83.02017, 43.952534]],
  ['48446', [-83.33073, 43.048777]],
  ['48448', [-84.300929, 42.955783]],
  ['48449', [-83.94468, 42.988946]],
  ['48450', [-82.52701, 43.243891]],
  ['48451', [-83.81366, 42.800911]],
  ['48453', [-83.03213, 43.336126]],
  ['48454', [-82.82558, 43.198014]],
  ['48455', [-83.29409, 42.938891]],
  ['48456', [-82.74685, 43.660079]],
  ['48457', [-83.89507, 43.182851]],
  ['48458', [-83.69025, 43.116959]],
  ['48460', [-83.98443, 43.131102]],
  ['48461', [-83.20549, 43.210744]],
  ['48462', [-83.43109, 42.84391]],
  ['48463', [-83.52542, 43.167457]],
  ['48464', [-83.42227, 43.219132]],
  ['48465', [-82.6874, 43.618879]],
  ['48466', [-82.81674, 43.264205]],
  ['48467', [-83.00241, 44.023181]],
  ['48468', [-82.77514, 43.938529]],
  ['48469', [-82.54948, 43.452038]],
  ['48470', [-82.75005, 43.736207]],
  ['48471', [-82.83687, 43.418054]],
  ['48472', [-82.9635, 43.512287]],
  ['48473', [-83.82612, 42.950128]],
  ['48475', [-82.9257, 43.673699]],
  ['48476', [-84.03166, 42.939379]],
  ['48501', [-83.780835, 42.965926]],
  ['48502', [-83.69012, 43.014077]],
  ['48503', [-83.69291, 43.011227]],
  ['48504', [-83.73126, 43.043627]],
  ['48505', [-83.69728, 43.062076]],
  ['48506', [-83.64688, 43.047826]],
  ['48507', [-83.70142, 42.973263]],
  ['48509', [-83.60433, 43.020176]],
  ['48519', [-83.61155, 42.984777]],
  ['48529', [-83.67085, 42.972477]],
  ['48531', [-83.692507, 43.002]],
  ['48532', [-83.76098, 43.005477]],
  ['48550', [-83.688706, 43.034927]],
  ['48551', [-83.713124, 42.978995]],
  ['48552', [-83.713074, 42.977895]],
  ['48553', [-83.720306, 42.973627]],
  ['48554', [-83.79463, 42.972244]],
  ['48555', [-83.710756, 43.011277]],
  ['48556', [-83.646255, 43.032677]],
  ['48557', [-83.783675, 43.080578]],
  ['48559', [-83.692507, 43.002]],
  ['48563', [-84.695963, 44.407339]],
  ['48567', [-83.655654, 42.993102]],
  ['48601', [-83.91427, 43.413975]],
  ['48602', [-83.97142, 43.423925]],
  ['48603', [-84.01177, 43.441975]],
  ['48604', [-83.94479, 43.473075]],
  ['48605', [-84.051827, 43.458776]],
  ['48606', [-84.032612, 43.348535]],
  ['48607', [-83.93267, 43.431375]],
  ['48608', [-84.032612, 43.348535]],
  ['48609', [-84.09438, 43.40153]],
  ['48610', [-84.14497, 44.142282]],
  ['48611', [-84.07918, 43.617796]],
  ['48612', [-84.42078, 43.87787]],
  ['48613', [-84.12889, 43.93724]],
  ['48614', [-84.29816, 43.25118]],
  ['48615', [-84.47433, 43.422568]],
  ['48616', [-84.11987, 43.190239]],
  ['48617', [-84.74219, 43.828266]],
  ['48618', [-84.55669, 43.747084]],
  ['48619', [-84.04695, 44.825431]],
  ['48620', [-84.37785, 43.807728]],
  ['48621', [-83.98795, 44.718279]],
  ['48622', [-84.86734, 43.840787]],
  ['48623', [-84.1337, 43.518309]],
  ['48624', [-84.46503, 44.041529]],
  ['48625', [-84.83959, 44.052759]],
  ['48626', [-84.22384, 43.416335]],
  ['48627', [-84.747, 44.462149]],
  ['48628', [-84.33789, 43.807162]],
  ['48629', [-84.75589, 44.305812]],
  ['48630', [-84.77405, 44.327064]],
  ['48631', [-83.97973, 43.67579]],
  ['48632', [-85.00864, 43.864435]],
  ['48633', [-84.93861, 43.959478]],
  ['48634', [-84.01217, 43.748816]],
  ['48635', [-83.99444, 44.399711]],
  ['48636', [-84.27784, 44.616344]],
  ['48637', [-84.33926, 43.410627]],
  ['48640', [-84.27234, 43.605457]],
  ['48641', [-84.387753, 43.538252]],
  ['48642', [-84.21671, 43.652404]],
  ['48647', [-84.10388, 44.654024]],
  ['48649', [-84.1976, 43.150386]],
  ['48650', [-83.97901, 43.851276]],
  ['48651', [-84.646, 44.258616]],
  ['48652', [-84.19641, 43.862479]],
  ['48653', [-84.66089, 44.484145]],
  ['48654', [-84.19608, 44.468977]],
  ['48655', [-84.14652, 43.297082]],
  ['48656', [-84.43815, 44.360384]],
  ['48657', [-84.39169, 43.69784]],
  ['48658', [-83.93628, 43.985129]],
  ['48659', [-84.02506, 44.080925]],
  ['48661', [-84.21162, 44.288158]],
  ['48662', [-84.41672, 43.400958]],
  ['48663', [-83.975475, 43.467315]],
  ['48667', [-84.387331, 43.647297]],
  ['48670', [-84.256758, 43.637471]],
  ['48674', [-84.197125, 43.612884]],
  ['48686', [-84.387331, 43.647297]],
  ['48701', [-83.53468, 43.587817]],
  ['48703', [-83.65821, 44.06434]],
  ['48705', [-83.63681, 44.708179]],
  ['48706', [-83.9162, 43.607523]],
  ['48707', [-83.933337, 43.737798]],
  ['48708', [-83.87869, 43.583323]],
  ['48710', [-83.994328, 43.556574]],
  ['48720', [-83.34887, 43.831509]],
  ['48721', [-83.32907, 44.79652]],
  ['48722', [-83.84636, 43.346632]],
  ['48723', [-83.3881, 43.490117]],
  ['48724', [-83.92772, 43.460075]],
  ['48725', [-83.23974, 43.944466]],
  ['48726', [-83.17889, 43.604819]],
  ['48727', [-83.18115, 43.314176]],
  ['48728', [-83.83882, 44.746486]],
  ['48729', [-83.17874, 43.492259]],
  ['48730', [-83.48516, 44.335904]],
  ['48731', [-83.16143, 43.83014]],
  ['48732', [-83.81298, 43.605861]],
  ['48733', [-83.61267, 43.542822]],
  ['48734', [-83.74123, 43.340413]],
  ['48735', [-83.26005, 43.664593]],
  ['48736', [-83.624431, 43.49442]],
  ['48737', [-83.70977, 44.54684]],
  ['48738', [-83.32459, 44.563997]],
  ['48739', [-83.83539, 44.379147]],
  ['48740', [-83.3516, 44.663477]],
  ['48741', [-83.17248, 43.410781]],
  ['48742', [-83.43887, 44.725546]],
  ['48743', [-83.87538, 44.442563]],
  ['48744', [-83.35883, 43.34896]],
  ['48745', [-83.49441, 44.580133]],
  ['48746', [-83.53822, 43.274498]],
  ['48747', [-83.76529, 43.519522]],
  ['48748', [-83.66579, 44.328794]],
  ['48749', [-83.87572, 44.046475]],
  ['48750', [-83.41927, 44.447318]],
  ['48754', [-83.24746, 43.736747]],
  ['48755', [-83.28557, 43.85672]],
  ['48756', [-83.99306, 44.206123]],
  ['48757', [-83.68564, 43.462775]],
  ['48758', [-83.676156, 43.407321]],
  ['48759', [-83.43007, 43.737971]],
  ['48760', [-83.25403, 43.331961]],
  ['48761', [-83.91915, 44.54106]],
  ['48762', [-83.50181, 44.821813]],
  ['48763', [-83.55805, 44.250144]],
  ['48764', [-83.519166, 44.266549]],
  ['48765', [-83.73723, 44.147192]],
  ['48766', [-83.84369, 44.127342]],
  ['48767', [-83.46527, 43.645608]],
  ['48768', [-83.57917, 43.370434]],
  ['48769', [-83.657355, 43.327048]],
  ['48770', [-83.81849, 44.246004]],
  ['48787', [-83.749418, 43.334958]],
  ['48801', [-84.66256, 43.377113]],
  ['48802', [-84.666661, 43.389262]],
  ['48803', [-83.09901, 42.581148]],
  ['48804', [-84.847417, 43.640574]],
  ['48805', [-84.371973, 42.599184]],
  ['48806', [-84.50765, 43.179346]],
  ['48807', [-84.41579, 43.145475]],
  ['48808', [-84.46099, 42.821233]],
  ['48809', [-85.24478, 43.085313]],
  ['48811', [-84.84871, 43.184647]],
  ['48812', [-84.975271, 43.413761]],
  ['48813', [-84.8228, 42.580076]],
  ['48815', [-85.25046, 42.841491]],
  ['48816', [-83.938967, 42.757633]],
  ['48817', [-84.0634, 43.000193]],
  ['48818', [-84.90913, 43.272279]],
  ['48819', [-84.27674, 42.552218]],
  ['48820', [-84.58612, 42.842438]],
  ['48821', [-84.64772, 42.645985]],
  ['48822', [-84.76379, 42.8288]],
  ['48823', [-84.47143, 42.747922]],
  ['48824', [-84.4792, 42.725884]],
  ['48825', [-84.464775, 42.723784]],
  ['48826', [-84.371973, 42.599184]],
  ['48827', [-84.6579, 42.507093]],
  ['48829', [-85.03033, 43.404412]],
  ['48830', [-84.83662, 43.362879]],
  ['48831', [-84.37722, 43.098253]],
  ['48832', [-84.77657, 43.411924]],
  ['48833', [-84.600723, 42.944209]],
  ['48834', [-85.04948, 43.141649]],
  ['48835', [-84.74589, 43.011103]],
  ['48836', [-84.07542, 42.666646]],
  ['48837', [-84.7503, 42.749774]],
  ['48838', [-85.26533, 43.1791]],
  ['48840', [-84.39027, 42.760217]],
  ['48841', [-84.23568, 43.104499]],
  ['48842', [-84.52702, 42.639235]],
  ['48843', [-83.91835, 42.612243]],
  ['48844', [-83.911173, 42.603479]],
  ['48845', [-84.84627, 43.096466]],
  ['48846', [-85.06679, 42.981932]],
  ['48847', [-84.59181, 43.269967]],
  ['48848', [-84.3569, 42.884921]],
  ['48849', [-85.13565, 42.788151]],
  ['48850', [-85.25628, 43.447625]],
  ['48851', [-84.94611, 42.962965]],
  ['48852', [-85.04326, 43.355304]],
  ['48853', [-84.69278, 43.102399]],
  ['48854', [-84.44738, 42.582902]],
  ['48855', [-83.915592, 42.678059]],
  ['48856', [-84.73336, 43.197414]],
  ['48857', [-84.16581, 42.845703]],
  ['48858', [-84.78306, 43.60616]],
  ['48859', [-84.847297, 43.564736]],
  ['48860', [-84.92633, 43.026842]],
  ['48861', [-84.92575, 42.733673]],
  ['48862', [-84.497138, 43.356518]],
  ['48863', [-83.911173, 42.603479]],
  ['48864', [-84.41946, 42.702128]],
  ['48865', [-85.11754, 43.090038]],
  ['48866', [-84.37643, 42.997636]],
  ['48867', [-84.17896, 42.99605]],
  ['48870', [-85.007665, 43.113587]],
  ['48871', [-84.68397, 43.160914]],
  ['48872', [-84.22291, 42.807435]],
  ['48873', [-84.84263, 42.987795]],
  ['48874', [-84.6032, 43.184467]],
  ['48875', [-84.91289, 42.864338]],
  ['48876', [-84.73826, 42.640719]],
  ['48877', [-84.84062, 43.403224]],
  ['48878', [-84.77731, 43.713365]],
  ['48879', [-84.57704, 42.998998]],
  ['48880', [-84.60041, 43.422397]],
  ['48881', [-85.20938, 42.929829]],
  ['48882', [-84.295949, 42.803973]],
  ['48883', [-84.68228, 43.534764]],
  ['48884', [-85.04772, 43.21107]],
  ['48885', [-85.16587, 43.247555]],
  ['48886', [-85.14998, 43.426703]],
  ['48887', [-85.074665, 42.944929]],
  ['48888', [-85.10506, 43.297366]],
  ['48889', [-84.79415, 43.293977]],
  ['48890', [-84.98054, 42.759217]],
  ['48891', [-84.92216, 43.402185]],
  ['48892', [-84.17011, 42.643684]],
  ['48893', [-84.98244, 43.662482]],
  ['48894', [-84.7996, 42.928735]],
  ['48895', [-84.27844, 42.68669]],
  ['48896', [-84.90307, 43.523617]],
  ['48897', [-85.12449, 42.705285]],
  ['48901', [-84.371973, 42.599184]],
  ['48906', [-84.56153, 42.764167]],
  ['48907', [-84.838206, 42.596071]],
  ['48908', [-84.838206, 42.596071]],
  ['48909', [-84.371973, 42.599184]],
  ['48910', [-84.54915, 42.704435]],
  ['48911', [-84.56913, 42.677335]],
  ['48912', [-84.52497, 42.734434]],
  ['48913', [-84.371973, 42.599184]],
  ['48915', [-84.57106, 42.737701]],
  ['48916', [-84.371973, 42.599184]],
  ['48917', [-84.62698, 42.735535]],
  ['48918', [-84.371973, 42.599184]],
  ['48919', [-84.551728, 42.728585]],
  ['48921', [-84.555629, 42.723735]],
  ['48922', [-84.558679, 42.732535]],
  ['48924', [-84.371973, 42.599184]],
  ['48929', [-84.558679, 42.732535]],
  ['48930', [-84.558679, 42.732535]],
  ['48933', [-84.55492, 42.731885]],
  ['48937', [-84.559029, 42.748734]],
  ['48950', [-84.371973, 42.599184]],
  ['48956', [-84.558679, 42.732535]],
  ['48980', [-84.371973, 42.599184]],
  ['49001', [-85.55107, 42.278891]],
  ['49002', [-85.55958, 42.210792]],
  ['49003', [-85.529858, 42.245412]],
  ['49004', [-85.56635, 42.330089]],
  ['49005', [-85.493232, 42.323045]],
  ['49006', [-85.62247, 42.295005]],
  ['49007', [-85.58573, 42.29714]],
  ['49008', [-85.61098, 42.26549]],
  ['49009', [-85.69255, 42.272538]],
  ['49010', [-85.87438, 42.531244]],
  ['49011', [-85.23333, 42.089314]],
  ['49012', [-85.35242, 42.347289]],
  ['49013', [-86.12201, 42.308798]],
  ['49014', [-85.13845, 42.311089]],
  ['49015', [-85.22294, 42.30244]],
  ['49016', [-85.29136, 42.34381]],
  ['49017', [-85.20257, 42.35714]],
  ['49018', [-85.004511, 42.246402]],
  ['49019', [-85.529858, 42.245412]],
  ['49020', [-85.004511, 42.246402]],
  ['49021', [-85.04264, 42.447038]],
  ['49022', [-86.41801, 42.10809]],
  ['49023', [-86.715294, 42.001551]],
  ['49024', [-85.61773, 42.216541]],
  ['49026', [-85.9679, 42.371729]],
  ['49027', [-86.0795, 42.345815]],
  ['49028', [-85.18987, 41.850631]],
  ['49029', [-85.09728, 42.142445]],
  ['49030', [-85.32984, 41.854639]],
  ['49031', [-85.98937, 41.907837]],
  ['49032', [-85.51068, 41.924331]],
  ['49033', [-85.09307, 42.239592]],
  ['49034', [-85.33499, 42.239393]],
  ['49035', [-85.308555, 42.595121]],
  ['49036', [-85.0142, 41.912776]],
  ['49038', [-86.3337, 42.209307]],
  ['49039', [-86.372276, 42.224757]],
  ['49040', [-85.33059, 41.960856]],
  ['49041', [-85.529858, 42.245412]],
  ['49042', [-85.66411, 41.847413]],
  ['49043', [-86.26647, 42.296105]],
  ['49045', [-85.99417, 42.105719]],
  ['49046', [-85.39257, 42.504149]],
  ['49047', [-86.1208, 42.004729]],
  ['49048', [-85.525359, 42.292335]],
  ['49050', [-85.24582, 42.504137]],
  ['49051', [-85.24228, 42.184303]],
  ['49052', [-85.31673, 42.111949]],
  ['49053', [-85.41657, 42.289991]],
  ['49055', [-85.85725, 42.37346]],
  ['49056', [-86.0545, 42.395965]],
  ['49057', [-86.16943, 42.19923]],
  ['49058', [-85.29296, 42.64275]],
  ['49060', [-85.4055, 42.424387]],
  ['49061', [-85.81527, 41.878312]],
  ['49062', [-85.814072, 42.36175]],
  ['49063', [-86.209904, 42.393586]],
  ['49064', [-86.04822, 42.215357]],
  ['49065', [-85.84205, 42.144087]],
  ['49066', [-85.35683, 42.037422]],
  ['49067', [-85.8033, 42.026876]],
  ['49068', [-84.94869, 42.277837]],
  ['49069', [-84.949919, 42.204494]],
  ['49070', [-85.63686, 42.540492]],
  ['49071', [-85.7807, 42.234417]],
  ['49072', [-85.46428, 42.005815]],
  ['49073', [-85.124, 42.588988]],
  ['49074', [-85.529858, 42.245412]],
  ['49075', [-85.45029, 41.916374]],
  ['49076', [-84.91041, 42.439324]],
  ['49077', [-85.529858, 42.245412]],
  ['49078', [-85.71069, 42.459989]],
  ['49079', [-85.90002, 42.22514]],
  ['49080', [-85.61256, 42.462935]],
  ['49081', [-85.617824, 42.171777]],
  ['49082', [-84.87559, 41.943472]],
  ['49083', [-85.45022, 42.379689]],
  ['49084', [-86.38557, 42.178035]],
  ['49085', [-86.47935, 42.074435]],
  ['49087', [-85.67594, 42.117421]],
  ['49088', [-85.42621, 42.187577]],
  ['49089', [-85.23198, 42.010485]],
  ['49090', [-86.24912, 42.403593]],
  ['49091', [-85.43234, 41.822244]],
  ['49092', [-84.97543, 42.09724]],
  ['49093', [-85.63829, 41.958306]],
  ['49094', [-85.11971, 42.06137]],
  ['49095', [-85.89022, 41.916613]],
  ['49096', [-85.01188, 42.63222]],
  ['49097', [-85.5073, 42.117028]],
  ['49098', [-86.25718, 42.186679]],
  ['49099', [-85.67233, 41.792628]],
  ['49101', [-86.48827, 41.944869]],
  ['49102', [-86.26909, 41.949244]],
  ['49103', [-86.34979, 41.944691]],
  ['49104', [-86.715294, 42.001551]],
  ['49106', [-86.55586, 41.94263]],
  ['49107', [-86.38349, 41.830012]],
  ['49111', [-86.29071, 42.022092]],
  ['49112', [-86.02616, 41.797792]],
  ['49113', [-86.50367, 41.801264]],
  ['49115', [-86.63715, 41.876483]],
  ['49116', [-86.67044, 41.848782]],
  ['49117', [-86.75519, 41.784691]],
  ['49119', [-86.54919, 41.875968]],
  ['49120', [-86.23981, 41.828055]],
  ['49121', [-86.715294, 42.001551]],
  ['49125', [-86.59362, 41.890521]],
  ['49126', [-86.37416, 42.036334]],
  ['49127', [-86.51351, 42.015092]],
  ['49128', [-86.61984, 41.820452]],
  ['49129', [-86.69237, 41.827718]],
  ['49130', [-85.83605, 41.787096]],
  ['49137', [-84.102395, 42.424092]],
  ['49157', [-85.298079, 44.21551]],
  ['49169', [-86.015026, 44.30476]],
  ['49201', [-84.38842, 42.252268]],
  ['49202', [-84.41052, 42.262135]],
  ['49203', [-84.40913, 42.227536]],
  ['49204', [-84.213755, 42.252847]],
  ['49220', [-84.3329, 41.992765]],
  ['49221', [-84.04585, 41.900927]],
  ['49224', [-84.75151, 42.261257]],
  ['49226', [-85.976841, 42.379469]],
  ['49227', [-84.76598, 41.956743]],
  ['49228', [-83.87435, 41.821595]],
  ['49229', [-83.82631, 41.991654]],
  ['49230', [-84.22768, 42.089891]],
  ['49232', [-84.65993, 41.731009]],
  ['49233', [-84.3494, 42.071196]],
  ['49234', [-84.36382, 42.121774]],
  ['49235', [-84.20769, 41.853441]],
  ['49236', [-83.95779, 42.067837]],
  ['49237', [-84.651, 42.177223]],
  ['49238', [-83.78699, 41.910884]],
  ['49239', [-84.604662, 41.782499]],
  ['49240', [-84.18971, 42.284771]],
  ['49241', [-84.60334, 42.107907]],
  ['49242', [-84.62624, 41.89958]],
  ['49245', [-84.80789, 42.147222]],
  ['49246', [-84.49338, 42.118019]],
  ['49247', [-84.34907, 41.857385]],
  ['49248', [-84.01395, 41.766891]],
  ['49249', [-84.4396, 42.048979]],
  ['49250', [-84.64548, 42.018585]],
  ['49251', [-84.41116, 42.464769]],
  ['49252', [-84.77046, 42.034427]],
  ['49253', [-84.27972, 41.974999]],
  ['49254', [-84.32597, 42.230336]],
  ['49255', [-84.8376, 41.776287]],
  ['49256', [-84.21557, 41.746349]],
  ['49257', [-84.503905, 42.054795]],
  ['49258', [-84.593491, 41.884795]],
  ['49259', [-84.25886, 42.371819]],
  ['49261', [-84.24575, 42.164338]],
  ['49262', [-84.46824, 41.963683]],
  ['49263', [-84.18274, 42.158739]],
  ['49264', [-84.56022, 42.443387]],
  ['49265', [-84.16796, 42.020199]],
  ['49266', [-84.55244, 41.844894]],
  ['49267', [-83.70951, 41.757599]],
  ['49268', [-83.93858, 41.869086]],
  ['49269', [-84.59305, 42.285987]],
  ['49270', [-83.69556, 41.886364]],
  ['49271', [-84.45404, 41.832213]],
  ['49272', [-84.34471, 42.398355]],
  ['49274', [-84.75263, 41.843254]],
  ['49275', [-83.865674, 41.988026]],
  ['49276', [-83.79433, 41.798043]],
  ['49277', [-84.46144, 42.395023]],
  ['49278', [-84.32652, 41.912292]],
  ['49279', [-84.10866, 41.791959]],
  ['49280', [-84.186608, 41.79583]],
  ['49281', [-84.37762, 42.050744]],
  ['49282', [-84.39902, 42.050628]],
  ['49283', [-84.55243, 42.203838]],
  ['49284', [-84.70868, 42.38806]],
  ['49285', [-84.19374, 42.458785]],
  ['49286', [-83.93878, 42.012268]],
  ['49287', [-84.09154, 42.035005]],
  ['49288', [-84.44772, 41.733173]],
  ['49289', [-84.09996, 41.770793]],
  ['49301', [-85.47798, 42.97252]],
  ['49302', [-85.41889, 42.834137]],
  ['49303', [-85.84023, 43.271422]],
  ['49304', [-85.88157, 43.895264]],
  ['49305', [-85.16304, 43.747406]],
  ['49306', [-85.59318, 43.078615]],
  ['49307', [-85.47815, 43.697651]],
  ['49309', [-85.86094, 43.747881]],
  ['49310', [-85.04876, 43.523037]],
  ['49311', [-85.643043, 42.633006]],
  ['49312', [-85.81962, 43.693086]],
  ['49314', [-85.840372, 42.731193]],
  ['49315', [-85.72542, 42.802932]],
  ['49316', [-85.55091, 42.794914]],
  ['49317', [-85.480856, 43.071201]],
  ['49318', [-85.82054, 43.22724]],
  ['49319', [-85.54811, 43.224155]],
  ['49320', [-85.278303, 43.755335]],
  ['49321', [-85.67384, 43.059661]],
  ['49322', [-85.37282, 43.368957]],
  ['49323', [-85.77879, 42.721597]],
  ['49325', [-85.31026, 42.763599]],
  ['49326', [-85.34217, 43.244538]],
  ['49327', [-85.82566, 43.34022]],
  ['49328', [-85.75809, 42.634199]],
  ['49329', [-85.47744, 43.405689]],
  ['49330', [-85.73768, 43.231933]],
  ['49331', [-85.34928, 42.944838]],
  ['49332', [-85.25883, 43.623053]],
  ['49333', [-85.46466, 42.689437]],
  ['49335', [-85.66543, 42.739942]],
  ['49336', [-85.43184, 43.494361]],
  ['49337', [-85.73515, 43.430588]],
  ['49338', [-85.57223, 43.764906]],
  ['49339', [-85.49536, 43.335474]],
  ['49340', [-85.09604, 43.617313]],
  ['49341', [-85.5189, 43.117214]],
  ['49342', [-85.30996, 43.696188]],
  ['49343', [-85.50812, 43.291089]],
  ['49344', [-85.5824, 42.588087]],
  ['49345', [-85.69952, 43.161106]],
  ['49346', [-85.37833, 43.587286]],
  ['49347', [-85.35285, 43.3157]],
  ['49348', [-85.62761, 42.681969]],
  ['49349', [-85.76662, 43.57219]],
  ['49351', [-85.550267, 43.031413]],
  ['49355', [-85.550267, 43.031413]],
  ['49356', [-85.550267, 43.031413]],
  ['49357', [-85.550267, 43.031413]],
  ['49368', [-85.713857, 44.321979]],
  ['49394', [-85.82081, 43.60034]],
  ['49401', [-85.9318, 42.973667]],
  ['49402', [-86.05421, 43.930995]],
  ['49403', [-85.84983, 43.125652]],
  ['49404', [-85.94516, 43.06507]],
  ['49405', [-86.19014, 43.907681]],
  ['49406', [-86.20404, 42.643315]],
  ['49408', [-86.12461, 42.575519]],
  ['49409', [-86.215413, 43.080865]],
  ['49410', [-86.14028, 44.016249]],
  ['49411', [-86.2662, 44.100701]],
  ['49412', [-85.95005, 43.464943]],
  ['49413', [-85.800884, 43.554311]],
  ['49415', [-86.13348, 43.135994]],
  ['49416', [-86.072032, 42.630533]],
  ['49417', [-86.19013, 43.04117]],
  ['49418', [-85.76466, 42.891709]],
  ['49419', [-85.98996, 42.679762]],
  ['49420', [-86.30604, 43.708002]],
  ['49421', [-86.0865, 43.595939]],
  ['49422', [-86.444751, 42.985596]],
  ['49423', [-86.10544, 42.765918]],
  ['49424', [-86.12726, 42.823232]],
  ['49425', [-86.10895, 43.443787]],
  ['49426', [-85.87861, 42.873302]],
  ['49427', [-85.844378, 42.826676]],
  ['49428', [-85.82314, 42.904275]],
  ['49429', [-86.444751, 42.985596]],
  ['49430', [-85.89754, 43.010337]],
  ['49431', [-86.43044, 43.967306]],
  ['49434', [-86.20597, 42.768619]],
  ['49435', [-85.83015, 43.032323]],
  ['49436', [-86.46576, 43.678258]],
  ['49437', [-86.37093, 43.436692]],
  ['49440', [-86.25131, 43.234014]],
  ['49441', [-86.2668, 43.199364]],
  ['49442', [-86.1955, 43.232615]],
  ['49443', [-86.468853, 43.295493]],
  ['49444', [-86.22774, 43.198048]],
  ['49445', [-86.2682, 43.278021]],
  ['49446', [-86.40265, 43.551783]],
  ['49448', [-86.07346, 43.091183]],
  ['49449', [-86.40276, 43.791699]],
  ['49450', [-86.07944, 42.484769]],
  ['49451', [-85.96376, 43.200998]],
  ['49452', [-86.27335, 43.520576]],
  ['49453', [-86.18724, 42.668174]],
  ['49454', [-86.28427, 43.946244]],
  ['49455', [-86.37004, 43.607628]],
  ['49456', [-86.19345, 43.08767]],
  ['49457', [-86.18835, 43.360182]],
  ['49458', [-86.11684, 43.945299]],
  ['49459', [-86.11462, 43.733491]],
  ['49460', [-86.14141, 42.935483]],
  ['49461', [-86.34028, 43.389775]],
  ['49463', [-86.468853, 43.295493]],
  ['49464', [-85.99621, 42.829252]],
  ['49468', [-85.550267, 43.031413]],
  ['49501', [-85.629101, 42.984226]],
  ['49502', [-85.550267, 43.031413]],
  ['49503', [-85.65885, 42.964176]],
  ['49504', [-85.69733, 42.977925]],
  ['49505', [-85.64785, 43.000076]],
  ['49506', [-85.61899, 42.945727]],
  ['49507', [-85.65435, 42.933077]],
  ['49508', [-85.62856, 42.876661]],
  ['49509', [-85.70392, 42.905877]],
  ['49510', [-85.550267, 43.031413]],
  ['49512', [-85.5616, 42.888821]],
  ['49514', [-85.550267, 43.031413]],
  ['49515', [-85.550267, 43.031413]],
  ['49516', [-85.550267, 43.031413]],
  ['49518', [-85.550267, 43.031413]],
  ['49519', [-85.7205, 42.896]],
  ['49523', [-85.550267, 43.031413]],
  ['49525', [-85.61168, 43.022199]],
  ['49530', [-85.550267, 43.031413]],
  ['49534', [-85.7769, 42.9709]],
  ['49544', [-85.75371, 42.999561]],
  ['49546', [-85.54582, 42.927801]],
  ['49548', [-85.66391, 42.868228]],
  ['49550', [-85.550267, 43.031413]],
  ['49555', [-85.550267, 43.031413]],
  ['49560', [-85.550267, 43.031413]],
  ['49564', [-85.950661, 44.933988]],
  ['49565', [-85.78338, 44.115429]],
  ['49582', [-85.832785, 44.544624]],
  ['49588', [-85.550267, 43.031413]],
  ['49590', [-85.394202, 44.851771]],
  ['49599', [-85.550267, 43.031413]],
  ['49601', [-85.46006, 44.243788]],
  ['49610', [-85.488449, 44.789382]],
  ['49611', [-84.97282, 44.976915]],
  ['49612', [-85.24163, 44.877741]],
  ['49613', [-86.21509, 44.494139]],
  ['49614', [-86.12625, 44.433903]],
  ['49615', [-85.21448, 44.970267]],
  ['49616', [-86.08323, 44.592766]],
  ['49617', [-86.04382, 44.643593]],
  ['49618', [-85.60119, 44.306885]],
  ['49619', [-86.00977, 44.306548]],
  ['49620', [-85.68623, 44.516368]],
  ['49621', [-85.78716, 44.872464]],
  ['49622', [-85.26929, 45.074263]],
  ['49623', [-85.67131, 43.889404]],
  ['49625', [-85.88908, 44.44905]],
  ['49626', [-86.29477, 44.245509]],
  ['49627', [-85.36212, 45.107683]],
  ['49628', [-86.22663, 44.617592]],
  ['49629', [-85.40671, 44.900873]],
  ['49630', [-86.02479, 44.845213]],
  ['49631', [-85.26394, 43.893894]],
  ['49632', [-84.97463, 44.233529]],
  ['49633', [-85.22931, 44.555245]],
  ['49634', [-86.29048, 44.215959]],
  ['49635', [-86.21484, 44.631]],
  ['49636', [-85.98588, 44.884904]],
  ['49637', [-85.70649, 44.647716]],
  ['49638', [-85.75597, 44.30192]],
  ['49639', [-85.40908, 43.84716]],
  ['49640', [-86.05033, 44.701761]],
  ['49642', [-85.78999, 43.880898]],
  ['49643', [-85.8079, 44.634131]],
  ['49644', [-85.92846, 44.105814]],
  ['49645', [-86.0122, 44.371587]],
  ['49646', [-85.08986, 44.743834]],
  ['49647', [-85.527652, 44.515487]],
  ['49648', [-85.3651, 45.000254]],
  ['49649', [-85.52611, 44.561602]],
  ['49650', [-85.85676, 44.725887]],
  ['49651', [-85.14305, 44.382889]],
  ['49652', [-85.772312, 43.867421]],
  ['49653', [-85.72325, 44.975051]],
  ['49654', [-85.75697, 45.023384]],
  ['49655', [-85.44877, 44.023714]],
  ['49656', [-85.69759, 44.05505]],
  ['49657', [-85.1771, 44.204505]],
  ['49659', [-85.03401, 44.905437]],
  ['49660', [-86.27234, 44.234322]],
  ['49663', [-85.37836, 44.426913]],
  ['49664', [-85.89914, 44.888566]],
  ['49665', [-85.11843, 44.095357]],
  ['49666', [-85.5614, 44.635328]],
  ['49667', [-84.91649, 44.335387]],
  ['49668', [-85.71981, 44.405334]],
  ['49670', [-85.62347, 45.107479]],
  ['49673', [-85.490005, 44.955872]],
  ['49674', [-85.597374, 45.066568]],
  ['49675', [-86.21149, 44.365757]],
  ['49676', [-85.27959, 44.829056]],
  ['49677', [-85.5155, 43.906666]],
  ['49679', [-85.15802, 43.878924]],
  ['49680', [-85.26096, 44.654872]],
  ['49682', [-85.63635, 44.993085]],
  ['49683', [-85.9334, 44.531456]],
  ['49684', [-85.67316, 44.74136]],
  ['49685', [-85.575147, 44.816178]],
  ['49686', [-85.54511, 44.735559]],
  ['49688', [-85.43612, 44.117243]],
  ['49689', [-85.9109, 44.209541]],
  ['49690', [-85.41696, 44.774285]],
  ['49695', [-85.131606, 44.918887]],
  ['49696', [-85.575147, 44.816178]],
  ['49701', [-84.72714, 45.773926]],
  ['49705', [-84.47898, 45.355074]],
  ['49706', [-84.77654, 45.431122]],
  ['49707', [-83.46411, 45.08583]],
  ['49709', [-84.14518, 45.016358]],
  ['49710', [-84.17164, 46.274091]],
  ['49711', [-85.332038, 45.523425]],
  ['49712', [-85.00995, 45.215511]],
  ['49713', [-84.86185, 45.206973]],
  ['49715', [-84.66502, 46.411782]],
  ['49716', [-84.72353, 45.515594]],
  ['49717', [-84.691207, 45.430705]],
  ['49718', [-84.79969, 45.714093]],
  ['49719', [-84.32615, 45.998074]],
  ['49720', [-85.24516, 45.294169]],
  ['49721', [-84.4625, 45.589694]],
  ['49722', [-84.86646, 45.416693]],
  ['49723', [-85.041872, 45.625353]],
  ['49724', [-84.43525, 46.33614]],
  ['49725', [-83.98087, 45.992931]],
  ['49726', [-83.75198, 45.994672]],
  ['49727', [-85.12252, 45.1379]],
  ['49728', [-84.99249, 46.355548]],
  ['49729', [-85.26699, 45.160264]],
  ['49730', [-84.86774, 45.039542]],
  ['49732', [-84.301497, 45.354266]],
  ['49733', [-84.71727, 44.809476]],
  ['49734', [-84.612207, 45.028411]],
  ['49735', [-84.67492, 44.989608]],
  ['49736', [-84.15477, 46.064734]],
  ['49737', [-85.113678, 45.580183]],
  ['49738', [-84.6253, 44.683274]],
  ['49739', [-84.610441, 44.682996]],
  ['49740', [-84.99025, 45.502308]],
  ['49743', [-83.93526, 45.282088]],
  ['49744', [-83.65723, 44.993111]],
  ['49745', [-84.45048, 46.01207]],
  ['49746', [-83.96071, 45.068394]],
  ['49747', [-83.61368, 44.827421]],
  ['49748', [-85.16591, 46.340461]],
  ['49749', [-84.59578, 45.418813]],
  ['49750', [-84.35977, 44.884763]],
  ['49751', [-84.43175, 44.963659]],
  ['49752', [-84.48228, 46.250359]],
  ['49753', [-83.7918, 44.99718]],
  ['49755', [-84.79103, 45.631602]],
  ['49756', [-84.30787, 44.839929]],
  ['49757', [-84.62643, 45.856777]],
  ['49758', [-84.287698, 44.682965]],
  ['49759', [-84.09811, 45.433394]],
  ['49760', [-84.94734, 46.048831]],
  ['49761', [-84.52405, 45.563799]],
  ['49762', [-85.29284, 46.142357]],
  ['49764', [-84.82611, 45.423768]],
  ['49765', [-84.23956, 45.354463]],
  ['49766', [-83.45125, 44.920907]],
  ['49768', [-85.09913, 46.659548]],
  ['49769', [-84.81464, 45.567787]],
  ['49770', [-84.93774, 45.360111]],
  ['49774', [-84.33219, 46.165132]],
  ['49775', [-84.46507, 45.758378]],
  ['49776', [-83.69012, 45.248956]],
  ['49777', [-83.49878, 45.311011]],
  ['49778', [-84.336519, 46.410777]],
  ['49779', [-83.83626, 45.413252]],
  ['49780', [-84.73671, 46.204512]],
  ['49781', [-84.7255, 45.923952]],
  ['49782', [-85.55339, 45.739114]],
  ['49783', [-84.32485, 46.460895]],
  ['49784', [-84.336519, 46.410777]],
  ['49785', [-84.405367, 46.181451]],
  ['49786', [-84.336519, 46.410777]],
  ['49788', [-84.45633, 46.267303]],
  ['49790', [-84.966792, 46.387377]],
  ['49791', [-84.59227, 45.48586]],
  ['49792', [-84.30074, 45.354677]],
  ['49793', [-85.05287, 46.199197]],
  ['49795', [-84.60248, 45.169729]],
  ['49796', [-84.9475, 45.269001]],
  ['49797', [-84.659847, 44.870234]],
  ['49799', [-84.60141, 45.268255]],
  ['49801', [-87.99717, 45.87178]],
  ['49802', [-88.08323, 45.800687]],
  ['49805', [-88.40806, 47.322703]],
  ['49806', [-86.88541, 46.436663]],
  ['49807', [-87.34617, 45.770006]],
  ['49808', [-87.86241, 46.650313]],
  ['49812', [-87.50316, 45.59232]],
  ['49813', [-87.37679, 45.435313]],
  ['49814', [-87.92354, 46.501074]],
  ['49815', [-88.04546, 46.186913]],
  ['49816', [-86.90766, 46.323845]],
  ['49817', [-86.45869, 45.921508]],
  ['49818', [-87.26197, 45.936883]],
  ['49819', [-87.614805, 46.604141]],
  ['49820', [-85.72148, 46.191702]],
  ['49821', [-87.61977, 45.521574]],
  ['49822', [-87.06843, 46.470986]],
  ['49825', [-86.98096, 46.364487]],
  ['49826', [-86.99621, 46.364395]],
  ['49827', [-85.57069, 46.156073]],
  ['49829', [-87.08951, 45.751671]],
  ['49831', [-87.81615, 45.994849]],
  ['49833', [-87.33355, 46.291233]],
  ['49834', [-87.77089, 45.947706]],
  ['49835', [-86.55894, 45.769159]],
  ['49836', [-85.90176, 46.180416]],
  ['49837', [-87.04529, 45.847136]],
  ['49838', [-85.71737, 46.095949]],
  ['49839', [-85.95837, 46.655567]],
  ['49840', [-85.9963, 46.000666]],
  ['49841', [-87.43312, 46.299186]],
  ['49845', [-87.351342, 45.692178]],
  ['49847', [-87.61054, 45.709804]],
  ['49848', [-87.63243, 45.376503]],
  ['49849', [-87.72709, 46.458207]],
  ['49852', [-87.81759, 45.781167]],
  ['49853', [-85.72321, 46.301265]],
  ['49854', [-86.33946, 46.042121]],
  ['49855', [-87.42223, 46.554402]],
  ['49858', [-87.60897, 45.1431]],
  ['49861', [-88.14742, 46.54081]],
  ['49862', [-86.70615, 46.37503]],
  ['49863', [-87.55411, 45.610678]],
  ['49864', [-86.66216, 45.840744]],
  ['49865', [-87.614805, 46.604141]],
  ['49866', [-87.58245, 46.517192]],
  ['49868', [-85.54758, 46.434201]],
  ['49869', [-87.533429, 46.07199]],
  ['49870', [-87.90824, 45.792399]],
  ['49871', [-87.58988, 46.440274]],
  ['49872', [-87.0742, 45.987113]],
  ['49873', [-87.50716, 45.889823]],
  ['49874', [-87.50966, 45.693128]],
  ['49876', [-87.99141, 45.80007]],
  ['49877', [-87.78294, 46.108829]],
  ['49878', [-86.90898, 45.926298]],
  ['49879', [-88.01972, 46.345957]],
  ['49880', [-87.20146, 46.086125]],
  ['49881', [-87.99607, 46.085875]],
  ['49883', [-85.98878, 46.460628]],
  ['49884', [-86.43315, 46.399545]],
  ['49885', [-87.25211, 46.356104]],
  ['49886', [-87.50457, 45.695551]],
  ['49887', [-87.6281, 45.417184]],
  ['49891', [-87.01715, 46.229173]],
  ['49892', [-87.78838, 45.755894]],
  ['49893', [-87.63114, 45.314014]],
  ['49894', [-87.07647, 45.778066]],
  ['49895', [-86.64901, 46.191294]],
  ['49896', [-87.37748, 45.685664]],
  ['49901', [-88.39491, 47.297841]],
  ['49902', [-88.37681, 46.045407]],
  ['49903', [-88.45043, 46.234686]],
  ['49905', [-88.71833, 47.101109]],
  ['49908', [-88.52645, 46.795417]],
  ['49910', [-89.57618, 46.576653]],
  ['49911', [-90.05138, 46.480948]],
  ['49912', [-89.15645, 46.46077]],
  ['49913', [-88.46121, 47.239083]],
  ['49915', [-88.62738, 46.063428]],
  ['49916', [-88.54077, 47.010896]],
  ['49917', [-88.38434, 47.283086]],
  ['49918', [-87.88212, 47.467597]],
  ['49919', [-88.52201, 46.559834]],
  ['49920', [-88.35677, 46.12624]],
  ['49921', [-88.57894, 47.093922]],
  ['49922', [-88.4818, 47.122751]],
  ['49924', [-88.297959, 47.414054]],
  ['49925', [-89.34434, 46.555946]],
  ['49927', [-88.59396, 46.056611]],
  ['49929', [-89.09762, 46.780926]],
  ['49930', [-88.57829, 47.145007]],
  ['49931', [-88.57259, 47.11929]],
  ['49934', [-88.43396, 47.170691]],
  ['49935', [-88.70073, 46.108022]],
  ['49938', [-90.15732, 46.47351]],
  ['49942', [-88.41589, 47.269659]],
  ['49943', [-88.879139, 46.421861]],
  ['49945', [-88.32904, 47.17583]],
  ['49946', [-88.37867, 46.766303]],
  ['49947', [-89.66518, 46.420235]],
  ['49948', [-89.02038, 46.730077]],
  ['49950', [-88.14768, 47.400603]],
  ['49952', [-88.84928, 46.7596]],
  ['49953', [-89.35119, 46.846455]],
  ['49955', [-88.6719, 47.034828]],
  ['49958', [-88.65751, 46.830728]],
  ['49959', [-89.99603, 46.470746]],
  ['49960', [-89.18126, 46.739853]],
  ['49961', [-88.73659, 46.469552]],
  ['49962', [-88.17166, 46.877]],
  ['49963', [-88.63936, 47.069257]],
  ['49964', [-88.62806, 46.081472]],
  ['49965', [-88.88179, 47.001469]],
  ['49967', [-89.0028, 46.459705]],
  ['49968', [-89.91876, 46.466645]],
  ['49969', [-89.26594, 46.258959]],
  ['49970', [-88.60459, 46.53227]],
  ['49971', [-89.57925, 46.754237]],
  ['49974', [-84.361116, 46.158139]],
  ['49990', [-88.375199, 46.045288]],
]);

// Longitude, Latitude for weather stations
const weatherStations = new Map<string, [number, number]>([
  ['KAPN', [-83.5603056, 45.0780833]],
  ['KCIU', [-84.4723847, 46.2507511]],
  ['KDTW', [-83.3533889, 42.2124444]],
  ['KFNT', [-83.74475, 42.9654722]],
  ['KGRR', [-85.5228056, 42.8808333]],
  ['KHTL', [-84.669483, 44.359869]],
  ['KIMT', [-88.1145556, 45.8183611]],
  ['KMBS', [-84.0796358, 43.5329269]],
  ['KTVC', [-85.5818611, 44.7415833]],
]);

// Use Detroit Metro Airport as a fallback
const defaultStation = 'KDTW';

function closestStation(point: [number, number]): string {
  let closestStation = defaultStation;
  let shortestDistance = -1;

  for (const [station, stationLocation] of weatherStations) {
    const distance = getDistance(point, stationLocation);
    if (shortestDistance === -1 || distance < shortestDistance) {
      closestStation = station;
      shortestDistance = distance;
    }
  }

  return closestStation;
}

export function weatherStation(zipcode: string | undefined | null): string | null {
  if (!zipcode) {
    return null;
  }

  const point = zipcodes.get(zipcode);
  if (!point) {
    return defaultStation;
  }

  return closestStation(point);
}
