import { useEffect, useState } from 'react';
import { ApiResponse } from './TemperatureRecord';
import { TemperatureGraphHelper } from './TemperatureDataHelper';
import { formatISO, getTrueEnd, getTrueStart } from '../../util/dates';
import { config } from '../../util/config';
import { UrlDetails, useUnauthenticatedJsonGet } from '../../util/AuthManager';
import { jsonEqual } from '../../util/utils';
import { useInterval } from '../../hooks/parameters/useInterval';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { missingValue } from '../../util/validation';
import { useJsonEqualState } from '../../hooks/hooks';

function dateRange(interval: string, startDate: Date, endDate: Date): [Date, Date] {
  return [getTrueStart(interval, startDate), getTrueEnd(interval, endDate)];
}

function useURL(): UrlDetails {
  const [url, setURL] = useJsonEqualState<UrlDetails>();
  const [interval] = useInterval();

  const account = useSelectedAccount();
  const weatherStation = account?.weatherStation;

  const [{ start: startDate, end: endDate }] = useSelectedDateRange();

  useEffect(() => {
    const [trueStart, trueEnd] = dateRange(interval, startDate, endDate);
    const params = {
      startDate: formatISO(trueStart),
      endDate: formatISO(trueEnd),
    };

    const requiredValues = [interval, trueStart, trueEnd, weatherStation];
    if (missingValue(requiredValues)) {
      setURL(undefined);
      return;
    }

    const url = `${config.apiBase}/weather/${weatherStation}/temperature/intervals/${interval}`;

    setURL({
      url: url,
      params: params,
    });
  }, [weatherStation, interval, startDate, endDate, setURL]);

  return url;
}

export function useTemperatureData(): TemperatureGraphHelper | undefined {
  const url = useURL();
  const [results, loading] = useUnauthenticatedJsonGet<ApiResponse>(url);
  const [temperatures, setTemperatures] = useState<TemperatureGraphHelper | undefined>(undefined);
  const [interval] = useInterval();

  useEffect(() => {
    if (url) {
      const data = results?.temperatures || [];
      setTemperatures(new TemperatureGraphHelper(url, interval, data));
    } else {
      setTemperatures(undefined);
    }
  }, [results, url, interval]);

  if (loading) {
    return undefined;
  }

  if (!jsonEqual(temperatures?.url, url)) {
    return undefined;
  }

  return temperatures;
}
