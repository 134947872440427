import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Spinner.scss';

interface Props {
  size?: 'xs' | 'sm' | 'lg' | '4x' | '1x' | '2x' | '3x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | undefined;
  className?: string;
}

function _Spinner(props: Props): JSX.Element {
  const size = props.size || '2x';

  let className = 'spinner';
  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <React.Fragment>
      <Row className={className}>
        <Col className="text-center">
          <FontAwesomeIcon icon={faSpinner} spin title="Loading" size={size} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
export const Spinner = React.memo(_Spinner);
