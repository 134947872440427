import { jsonEqual } from './utils';

export function isBlankValue(value: unknown): boolean {
  if (value === '') {
    return true;
  }
  if (value === undefined) {
    return true;
  }
  if (value === null) {
    return true;
  }
  if (jsonEqual(value, {})) {
    return true;
  }
  return false;
}

export function missingValue(values: unknown[]): boolean {
  if (!values?.length) {
    return true;
  }

  for (const value of values) {
    if (isBlankValue(value)) {
      return true;
    }
  }

  return false;
}

export function validateToken(token: string | undefined | null): string {
  if (!token) {
    throw new Error('missing token');
  }

  if (token.length < 100) {
    throw new Error('invalid token');
  }

  return token;
}

const validProductTypes = ['electric', 'gas'];
export function validateProductType(productType: string | undefined | null): string {
  if (!productType) {
    throw new Error('missing product type');
  }

  if (!validProductTypes.includes(productType)) {
    throw new Error('invalid product type');
  }

  return productType;
}

export function validateAccountNumber(accountNumber: string | undefined | null): string {
  if (accountNumber?.length !== 12) {
    throw new Error('invalid account number');
  }

  return accountNumber;
}

const validElectricIntervals = ['hour', 'day', 'month'];
const validGasIntervals = ['day', 'month'];
export function validateInterval(interval: string | undefined | null, productType?: string): string {
  if (!interval) {
    throw new Error('missing interval');
  }

  // Just translate hour to day for gas
  if (productType === 'gas' && interval === 'hour') {
    return 'day';
  }

  if (!validElectricIntervals.includes(interval)) {
    throw new Error('invalid interval');
  }

  return interval;
}
