import React, { useState, useContext } from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import { Account } from '../AccountList/account';
import { SearchFilter } from './SearchFilter';
import { AccountListItem } from './AccountListItem';
import './SelectAccount.scss';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { AccountContext } from '../../contexts/AccountContext/AccountContext';

export const SelectAccountModal: React.FunctionComponent = () => {
  const context = useContext(AccountContext);
  const accountList = context.accountList;
  const [accountNumber, setAccountNumber] = useAccountNumber();

  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const selectAccount = (account: Account) => {
    setAccountNumber(account?.accountNumber);
    closeModal();
  };

  // Only show account selection option if more than 1 account
  if (!accountList || accountList?.accounts.length < 2) {
    return null;
  }

  const listItems = accountList.accounts.map((account) => {
    return (
      <AccountListItem
        account={account}
        key={account.accountNumber}
        filter={filter}
        setAccount={selectAccount}
        selected={account?.accountNumber === accountNumber}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="switchAccount">
        <Button
          variant="link"
          onClick={openModal}
          data-track="usage"
          data-track-detail="usage customerInformation"
          data-track-action="click"
          data-track-sub-action="switch account"
        >
          Switch Accounts
        </Button>
      </div>

      <Modal centered scrollable size="lg" show={showModal} animation={false} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SearchFilter filter={filter} setFilter={setFilter} />
          <ListGroup defaultActiveKey={accountNumber} className="selectAccounts">
            {listItems}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
