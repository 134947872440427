import { startOfMonth, subMonths } from 'date-fns';
import React, { useEffect } from 'react';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';

export const YearSelection: React.FunctionComponent = () => {
  // TODO: add more here if we want to select between multiple years
  const [, dateLimitMax] = useDateLimits();
  const [, setDateRange] = useSelectedDateRange();

  useEffect(() => {
    if (!dateLimitMax) {
      return;
    }

    const newRange = {
      start: startOfMonth(subMonths(dateLimitMax, 13)),
      end: dateLimitMax,
    };
    setDateRange(newRange);
  }, [dateLimitMax, setDateRange]);

  return null;
};
