import React from 'react';
import { format, isValid } from 'date-fns';

function alwaysShowMonthLabel(index: number, tickCount: number): boolean {
  if (index < 0 || index > tickCount - 1) {
    return false;
  }

  if (index === 0) {
    return true;
  }

  return index % 2 === 0;
}

function monthBlankLabel(index: number, tickCount: number): JSX.Element {
  let className = 'minus';
  const labelBefore = alwaysShowMonthLabel(index - 1, tickCount);
  const labelAfter = alwaysShowMonthLabel(index + 1, tickCount);
  if (labelBefore || labelAfter) {
    className += ' d-lg-none';
  }
  return <tspan className={className}>-</tspan>;
}

// Determine if a start date is the first date of its year
function firstOfYear(index: number, ticks: number[]): boolean {
  if (index <= 0) {
    return true;
  }

  const midpoint = ticks[index];

  const datumDate = new Date(midpoint);
  const datumYear = datumDate.getFullYear();

  const years = ticks.map((timestamp: number) => {
    const tempDate = new Date(timestamp);
    return tempDate.getFullYear();
  });

  return index === years.indexOf(datumYear);
}

function monthYearLabel(index: number, ticks: number[], x: number): JSX.Element | null {
  let showYear = firstOfYear(index, ticks);

  const midpoint = ticks[index];
  const date = new Date(midpoint);
  if (!isValid(date)) {
    return null;
  }

  let className;
  if (showYear && !alwaysShowMonthLabel(index, ticks.length)) {
    className = 'd-none d-lg-inline';
  } else if (!showYear && alwaysShowMonthLabel(index, ticks.length)) {
    showYear = firstOfYear(index - 1, ticks);
    className = 'd-lg-none';
  }

  if (!showYear) {
    return null;
  }

  const formatted = format(date, 'yyyy');
  return (
    <tspan x={x} dy="1.4em" className={className}>
      {formatted}
    </tspan>
  );
}

export function XAxisLabelMonth(midpoint: number, ticks: number[], x: number, y: number): JSX.Element {
  const index = ticks.indexOf(midpoint);

  const date = new Date(midpoint);

  const monthText = format(date, 'MMM');

  let monthLabel;
  if (!alwaysShowMonthLabel(index, ticks.length)) {
    monthLabel = (
      <React.Fragment>
        <tspan className="d-none d-lg-inline">{monthText}</tspan>
        {monthBlankLabel(index, ticks.length)}
      </React.Fragment>
    );
  } else {
    monthLabel = monthText;
  }

  const yearLabel = monthYearLabel(index, ticks, x);

  return (
    <text x={x} y={y + 12} textAnchor="middle" orientation="bottom">
      {monthLabel}
      {yearLabel}
    </text>
  );
}
