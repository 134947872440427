import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AccountContext } from '../../contexts/AccountContext/AccountContext';
import { useJsonEqualState } from '../../hooks/hooks';
import { useProductType } from '../../hooks/parameters/useProductType';

import { SmartRadioGroup, RadioGroupChoice } from '../SmartRadioGroup/SmartRadioGroup';

interface Props {
  children?: React.ReactNode;
}

const validChoices: Map<string, RadioGroupChoice> = new Map<string, RadioGroupChoice>([
  ['electric', { value: 'electric', label: 'Electric (Hourly)' }],
  ['gas', { value: 'gas', label: 'Gas (Daily)' }],
]);

const noDataMessage = (
  <Container>
    <Row>
      <Col>
        <div className="error">Your energy usage data is unavailable for this account.</div>
      </Col>
    </Row>
  </Container>
);

function getChoices(productTypes?: string[]) {
  const choices: RadioGroupChoice[] = [];
  for (const value of productTypes || []) {
    const choice = validChoices.get(value);
    if (choice) {
      choices.push(choice);
    }
  }
  return choices;
}

function useProductTypeOptions(): string[] {
  const context = useContext(AccountContext);
  const accounts = context.accountList;

  const [choices, setChoices] = useJsonEqualState<string[]>([]);

  useEffect(() => {
    const newChoices = [];
    if (accounts.electricAccounts) {
      newChoices.push('electric');
    }
    if (accounts.gasAccounts) {
      newChoices.push('gas');
    }
    setChoices(newChoices);
  }, [accounts?.electricAccounts, accounts?.gasAccounts, setChoices]);

  return choices;
}

export const ProductTypeOptions: React.FunctionComponent<Props> = (props: Props) => {
  const [choices, setChoices] = useState<RadioGroupChoice[]>([]);

  const productTypeOptions = useProductTypeOptions();
  const [productType, setProductType] = useProductType();

  useEffect(() => {
    const newChoices = getChoices(productTypeOptions);
    setChoices(newChoices);
  }, [productTypeOptions]);

  if (!choices.length) {
    return (
      <React.Fragment>
        {noDataMessage}
        {props.children}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h5>Type of Service</h5>
        </Col>
      </Row>
      <SmartRadioGroup
        name="productType"
        defaultChoice={productType}
        choices={choices}
        onChoiceChange={setProductType}
      />
      {props.children}
    </React.Fragment>
  );
};
