import React from 'react';
import {
  format,
  startOfMonth,
  startOfDay,
  endOfToday,
  endOfDay,
  endOfMonth,
  addMonths,
  startOfHour,
  endOfHour,
  isBefore,
  addDays,
  addHours,
  parseISO,
  isValid,
} from 'date-fns';
import memoize from 'fast-memoize';

export function formatISO(date: Date): string {
  if (!isValid(date)) {
    return undefined;
  }
  return format(date, 'yyyy-MM-dd');
}

export function midpoint(startDate: Date, endDate: Date): number {
  const start = startDate.getTime();
  const end = endDate.getTime();
  return Math.round((start + end) / 2);
}

export function intervalMidpoint(interval: string, date: Date): number {
  let start = date;
  let end = date;
  switch (interval) {
    case 'hour':
      start = startOfHour(date);
      end = endOfHour(date);
      break;
    case 'day':
      start = startOfDay(date);
      end = endOfDay(date);
      break;
    case 'month':
      start = startOfMonth(date);
      end = endOfMonth(date);
      break;
  }
  return midpoint(start, end);
}

export function getTrueStart(interval: string, startDate: Date | null | undefined): Date | null {
  if (!startDate) {
    return null;
  }

  // if (interval === 'hour') {
  return startOfDay(startDate);
  // }

  // return startOfMonth(startDate);
}

export function getTrueEnd(interval: string, endDate: Date | null | undefined): Date | null {
  if (!endDate) {
    return null;
  }

  let trueEnd = endDate;

  if (interval === 'hour') {
    endOfDay(endDate);
  }

  // if (interval === 'day') {
  //   trueEnd = endOfMonth(endDate);
  // }

  if (interval === 'month') {
    trueEnd = endOfMonth(endDate);
  }

  return trueEnd;
}

export function getDayMidpoint(date: Date): number {
  const start = startOfDay(date);
  const end = endOfDay(date);
  return midpoint(start, end);
}

export function getHourMidpoint(date: Date): number {
  const start = startOfHour(date);
  const end = endOfHour(date);
  const mid = midpoint(start, end);
  return mid;
}

export function getMonthMidpoint(date: Date): number {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return midpoint(start, end);
}

export function midpointForInterval(interval: string, date: Date | undefined | null): number {
  if (!date) {
    return 0;
  }

  switch (interval) {
    case 'hour':
      return getHourMidpoint(date);
    case 'day':
      return getDayMidpoint(date);
    case 'month':
      return getMonthMidpoint(date);
  }

  return 0;
}

function getMidpointsForDay(day: Date): number[] {
  const midpoints = [];
  const start = startOfDay(day).getTime();

  // 24 hours
  for (let i = 0; i < 24; i++) {
    let date = new Date(start);
    date = addHours(date, i);

    const mid = getHourMidpoint(date);
    midpoints.push(mid);
  }

  return midpoints;
}

function getMidpointsForDateRange(startDate: Date | null, endDate: Date | null): number[] {
  const midpoints = [];
  let current = startOfDay(startDate || new Date());
  const end = endOfDay(endDate || new Date());

  while (isBefore(current, end)) {
    const dayStart = startOfDay(current);
    const dayEnd = endOfDay(current);
    midpoints.push(midpoint(dayStart, dayEnd));
    current = addDays(current, 1);
  }

  return midpoints;
}

function getMidpointsForYear(startDate: Date, endDate: Date): number[] {
  const midpoints = [];
  const end = endOfMonth(endDate);
  let current = startOfMonth(startDate);

  while (isBefore(current, end)) {
    const monthStart = startOfMonth(current);
    const monthEnd = endOfMonth(current);
    midpoints.push(midpoint(monthStart, monthEnd));
    current = addMonths(current, 1);
  }

  return midpoints;
}

export function getMidpointsForInterval(interval: string, startDate: Date, endDate: Date): number[] {
  const trueStart = getTrueStart(interval, startDate) || new Date();
  const trueEnd = getTrueEnd(interval, endDate) || new Date();

  if (interval === 'month') {
    return getMidpointsForYear(trueStart, trueEnd);
  }

  if (interval === 'day') {
    return getMidpointsForDateRange(trueStart, trueEnd);
  }

  return getMidpointsForDay(trueStart);
}

function getHourDate(day: string | undefined | null, hour: number | undefined | null): Date | null {
  if (!day) {
    return null;
  }
  if (hour === undefined || hour === null) {
    return null;
  }

  try {
    let date = parseISO(day);
    date = startOfDay(date);
    const dateHour = Math.floor(hour);
    date = addHours(date, dateHour);
    return date;
  } catch {
    // Invalid date
  }

  return null;
}

function getDayDate(day: string | undefined | null): Date | null {
  if (!day) {
    return null;
  }

  try {
    let date = parseISO(day);
    date = startOfDay(date);
    return date;
  } catch {
    // Invalid date
  }

  return null;
}

function getMonthDate(month: string | undefined | null): Date | null {
  if (!month) {
    return null;
  }

  try {
    let date = parseISO(month);
    date = startOfDay(date);
    return date;
  } catch {
    // Invalid date
  }

  return null;
}

export function parseIntervalDate(
  interval: string,
  month: string | undefined | null,
  day: string | undefined | null,
  hour: number | undefined | null
): Date | null {
  switch (interval) {
    case 'hour':
      return getHourDate(day, hour);
    case 'day':
      return getDayDate(day);
    case 'month':
      return getMonthDate(month);
  }

  return null;
}

function _formatMonth(date: Date, breakpoint: string): JSX.Element | string {
  const longMonth = format(date, 'MMMM');
  if (longMonth.length < 5) {
    return longMonth;
  }

  const longMonthClassName = 'd-none d-' + breakpoint + '-inline';
  const shortMonthClassName = 'd-' + breakpoint + '-none';

  const shortMonth = format(date, 'MMM');
  return (
    <React.Fragment>
      <span className="sr-only">{longMonth}</span>
      <span aria-hidden="true">
        <span className={longMonthClassName}>{longMonth}</span>
        <span className={shortMonthClassName}>{shortMonth}</span>
      </span>
    </React.Fragment>
  );
}
export const formatMonth = memoize(_formatMonth);

function _beforeToday(date: Date | null | undefined): boolean {
  if (!date) {
    return false;
  }

  return isBefore(date, endOfToday());
}
export const beforeToday = memoize(_beforeToday);
