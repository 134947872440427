import React, { useContext } from 'react';
import { Button, Accordion, Card, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { formatDigits, jsonEqual } from '../../util/utils';
import './UsageTable.scss';
import { PeakTimeSavingsEvent } from '../PeakTimeSavingsData/PeakTimeSavingsHelper';
import { format } from 'date-fns';

interface ExpandButtonProps {
  eventKey: string;
  children?: React.ReactNode;
}
function ExpandContractButton(props: ExpandButtonProps) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(props.eventKey);

  const isCurrentEventKey = currentEventKey === props.eventKey;

  if (isCurrentEventKey) {
    return (
      <Button
        onClick={decoratedOnClick}
        className="accordion expanded intervalButton"
        aria-expanded="true"
        data-track="usage"
        data-track-detail="usage shiftandsave"
        data-track-action="click"
        data-track-sub-action="contract table"
      >
        <span className="sr-only">Hide </span>
        {props.children}
      </Button>
    );
  }

  return (
    <Button
      onClick={decoratedOnClick}
      className="accordion intervalButton"
      aria-expanded="false"
      data-track="usage"
      data-track-detail="usage shiftandsave"
      data-track-action="click"
      data-track-sub-action="expand table"
    >
      <span className="sr-only">Show </span>
      {props.children}
    </Button>
  );
}

function renderRow(event: PeakTimeSavingsEvent, index: number): JSX.Element {
  return (
    <tr key={index}>
      <td>{format(event.date, 'M/d')}</td>
      <td>{formatDigits(0).format(event.baseline)}</td>
      <td>{formatDigits(0).format(event.actual)}</td>
      <td>{formatDigits(0).format(event.reduced)}</td>
      <td>${formatDigits(2).format(event.credit)}</td>
    </tr>
  );
}

interface TableProps {
  events: PeakTimeSavingsEvent[];
  children?: React.ReactNode;
}
const _Table: React.FunctionComponent<TableProps> = (props: TableProps) => {
  const events = props.events;

  const rows = events.map((event: PeakTimeSavingsEvent, index: number) => {
    return renderRow(event, index);
  });

  return (
    <table className="peakUsageTable">
      <thead>
        <tr>
          <th>Event Date</th>
          <th>
            Baseline
            <br className="d-lg-none" /> kWh Use
          </th>
          <th>
            Actual
            <br className="d-lg-none" /> kWh Use
          </th>
          <th>
            kWh
            <br className="d-lg-none" /> Reduced
          </th>
          <th>Credit</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};
const Table = React.memo(_Table, jsonEqual);

interface Props {
  events: PeakTimeSavingsEvent[];
  children?: React.ReactNode;
}

export const UsageTable: React.FunctionComponent<Props> = (props: Props) => {
  const events = props.events;

  if (!events.length) {
    return null;
  }

  return (
    <Accordion>
      <ExpandContractButton eventKey="0">Detailed Usage Table</ExpandContractButton>
      <Accordion.Collapse eventKey="0">
        <Card.Body className="p-0 peakTimeSavingsTableWrapper">
          <Table events={events} />
        </Card.Body>
      </Accordion.Collapse>
    </Accordion>
  );
};
