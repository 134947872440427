import { parseIntervalDate, midpointForInterval } from '../../util/dates';
import { TemperatureRecord } from './TemperatureRecord';

function midpoint(interval: string, date: Date | null, hour?: number): number {
  // For hour, just use the hour of day to avoid issues with DST transitions
  if (interval === 'hour') {
    return hour || 0;
  }
  return midpointForInterval(interval, date);
}

export class TemperatureData {
  readonly interval: string;
  readonly record: TemperatureRecord;
  readonly high: number;
  readonly low: number;
  readonly average: number;
  readonly range: [number, number];
  readonly date: Date | null;
  readonly midpoint: number;

  constructor(interval: string, record: TemperatureRecord) {
    this.interval = interval;
    this.record = record;
    this.high = record.high;
    this.low = record.low;
    this.average = record.average;
    this.range = [this.low, this.high];
    this.date = parseIntervalDate(interval, record.month, record.day, record.hour);
    this.midpoint = midpoint(interval, this.date, record.hour);
  }
}
