import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthenticatedCustomerWrapper } from './contexts/TokenContext/AuthenticatedCustomerWrapper';
import { AuthenticatedEmployeeWrapper } from './contexts/TokenContext/AuthenticatedEmployeeWrapper';
import { isLocalhost } from './contexts/TokenContext/isLocalhost';
import { DownloadData } from './routes/DownloadData/DownloadData';
import { ElectricTimeOfDayGraph } from './routes/ElectricTimeOfDayGraph/ElectricTimeOfDayGraph';
import { PeakTimeSavings } from './routes/PeakTimeSavings/PeakTimeSavings';
import { Footer } from './components/Footer/Footer';
import { isEmployee } from './util/AuthManager';
import { GasGraph } from './routes/GasGraph/GasGraph';
import { AccountListWrapper } from './components/AccountList/AccountListWrapper';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import queryString from 'query-string';

interface Props {
  children?: React.ReactNode;
}

const AuthWrapper: React.FunctionComponent<Props> = (props: Props) => {
  console.log('Auth wrapper');
  if (isEmployee()) {
    console.log('employee');
    return (
      <React.Fragment>
        <AuthenticatedEmployeeWrapper>{props.children}</AuthenticatedEmployeeWrapper>
      </React.Fragment>
    );
  }

  console.log('customer');
  return (
    <React.Fragment>
      <AuthenticatedCustomerWrapper>
        {props.children}
        <Footer />
      </AuthenticatedCustomerWrapper>
    </React.Fragment>
  );
};

const AppContent: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
          }}
        >
          <Routes>
            <Route
              path={'/:cr?/peaktimesavings'}
              element={
                <AuthWrapper>
                  <AccountListWrapper pageName="Peak Time Savings" productTypeFilter="electric" includeLinks={false}>
                    <PeakTimeSavings />
                  </AccountListWrapper>
                </AuthWrapper>
              }
            />
            <Route
              path={'/:cr?/gasDashboard'}
              element={
                <AuthWrapper>
                  <AccountListWrapper pageName="Gas Dashboard" productTypeFilter="gas" includeLinks={true}>
                    <GasGraph />
                  </AccountListWrapper>
                </AuthWrapper>
              }
            />
            <Route
              path={'/:cr?/download'}
              element={
                <AuthWrapper>
                  <AccountListWrapper pageName="Energy Usage Data" includeLinks={true}>
                    <DownloadData />
                  </AccountListWrapper>
                </AuthWrapper>
              }
            />
            <Route
              path={'*'}
              element={
                <AuthWrapper>
                  <AccountListWrapper pageName="Electric Dashboard" productTypeFilter="electric" includeLinks={true}>
                    <ElectricTimeOfDayGraph />
                  </AccountListWrapper>
                </AuthWrapper>
              }
            />
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </React.Fragment>
  );
};

export const App: React.FunctionComponent = () => {
  if (isLocalhost()) {
    window.location.href = 'https://usage-test.dteenergy.com/';
    return null;
  }

  return <AppContent />;
};
