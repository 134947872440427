import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Account } from '../AccountList/account';

interface AccountListItemProps {
  account: Account;
  selected: boolean;
  setAccount: (acccount: Account) => void;
  filter: string;
}

function filterMatchesAccount(account: Account, filter: string): boolean {
  if (!filter) {
    return true;
  }

  const address = account.address;
  if (address && address.matchesFilter(filter)) {
    return true;
  }

  return account.accountNumber.includes(filter) || account.formattedAccountNumber.includes(filter);
}

export const AccountListItem: React.FunctionComponent<AccountListItemProps> = (props: AccountListItemProps) => {
  const account = props.account;
  if (!account.address) {
    return null;
  }

  // Don't display anything if the account doesn't match the filter
  if (!filterMatchesAccount(account, props.filter)) {
    return null;
  }

  const switchAccount = () => {
    props.setAccount(account);
  };

  return (
    <React.Fragment>
      <ListGroup.Item
        action
        key={account.accountNumber}
        disabled={props.selected}
        onClick={switchAccount}
        data-track="usage"
        data-track-detail="usage customerInformation"
        data-track-action="click"
        data-track-sub-action="select account"
      >
        <div>{account.address.formatCondensed}</div>
        <div>
          <span className="accountNumber">Account Number</span>
          {account.formattedAccountNumber}
        </div>
      </ListGroup.Item>
    </React.Fragment>
  );
};
