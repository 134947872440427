import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function LowTempTriangle(props: any): JSX.Element {
  if (!props.lowestTemp) {
    return null;
  }

  return (
    <svg width={14} height={14} x={props.x - 3} y={props.y} viewBox="0 0 18 16">
      <path
        d="M7.26795 13.5C8.03775 14.8333 9.96225 14.8333 10.7321 13.5L15.9282 4.5C16.698 3.16667 15.7358 1.5 14.1962 1.5L3.80385 1.5C2.26425 1.5 1.302 3.16666 2.0718 4.5L7.26795 13.5Z"
        fill="#1E3575"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function HighTempTriangle(props: any): JSX.Element {
  if (!props.highestTemp) {
    return null;
  }

  return (
    <svg
      width={14}
      height={14}
      x={props.x - 3}
      y={props.y - 3}
      cx={props.cx}
      cy={props.cy}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9282 11.5L10.7321 2.5C9.96225 1.16667 8.03775 1.16666 7.26795 2.5L2.0718 11.5C1.302 12.8333 2.26424 14.5 3.80385 14.5H14.1962C15.7358 14.5 16.698 12.8333 15.9282 11.5Z"
        fill="#E31937"
        stroke="white"
        strokeWidth="2"
      />
      {/* <polygon points="0 100, 50 0, 100 100" /> */}
    </svg>
  );
}
