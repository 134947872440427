import { startOfMonth, subDays, subMonths } from 'date-fns';
import React, { SyntheticEvent, ChangeEvent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DateRange } from '../../contexts/DateContext/DateContext';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { useInterval } from '../../hooks/parameters/useInterval';
import { useMeterNumber } from '../../hooks/parameters/useMeterNumber';
import { useProductType } from '../../hooks/parameters/useProductType';
import { formatISO } from '../../util/dates';

function hourOption(interval: string | undefined): JSX.Element {
  let className = 'option';
  if (interval === 'hour') {
    className += ' selected';
  }
  return (
    <option key="hour" value="hour" className={className}>
      Day
    </option>
  );
}

function dayOption(interval: string | undefined): JSX.Element {
  let className = 'option';
  if (interval === 'day') {
    className += ' selected';
  }
  return (
    <option key="day" value="day" className={className}>
      Date Range
    </option>
  );
}

function monthOption(interval: string | undefined): JSX.Element {
  let className = 'option';
  if (interval === 'month') {
    className += ' selected';
  }
  return (
    <option key="month" value="month" className={className}>
      13 Month
    </option>
  );
}

function newUrl(
  accountNumber: string,
  meterNumber: string,
  interval: string,
  dateRange: DateRange,
  dateMinLimit: Date,
  dateMaxLimit: Date
): string {
  let newStart = dateRange?.start;
  let newEnd = dateRange?.end;

  // When we change interval, set the new date range based on that
  if (interval === 'hour') {
    newStart = newEnd;
  } else if (interval === 'day') {
    newStart = subDays(newEnd, 35);
  } else if (interval === 'month') {
    newEnd = dateMaxLimit;
    newStart = startOfMonth(subMonths(newEnd, 13));
  }

  const startDate = formatISO(newStart);
  const endDate = formatISO(newEnd);

  const url = `?accountNumber=${accountNumber}&meterNumber=${meterNumber}&interval=${interval}&startDate=${startDate}&endDate=${endDate}`;
  return url;
}

export const IntervalOptions: React.FunctionComponent = () => {
  const [interval, setInterval] = useInterval();
  const [productType] = useProductType();
  const [accountNumber] = useAccountNumber();
  const [meterNumber] = useMeterNumber();
  const [dateRange] = useSelectedDateRange();
  const [dateMinLimit, dateMaxLimit] = useDateLimits();
  const navigate = useNavigate();

  function changeInterval(event: ChangeEvent<HTMLSelectElement>): void {
    const newInterval = event.currentTarget.value;
    const url = newUrl(accountNumber, meterNumber, newInterval, dateRange, dateMinLimit, dateMaxLimit);
    navigate(url);
    event.stopPropagation();
  }

  const optionsList = [];
  if (productType === 'electric') {
    optionsList.push(hourOption(interval));
  }
  optionsList.push(dayOption(interval));
  optionsList.push(monthOption(interval));

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }}>
        <Form.Group as="fieldset">
          <Form.Label htmlFor="interval">Time Period</Form.Label>
          <Form.Control
            id="interval"
            as="select"
            value={interval}
            onChange={changeInterval}
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
            data-track-detail="usage shiftandsave"
            data-track-action="click"
            data-track-sub-action="change interval"
          >
            {optionsList}
          </Form.Control>
        </Form.Group>
      </Col>
    </React.Fragment>
  );
};
