import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { config } from '../../util/config';

export const GenerationInfo: React.FunctionComponent = () => {
  const generationLink = `${config.commerceNonCommodityBase}/dte/en/Services/CleanVision-Service/Rooftop-Solar-and-Private-Generation/p/ROOFTOP_SOLAR`;
  const consentFormLink =
    config.webBase +
    '/wps/wcm/connect/590fe386-101a-46b7-b40b-4a2d9d304226/customer-consent-release-form.pdf?MOD=AJPERES';

  return (
    <Row>
      <Col className="desktopIndent mt-3 mb-3">
        <b>Rooftop Solar and Private Generation Customers</b>: You can request additional energy usage information,
        including outflow data, by completing the{' '}
        <a href={consentFormLink} target="_blank" rel="noreferrer">
          Customer Consent Release Form
        </a>
        .
        <br />
        <br />
        <a href={generationLink} target="_blank" rel="noreferrer">
          Learn more about Rooftop Solar and Private Generation.
        </a>
      </Col>
    </Row>
  );
};
