import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MsalProvider, MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from '@azure/msal-react';
import { SilentRequest, InteractionType, PublicClientApplication, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from '../../util/employeeAuthConfig';
import { isEmployee } from '../../util/AuthManager';
import { TokenContextWrapper } from './TokenContextWrapper';
import { Spinner } from '../../components/Spinner/Spinner';
import { loginRequest } from '../../util/employeeAuthConfig';
import { addMinutes, isBefore } from 'date-fns';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { isGoodToken } from './isGoodToken';

interface Props {
  children?: React.ReactNode;
}

function needsNewToken(response: AuthenticationResult): boolean {
  const expiry = response?.expiresOn;
  if (!expiry) {
    return true;
  }

  const expiryThreshold = addMinutes(new Date(), 5);
  const expired = isBefore(response?.expiresOn, expiryThreshold);
  if (expired) {
    return true;
  }

  return !isGoodToken(response?.idToken);
}

const InnerWrapper: React.FunctionComponent<Props> = (props: Props) => {
  // Attemp silent login first
  const { accounts, instance, inProgress } = useMsal();
  const [token, setToken] = useState('');
  const [accountNumber] = useAccountNumber();

  useEffect(() => {
    if (inProgress !== 'none') {
      return;
    }

    const username = accounts[0]?.username;
    if (!username) {
      return;
    }

    const account = instance.getAccountByUsername(username);
    if (!account) {
      return;
    }

    const tokenRequest: SilentRequest = {
      scopes: ['openid'],
      account: account,
    };
    instance
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        if (needsNewToken(response)) {
          // Got a token but cached token was expired (or is about to expire), reloading to force getting a new token
          void instance.acquireTokenRedirect(loginRequest);
          return;
        }

        setToken(response.idToken || '');
      })
      .catch((e) => {
        console.error(e);

        // If token failed, try reloading to trigger another login
        void instance.acquireTokenRedirect(loginRequest);
      });
  }, [accounts, inProgress, instance]);

  if (!token || inProgress !== 'none') {
    return <Spinner size="4x" className="fullPageSpinner" />;
  }

  // TODO: Need to ensure that employee has an account selected
  if (!accountNumber) {
    return (
      <Container>
        <Row>
          <Col>
            <p>
              No account number was specified. Try closing this window and clicking on a link to the site again to
              continue.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <TokenContextWrapper token={token}>{props.children}</TokenContextWrapper>
    </React.Fragment>
  );
};

let msalInstance: PublicClientApplication | null = null;
if (isEmployee()) {
  msalInstance = new PublicClientApplication(msalConfig);
}

const LoginError: React.FunctionComponent<MsalAuthenticationResult> = (error: MsalAuthenticationResult) => {
  let message = 'Error signing in';
  if (error.error?.message?.includes('AADSTS50105')) {
    message = 'Your account does not have access to this application.';
  }

  return (
    <Container className="bodyContent">
      <Row className="error p-3">
        <Col>{message}</Col>
      </Row>
    </Container>
  );
};

export const AuthenticatedEmployeeWrapper: React.FunctionComponent<Props> = (props: Props) => {
  if (!msalInstance) {
    return null;
  }

  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={LoginError}
        >
          <InnerWrapper>{props.children}</InnerWrapper>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </React.Fragment>
  );
};
