import { getDate } from '../../common/dates/dates';
import { config } from '../../util/config';

export interface ApiSharedLinkResponse {
  canManage: boolean;
  link?: ApiSharedLink;
}

export interface ApiSharedLink {
  uuid: string;
  creationDate: string;
  lastAccessedDate: string;
  accessCount: number;
}

export class SharedLink {
  private apiSharedLinkResponse: ApiSharedLinkResponse;

  constructor(apiSharedLinkResponse: ApiSharedLinkResponse) {
    this.apiSharedLinkResponse = apiSharedLinkResponse || { canManage: false };
  }

  get canManage(): boolean {
    return this.apiSharedLinkResponse?.canManage;
  }

  get url(): string {
    const uuid = this.apiSharedLinkResponse?.link?.uuid;
    if (!uuid) {
      return undefined;
    }

    return `${config.sharedLinkBase}/link/${uuid}`;
  }

  get lastAccessedDate(): Date {
    return getDate(this.apiSharedLinkResponse?.link?.lastAccessedDate);
  }

  get accessCount(): number {
    const accessCount = this.apiSharedLinkResponse?.link?.accessCount;
    if (!accessCount || accessCount < 0) {
      return 0;
    }
    return accessCount;
  }
}
