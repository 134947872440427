import React, { useEffect, useState } from 'react';
import { min, max } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import { ApiResponse, DatesAvailableRecord } from './DatesAvailableRecord';
import { validateAccountNumber, validateProductType } from '../../util/validation';
import { config } from '../../util/config';
import { UrlDetails, useAuthenticatedJsonGet } from '../../util/AuthManager';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { useProductType } from '../../hooks/parameters/useProductType';
import { getDate } from '../../common/dates/dates';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { DateContextWrapper } from '../../contexts/DateContext/DateContextWrapper';

function earliestReadDate(records: DatesAvailableRecord[]): Date {
  if (!records?.length) {
    return undefined;
  }

  let dateValues = records.map((record) => {
    return getDate(record.earliestReadDate);
  });
  // Filter empty
  dateValues = dateValues.filter((dateValue) => dateValue);

  return min(dateValues);
}

function mostRecentReadDate(records: DatesAvailableRecord[]): Date {
  if (!records?.length) {
    return undefined;
  }

  let dateValues = records.map((record) => {
    return getDate(record.mostRecentReadDate);
  });
  // Filter empty
  dateValues = dateValues.filter((dateValue) => dateValue);

  return max(dateValues);
}

interface Props {
  children?: React.ReactNode;
}

function useURL(): UrlDetails | null {
  const [url, setURL] = useState<UrlDetails | null>(null);
  const [accountNumber] = useAccountNumber();
  const [productType] = useProductType();

  useEffect(() => {
    let newURL: UrlDetails | null = null;

    try {
      const validAccount = validateAccountNumber(accountNumber);
      const validProduct = validateProductType(productType);
      newURL = {
        url: `${config.apiBase}/authenticated/accounts/${validAccount}/usage/datesAvailable/${validProduct}`,
      };
    } catch {
      // At least one parameter was invalid
    }

    setURL(newURL);
  }, [accountNumber, productType]);

  return url;
}

export const useDatesAvailable = (): [Date, Date, boolean] => {
  const url = useURL();
  const [results, loading] = useAuthenticatedJsonGet<ApiResponse>(url);
  const [minLimit, setMinLimit] = useState<Date>();
  const [maxLimit, setMaxLimit] = useState<Date>();

  useEffect(() => {
    // Don't do anything until we have results
    if (loading) {
      return;
    }

    const newMin = earliestReadDate(results?.meters);
    const newMax = mostRecentReadDate(results?.meters);

    setMinLimit(newMin);
    setMaxLimit(newMax);
  }, [results, loading]);

  return [minLimit, maxLimit, loading];
};

const InnerContent: React.FunctionComponent<Props> = (props: Props) => {
  const [, dateLimitMax] = useDateLimits();
  const [productType] = useProductType();

  if (!dateLimitMax) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className="error pt-3 pb-2">Your {productType || ''} usage data is unavailable for this account.</div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export const DatesAvailableWrapper: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <DateContextWrapper>
      <InnerContent>{props.children}</InnerContent>
    </DateContextWrapper>
  );
};
