import { uniqueValues } from '../../util/utils';
import { weatherStation } from '../TemperatureData/weatherStation';
import { ApiAddress, Address } from './address';

export interface ApiResponse {
  accounts: ApiAccount[];
}

export interface ApiAccount {
  customerName: string;
  accountNumber: string;
  premiseNumber: string;
  meterNumber: string;
  addressLine1: string;
  addressLine2: string | undefined;
  city: string;
  state: string;
  zipcode: string;
  tmsCode: string;
  productType: string;
  rateCategory: string;
  amiMeter: boolean;
  address: ApiAddress | null;
  effectiveDate: string;
  lastEffectiveDate: string;
  canManageSharedLink?: boolean;
}

function address(apiAccountList: ApiAccount[]): Address | null {
  if (!apiAccountList?.length) {
    return null;
  }

  const account = apiAccountList[0];
  const address = {
    addressLine1: account.addressLine1,
    addressLine2: account.addressLine2,
    city: account.city,
    state: account.state,
    zipcode: account.zipcode,
  };

  return new Address(address);
}

export class Account {
  readonly apiAccountList: ApiAccount[];
  readonly address: Address | null;
  readonly weatherStation: string | null;

  constructor(apiAccountList: ApiAccount[]) {
    this.apiAccountList = apiAccountList || [];
    this.address = address(this.apiAccountList);
    this.weatherStation = weatherStation(this.address?.zipcode);
  }

  get hasData(): boolean {
    return this.apiAccountList && this.apiAccountList.length > 0;
  }

  get accountNumber(): string {
    return this.apiAccountList[0]?.accountNumber || '';
  }

  get formattedAccountNumber(): string {
    return this.accountNumber.replace(/(\d{4})(\d{3})(\d{4})(\d{1})/, '$1 $2 $3 $4');
  }

  get productTypes(): string[] {
    let values = this.apiAccountList.map((account) => {
      return account.productType;
    });
    values = uniqueValues(values);
    // Put electric before gas
    // (If we wind up with other products here at some point, we'd need to adjust this)
    values = values.sort();
    return uniqueValues(values);
  }

  get rateCategories(): string[] {
    let values = this.apiAccountList.map((account) => {
      return account.rateCategory;
    });
    values = uniqueValues(values);
    values = values.sort();
    return uniqueValues(values);
  }

  get defaultProductType(): string {
    return this.productTypes[0] || '';
  }

  public filterService(productType: string): ApiAccount[] {
    return this.apiAccountList.filter((account) => {
      return account.productType === productType;
    });
  }

  public meterCount(productType: string): number {
    // Filter by product type
    let filtered = this.filterService(productType);

    // Only count AMI meters
    filtered = filtered.filter((account) => {
      return account.amiMeter;
    });

    // TODO: should we also limit by date range selected?

    return filtered.length;
  }

  public hasGasService(ami?: boolean): boolean {
    let filtered = this.filterService('gas');

    filtered = filtered.filter((account) => {
      return !ami || account.amiMeter;
    });

    return filtered.length > 0;
  }

  public hasElectricService(ami?: boolean): boolean {
    let filtered = this.filterService('electric');

    filtered = filtered.filter((account) => {
      return !ami || account.amiMeter;
    });

    return filtered.length > 0;
  }

  public hasComboService(ami?: boolean): boolean {
    return this.hasElectricService(ami) && this.hasGasService(ami);
  }

  public hasService(ami?: boolean): boolean {
    return this.hasElectricService(ami) || this.hasGasService(ami);
  }

  get customerName(): string | null {
    return this.apiAccountList[0]?.customerName || null;
  }

  get canManageSharedLink(): boolean {
    return this.apiAccountList[0]?.canManageSharedLink === true;
  }
}
