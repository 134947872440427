import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { SmartRadioGroup } from '../SmartRadioGroup/SmartRadioGroup';

const choices = [
  {
    label: <span>Yearly</span>,
    value: 'all',
  },
  {
    label: <span>Non-Summer</span>,
    value: 'nonsummer',
  },
  {
    label: <span>Summer</span>,
    value: 'summer',
  },
];

interface Props {
  selectedSeason: string;
  setSeason: (mode: string) => void;
  seasons: string[];
  children?: React.ReactNode;
}

export const SeasonOptions: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <Col sm={{ span: 12 }} md={{ span: 'auto' }}>
        <Form.Group as="fieldset">
          <legend className="form-label">View</legend>
          <SmartRadioGroup
            name="mode"
            inline={true}
            defaultChoice={props.selectedSeason}
            choices={choices}
            onChoiceChange={props.setSeason}
            data-track="usage"
            data-track-detail="usage peaktimesavings"
            data-track-action="click"
            data-track-sub-action="change season"
          />
        </Form.Group>
      </Col>
    </React.Fragment>
  );
};
