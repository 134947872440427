import React from 'react';
import { Spinner } from '../../components/Spinner/Spinner';
import { useDateRangeValues } from '../../hooks/parameters/useDateRangeValues';
import { useIntervalValue } from '../../hooks/parameters/useIntervalValue';
import { DateContext, DateContextType as DateContextType, DateRange } from './DateContext';
import { useDatesAvailableResults } from './useDatesAvailableAPI';

interface Props {
  children?: React.ReactNode;
}

export const DateContextWrapper: React.FC<Props> = (props: Props) => {
  const [minDate, maxDate, loading] = useDatesAvailableResults();
  const limits: DateRange = {
    start: minDate,
    end: maxDate,
  };
  const [interval, setInterval] = useIntervalValue();
  const [dateRange, setDateRange] = useDateRangeValues(limits);

  const context: DateContextType = {
    dateLimit: limits,
    dateRange: dateRange,
    setDateRange: setDateRange,
    interval: interval,
    setInterval: setInterval,
  };

  // Assume we're still loading as long as we have min / max
  if (loading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <DateContext.Provider value={context}>{props.children}</DateContext.Provider>
    </React.Fragment>
  );
};
