export const apiPaths: Map<string, string> = new Map<string, string>([]);

export const webPaths: Map<string, string> = new Map<string, string>([
  ['home', '/wps/wcm/connect/dte-web/home'],
  ['back', '/wps/wcm/connect/dte-web/home/billing-and-payments/residential/billing/current-bill'],
]);

if (process.env.REAC_APP_ENV !== 'prod') {
  console.log('environment: ', process.env.REACT_APP_ENV);
}

interface Environment {
  prod?: boolean;
  hostname: string;
  referrer?: string;
  commerceLink: string;
  commerceNonCommodityLink: string;
  webLink: string;
  webApiBase: string;
  sharedLink: string;
  api: string;
}

enum EnviornmentNames {
  Production = 'production',
  Test = 'test',
  Preview = 'preview',
  N1QA = 'n1qa',
  Local = 'local',
}

const environments: Record<string, Environment> = {};
environments[EnviornmentNames.Production] = {
  prod: true,
  hostname: 'https://usage.dteenergy.com',
  webLink: 'https://newlook.dteenergy.com',
  webApiBase: 'https://newlook.dteenergy.com',
  sharedLink: 'https://usagedata.dteenergy.com',
  commerceLink: 'https://advantage.dteenergy.com',
  commerceNonCommodityLink: 'https://solutions.dteenergy.com',
  api: 'https://api.customer.sites.dteenergy.com/public/usage',
};

environments[EnviornmentNames.Test] = {
  hostname: 'https://usage-test.dteenergy.com',
  referrer: 'https://web-test.dteenergy.com',
  webLink: 'https://web-test.dteenergy.com',
  webApiBase: 'https://web-test.dteenergy.com',
  sharedLink: 'https://usagedata-test.dteenergy.com',
  commerceLink: 'https://advantage-qa.dteenergy.com',
  commerceNonCommodityLink: 'https://solutions-qa.dteenergy.com',
  api: 'https://test.api.customer.sites.dteenergy.com/public/qa/usage',
};

environments[EnviornmentNames.Preview] = {
  hostname: 'https://usage-test.dteenergy.com',
  referrer: 'https://web-prv.dteenergy.com',
  webLink: 'https://web-prv.dteenergy.com',
  webApiBase: 'https://web-prv.dteenergy.com',
  sharedLink: 'https://usagedata-test.dteenergy.com',
  commerceLink: 'https://advantage-qa.dteenergy.com',
  commerceNonCommodityLink: 'https://solutions-qa.dteenergy.com',
  api: 'https://test.api.customer.sites.dteenergy.com/public/qa/usage',
};

environments[EnviornmentNames.N1QA] = {
  hostname: 'https://usage-qan1.dteenergy.com',
  referrer: 'https://web-testn1.dteenergy.com',
  webLink: 'https://web-testn1.dteenergy.com',
  webApiBase: 'https://web-testn1.dteenergy.com',
  sharedLink: 'https://usagedata-test.dteenergy.com',
  commerceLink: 'https://advantage-qa.dteenergy.com',
  commerceNonCommodityLink: 'https://solutions-qa.dteenergy.com',
  api: 'https://test.api.customer.sites.dteenergy.com/public/qa/usage',
};

environments[EnviornmentNames.N1QA] = {
  hostname: 'https://usage-qan1.dteenergy.com',
  referrer: 'https://web-test.dteenergy.com',
  webLink: 'https://web-testn1.dteenergy.com',
  webApiBase: 'https://web-testn1.dteenergy.com',
  sharedLink: 'https://usagedata-test.dteenergy.com',
  commerceLink: 'https://advantage-qa.dteenergy.com',
  commerceNonCommodityLink: 'https://solutions-qa.dteenergy.com',
  api: 'https://test.api.customer.sites.dteenergy.com/public/qa/usage',
};

environments[EnviornmentNames.Local] = {
  hostname: 'https://local.usage.dteenergy.com',
  webLink: 'https://web-test.dteenergy.com',
  webApiBase: 'https://web-test.dteenergy.com',
  sharedLink: 'https://usagedata-test.dteenergy.com',
  commerceLink: 'https://advantage-qa.dteenergy.com',
  commerceNonCommodityLink: 'https://solutions-qa.dteenergy.com',
  api: 'https://local.usage.dteenergy.com:8443/api/usage',
};

const hostname = window && window.location && window.location.hostname;

function getEnvironment() {
  let match = environments[EnviornmentNames.Production];

  for (const environmentName in environments) {
    const environment = environments[environmentName];

    const envUrl = new URL(environment.hostname);

    // Make sure we match on hostname
    if (hostname !== envUrl.hostname) {
      continue;
    }

    // Next match on referrer (for environments where there are more than 1 referrer)
    if (environment.referrer) {
      const referrer = getGoodReferrer();

      if (environment.referrer === referrer) {
        return environment;
      }
    }

    match = environment;
  }

  return match;
}

function getGoodReferrer(): string {
  let referrer = document.referrer || sessionStorage.getItem('referrer') || '';

  // Strip trailing slash (if we have one in the referrer)
  referrer = referrer.replace(/\/$/, '');

  // Updated referrer to the last good page we came from
  sessionStorage.setItem('referrer', document.referrer);

  return referrer;
}

class Config {
  private hostname: string = window && window.location && window.location.hostname;

  private environment: Environment;

  // Default to prod values
  constructor() {
    this.environment = getEnvironment();
  }

  get sharedLinkBase(): string {
    return this.environment.sharedLink;
  }

  get apiSubscriptionKey(): string {
    if (this.environment.prod) {
      return 'f3388b16c3e04a06a48c28c75520f416';
    }

    return window.localStorage.getItem('subscriptionKey') || '';
  }

  get isProd(): boolean {
    return this.environment.prod === true;
  }

  get webBase(): string {
    return this.environment.webLink;
  }

  get apiBase(): string {
    return this.environment.api;
  }

  get dteHomepage(): string {
    return `${this.environment.webLink}/wps/wcm/connect/dte-web/home`;
  }

  get dteApi(): string {
    return `${this.environment.webApiBase}/api`;
  }

  get referringPage(): string {
    // TODO: may need better logic here to try to return to a specific page if we came from somewhere other than current bill
    return `${this.environment.webLink}/wps/wcm/connect/dte-web/home/billing-and-payments/residential/billing/current-bill`;
  }

  get commerceBase(): string {
    return this.environment.commerceLink;
  }

  get commerceNonCommodityBase(): string {
    return this.environment.commerceNonCommodityLink;
  }
}

export const config = new Config();
