import React from 'react';
import { Row } from 'react-bootstrap';
import './GraphLegend.scss';
import { createUUID, jsonEqual } from '../../util/utils';
import { UsageGraphHelper } from '../TimeOfUseData/TimeOfUseDataHelper';
import { TemperatureGraphHelper } from '../TemperatureData/TemperatureDataHelper';
import { patternStripeBackward, patternStripeForward } from '../Patterns';

const missingDataLegend = (() => {
  const UUID = createUUID();

  // TODO: need to get SVG mask working for legend icon here
  return (
    <dl key="noData">
      <dt className="noData" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>{patternStripeBackward(UUID, undefined, 9.5)}</defs>
          <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" fill={'url(#pattern-stripe-backward-' + UUID + ')'} />
        </svg>
      </dt>
      <dd>
        No Data <sup>*</sup>
      </dd>
    </dl>
  );
})();

const standardLegend = (
  <dl key="standard">
    <dt className="standard" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" />
      </svg>
    </dt>
    <dd>
      <span className="sr-only">Other Rates</span>
      <span aria-hidden="true">
        <span className="d-none d-md-inline">Other Rates</span>
        <span className="d-md-none">Other</span>
      </span>{' '}
      <sup>1</sup>
    </dd>
  </dl>
);

const electricLegend = (
  <dl key="standard">
    <dt className="standard" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" />
      </svg>
    </dt>
    <dd>
      <span className="sr-only">Electric Usage</span>
      <span aria-hidden="true">
        <span className="d-none d-md-inline">Electric Usage</span>
        <span className="d-md-none">Usage</span>
      </span>
    </dd>
  </dl>
);

const tempLegend = (
  <dl key="temperature">
    <dt className="temperature" aria-hidden="true">
      <svg width="100%" height="100%" viewBox="0 0 44 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1.31134e-07" y1="9" x2="44" y2="9" stroke="#1E3575" strokeWidth="3" />
        <path
          d="M10.2679 15.5C11.0377 16.8333 12.9622 16.8333 13.7321 15.5L18.9282 6.5C19.698 5.16667 18.7358 3.5 17.1962 3.5L6.80385 3.5C5.26425 3.5 4.302 5.16666 5.0718 6.5L10.2679 15.5Z"
          fill="#1E3575"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M33.7321 3.5C32.9623 2.16667 31.0378 2.16666 30.2679 3.5L25.0718 12.5C24.302 13.8333 25.2642 15.5 26.8038 15.5H37.1962C38.7358 15.5 39.698 13.8333 38.9282 12.5L33.7321 3.5Z"
          fill="#E31937"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </dt>
    <dd>
      <span className="sr-only">Temperature, Average in Degrees Fahrenheit</span>
      <span aria-hidden="true">
        <span className="d-none d-md-inline">Temperature</span>
        <span className="d-md-none">Temp.</span>
      </span>
    </dd>
  </dl>
);

const peakLegend = (() => {
  const UUID = createUUID();

  return (
    <dl key="peak">
      <dt className="peak" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
          <defs>{patternStripeForward(UUID, undefined, 9)}</defs>
          <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" fill={'url(#pattern-stripe-forward-' + UUID + ')'} />
        </svg>
      </dt>
      <dd>Peak</dd>
    </dl>
  );
})();

const offpeakLegend = (
  <dl key="offpeak">
    <dt className="offpeak" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" />
      </svg>
    </dt>
    <dd>Off-Peak</dd>
  </dl>
);

const superOffpeakLegend = (
  <dl key="superOffpeak">
    <dt className="superOffpeak" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" />
      </svg>
    </dt>
    <dd>Super Off-Peak</dd>
  </dl>
);

function showMissingData(usage: UsageGraphHelper | undefined): boolean {
  const hasMissingData = usage?.hasMissingData || false;
  const hasPartialData = usage?.hasPartialData || false;
  return hasMissingData || hasPartialData;
}

interface Props {
  usage: UsageGraphHelper;
  temperatures: TemperatureGraphHelper;
  children?: React.ReactNode;
}

const _GraphLegend: React.FunctionComponent<Props> = (props: Props) => {
  const usage = props.usage;
  const temperatures = props.temperatures;

  const elements = [];

  if (temperatures.hasData) {
    elements.push(tempLegend);
  }

  if (usage.hasTimeOfUseData) {
    elements.push(superOffpeakLegend);
    elements.push(offpeakLegend);
    elements.push(peakLegend);
    if (usage.standardUsage.length) {
      elements.push(standardLegend);
    }
  } else {
    elements.push(electricLegend);
  }

  // Show legend for missing or partial data
  if (showMissingData(usage)) {
    elements.push(missingDataLegend);
  }

  return <Row className="legend d-flex justify-content-end">{elements}</Row>;
};
export const GraphLegend = React.memo(_GraphLegend, jsonEqual);
