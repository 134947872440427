import { useValidatedStringQueryParam } from './useValidatedStringQueryParam';

function isValidMeterNumber(value: string): boolean {
  const normalized = value?.trim();

  if (!normalized?.length) {
    return false;
  }

  return true;
}

export function useMeterNumber(): [string, (string) => void] {
  const [meterNumber, setMeterNumber] = useValidatedStringQueryParam('meterNumber', isValidMeterNumber);
  return [meterNumber, setMeterNumber];
}
