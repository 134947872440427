import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { max, isAfter, subDays, addDays, differenceInDays } from 'date-fns';
import { Row, Col, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { min } from 'date-fns/esm';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { DateRange } from '../../contexts/DateContext/DateContext';

interface Props {
  dayLimit: number;
  children?: React.ReactNode;
}

function getOrderedDates(dateA: Date, dateB: Date): [Date, Date] {
  // Dates are swapped
  if (dateA && dateB && isAfter(dateA, dateB)) {
    return [dateB, dateA];
  }

  return [dateA, dateB];
}

export const SelectDateRange: React.FunctionComponent<Props> = (props: Props) => {
  const [dateLimitMin, dateLimitMax] = useDateLimits();
  const [dateRange, setDateRange] = useSelectedDateRange();

  // On load set the values
  useEffect(() => {
    if (!dateLimitMax) {
      return;
    }

    const newEnd = dateRange?.end || dateLimitMax;
    let newStart = dateRange?.start;

    // Avoid having just a single day selected
    if (!newStart || differenceInDays(newStart, newEnd) === 0) {
      newStart = subDays(newEnd, 35);
    }

    newStart = max([newStart, subDays(newEnd, props.dayLimit)]);

    const newRange = {
      start: newStart,
      end: newEnd,
    };

    setDateRange(newRange);
  }, [dateLimitMax, dateRange, setDateRange, props.dayLimit]);

  const setStartDate = (newStartValue: Date): void => {
    let [newStart, newEnd] = getOrderedDates(newStartValue, dateRange?.end);

    // Don't allow selecting more than the limit
    newEnd = min([newEnd, addDays(newStart, props.dayLimit)]);

    const newRange: DateRange = {
      start: newStart,
      end: newEnd,
    };
    setDateRange(newRange);
  };

  const setEndDate = (newEndValue: Date): void => {
    let [newStart, newEnd] = getOrderedDates(dateRange?.start, newEndValue);

    // Don't allow selecting more than the limit
    newStart = max([newStart, subDays(newEnd, props.dayLimit)]);

    const newRange: DateRange = {
      start: newStart,
      end: newEnd,
    };
    setDateRange(newRange);
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={{ span: 'auto' }} xs={{ span: 12 }}>
          <Form.Group>
            <div>
              <Form.Label htmlFor="startDate">Start Date</Form.Label>
            </div>
            <DatePicker
              id="startDate"
              selected={dateRange?.start}
              minDate={dateLimitMin}
              maxDate={dateLimitMax}
              onChange={setStartDate}
              popperPlacement="bottom-start"
            />
          </Form.Group>
        </Col>
        <Col sm={{}} xs={{ span: 12 }}>
          <Form.Group>
            <div>
              <Form.Label htmlFor="endDate">End Date</Form.Label>
            </div>
            <DatePicker
              id="endDate"
              selected={dateRange?.end}
              minDate={dateLimitMin}
              maxDate={dateLimitMax}
              onChange={setEndDate}
              popperPlacement="bottom-start"
            />
          </Form.Group>
        </Col>
      </Row>
      {props.children}
    </React.Fragment>
  );
};
