import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useMode } from '../../hooks/parameters/useMode';
import { totalCost, totalUsage } from '../TimeOfUseSummary/UsageSummary';
import { UsageData } from '../UsageData/UsageData';
import { UsageGraphHelper } from '../UsageData/UsageDataHelper';
import './UsageSummary.scss';

// TODO: ensure that totals add up correctly after rounding
const formatDigits = (digits: number): Intl.NumberFormat => {
  let rounded = digits;

  if (digits === 0) {
    rounded = Math.round(digits);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: rounded,
    minimumFractionDigits: rounded,
    useGrouping: true,
  });
};

const usageUnitElectric = (
  <React.Fragment>
    <span className="sr-only">kilowatt hours</span>
    <span aria-hidden="true">kWh</span>
  </React.Fragment>
);

const usageUnitGas = (
  <React.Fragment>
    <span className="sr-only">hundred cubic feet</span>
    <span aria-hidden="true">CCF</span>
  </React.Fragment>
);

interface Props {
  product: string;
  mode: string;
  usage: UsageGraphHelper;
}

function formatSummaryBlock(
  mode: string,
  total: number,
  label: string,
  className: string,
  digits: number,
  product: string
): JSX.Element {
  let value = formatDigits(digits).format(total);

  let modeDescription;
  let units = null;

  if (mode === 'usage') {
    modeDescription = 'Used';
    if (product === 'gas') {
      units = <span className="units">{usageUnitGas}</span>;
    } else {
      units = <span className="units">{usageUnitElectric}</span>;
    }
  } else {
    value = '$' + value;
    modeDescription = 'Cost';
  }

  const name = 'icon ' + className;

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }} className={name}>
        <span className="value">{value}</span> {units}
        <br />
        <span className="sr-only">
          {label} {modeDescription}
        </span>
        <span aria-hidden="true">
          {label}
          <span className="d-none d-sm-inline"> {modeDescription}</span>
        </span>
      </Col>
    </React.Fragment>
  );
}

function summaryTotal(mode: string, values: SummaryValues, product: string): JSX.Element {
  if (values.total === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      {formatSummaryBlock(mode, values.total || 0, 'Total', 'total', values.digits, product)}
    </React.Fragment>
  );
}

function totalValue(mode: string, data: UsageData[]): number {
  if (mode === 'usage') {
    return totalUsage(data);
  }
  return totalCost(data);
}

// Needed to ensure that everything adds up properly
function displayValue(value: number | undefined, digits: number): number {
  const numberString = (value || 0).toFixed(digits);

  return Number.parseFloat(numberString);
}

function largeZeroOrUndefined(value: number | undefined): boolean {
  if (!value) {
    return true;
  }

  return value > 100;
}

function costDigits(values: SummaryValues): number {
  // Round if the total cost is more than $1,000
  if (values.total && values.total > 1000) {
    return 0;
  }

  // Lowest cost is > $100
  if (
    largeZeroOrUndefined(values.offpeak) &&
    largeZeroOrUndefined(values.peak) &&
    largeZeroOrUndefined(values.standard)
  ) {
    return 0;
  }

  return 2;
}

interface SummaryValues {
  offpeak?: number;
  peak?: number;
  standard?: number;
  total?: number;
  digits: number;
}
function summaryValues(mode: string, usage: UsageGraphHelper): SummaryValues {
  const values: SummaryValues = {
    digits: 4,
  };

  let total = 0;

  if (usage.data.length) {
    values.standard = totalValue(mode, usage.data);
    total += values.standard;
  }

  values.total = total;

  return values;
}

function displayDigits(mode: string, values: SummaryValues): number {
  let digits = 0;
  if (mode === 'cost') {
    digits = costDigits(values);
  }
  return digits;
}

function hasStandardData(values: SummaryValues): boolean {
  return values.standard !== undefined;
}

function displayValues(mode: string, usage: UsageGraphHelper): SummaryValues {
  const values = summaryValues(mode, usage);

  const digits = displayDigits(mode, values);
  const display: SummaryValues = { digits: digits };

  let total = 0;

  if (hasStandardData(values)) {
    display.standard = displayValue(values.standard, digits);
    total += display.standard;
  }
  display.total = total;

  return display;
}

export const UsageSummary: React.FunctionComponent<Props> = (props: Props) => {
  const usage = props.usage;
  const [mode] = useMode();

  if (!usage) {
    return null;
  }

  const product = props.product;

  const values = displayValues(mode, usage);

  const breakpoint = '';
  let className = '';
  if (product === 'gas') {
    className += 'gas';
  } else {
    className += 'electric';
  }
  className += ' justify-content' + breakpoint + '-around';
  className += ' break' + breakpoint;

  let wrapperClass = 'usageSummary';
  wrapperClass += ' justify-content-center';

  return (
    <div className={wrapperClass}>
      <Row className={className}>{summaryTotal(mode, values, product)}</Row>
    </div>
  );
};
