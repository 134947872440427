import { config } from '../../util/config';
import { getDefaultRequestConfig, jsonDelete, jsonPost, jsonGet } from '../../util/AuthManager';
import { SharedLink, ApiSharedLinkResponse } from './sharedLink';
import { AxiosResponse } from 'axios';

function getUrl(accountNumber: string): string {
  return `${config.apiBase}/authenticated/accounts/${accountNumber}/usage/sharedLink`;
}

export function callSharedLinkApi(
  token: string,
  accountNumber: string,
  callback: (sharedLink: SharedLink) => void,
  operation: 'get' | 'post' | 'delete'
): void {
  const url = getUrl(accountNumber);

  const requestConfig = getDefaultRequestConfig(token);

  // TODO: make use of cancel token
  let request: Promise<AxiosResponse<ApiSharedLinkResponse>>;
  switch (operation) {
    case 'delete':
      request = jsonDelete<ApiSharedLinkResponse>(url, requestConfig);
      break;
    case 'post':
      request = jsonPost<ApiSharedLinkResponse>(url, requestConfig);
      break;
    default:
      request = jsonGet<ApiSharedLinkResponse>(url, requestConfig, false);
      break;
  }

  void request.then((response) => {
    const sharedLink = new SharedLink(response?.data);
    callback(sharedLink);
  });
}
