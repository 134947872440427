import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { formatEST } from '../../common/dates/dates';
import { formatISO } from '../../util/dates';
import { useNewLink } from '../../hooks/parameters/useNewLink';
import { endOfMonth, startOfMonth } from 'date-fns';

function getLongMonth(date: Date): string {
  const longMonth = formatEST(date, 'MMMM');
  return longMonth;
}

function getShortMonth(date: Date): string {
  const longMonth = getLongMonth(date);
  let shortMonth = formatEST(date, 'MMM');
  const monthName = formatEST(date, 'MMMM');
  if (monthName.length < 5) {
    shortMonth = longMonth;
  }
  return shortMonth;
}

const TimeLabelMonth: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const midpoint = props.midpoint;
  const midpoints = props.midpoints;

  const date = new Date(midpoint);

  const shortMonth = getShortMonth(date);
  const longMonth = getLongMonth(date);

  const newParams = { interval: 'day', startDate: formatISO(startOfMonth(date)), endDate: formatISO(endOfMonth(date)) };
  const newLink = useNewLink(undefined, newParams);

  let yearDetail = null;
  if (firstOfYear(midpoint, midpoints)) {
    yearDetail = (
      <React.Fragment>
        <br />
        <span className="note">{formatEST(date, 'yyyy')}</span>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Link to={newLink}>
        <span className="sr-only">{longMonth}</span>
        <span aria-hidden="true">
          <span className="d-none d-md-inline">{longMonth}</span>
          <span className="d-md-none">{shortMonth}</span>
        </span>
      </Link>
      {yearDetail}
    </React.Fragment>
  );
};

const TimeLabelDay: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const midpoint = props.midpoint;

  const date = new Date(midpoint);

  const newParams = { interval: 'hour', startDate: formatISO(date), endDate: formatISO(date) };
  const newLink = useNewLink(undefined, newParams);

  const fullDay = formatEST(date, 'EEEE, MMMM d');

  const shortDayOfWeek = formatEST(date, 'EEE');

  const shortMonth = getShortMonth(date);
  const day = formatEST(date, 'd');

  return (
    <React.Fragment>
      <Link to={newLink}>
        <span className="sr-only">{fullDay}</span>
        <span aria-hidden="true">
          {shortDayOfWeek} {shortMonth} {day}
        </span>
      </Link>
    </React.Fragment>
  );
};

const TimeLabelHour: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const midpoint = props.midpoint;
  const midpoints = props.midpoints;

  let hourOfDay = midpoints.indexOf(midpoint);
  if (hourOfDay === -1) {
    return <React.Fragment>&nbsp;</React.Fragment>;
  }

  const suffix = hourOfDay >= 12 ? ' p.m.' : ' a.m.';
  if (hourOfDay > 12) {
    hourOfDay = hourOfDay - 12;
  } else if (hourOfDay === 0) {
    hourOfDay = 12;
  }

  return (
    <React.Fragment>
      {hourOfDay} {suffix}
    </React.Fragment>
  );
};

// Determine if a start date is the first date of its year
function firstOfYear(midpoint: number, midpoints: number[]): boolean {
  const datumDate = new Date(midpoint);
  const datumYear = datumDate.getFullYear();

  const years = midpoints.map((timestamp: number) => {
    const tempDate = new Date(timestamp);
    return tempDate.getFullYear();
  });

  return midpoints.indexOf(midpoint) === years.indexOf(datumYear);
}

const RenderTimeLabel: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const interval = props.interval;
  const midpoint = props.midpoint;
  const midpoints = props.midpoints;

  if (interval === 'month') {
    return <TimeLabelMonth interval={props.interval} midpoint={props.midpoint} midpoints={props.midpoints} />;
  }

  if (interval === 'hour') {
    return <TimeLabelHour interval={props.interval} midpoint={props.midpoint} midpoints={props.midpoints} />;
  }

  return <TimeLabelDay interval={props.interval} midpoint={props.midpoint} midpoints={props.midpoints} />;
};

interface Props {
  interval: string;
  midpoint: number;
  midpoints: number[];
}
export const DateCell: FunctionComponent<Props> = (props: Props): JSX.Element => {
  return (
    <th scope="row" className="time">
      <RenderTimeLabel interval={props.interval} midpoint={props.midpoint} midpoints={props.midpoints} />
    </th>
  );
};
