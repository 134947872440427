import { getDefaultRequestConfig, blobRequest } from '../../util/AuthManager';
import { config } from '../../util/config';
import { formatISO } from '../../util/dates';
import { Account } from '../AccountList/account';

function getUrl(account: Account | undefined | null, productType: string): string | null {
  if (!account) {
    return null;
  }

  const accountNumber = account.accountNumber;

  const validProducts = ['gas', 'electric'];
  if (!validProducts.includes(productType)) {
    return null;
  }

  return `${config.apiBase}/authenticated/accounts/${accountNumber}/usage/report/${productType}`;
}

export function downloadReport(
  token: string,
  account: Account | undefined | null,
  productType: string,
  startDate: Date,
  endDate: Date,
  fileType: string,
  callback: (data: Blob | string | null) => void
): void {
  const url = getUrl(account, productType);
  if (!url) {
    return;
  }

  const meterCount = account?.meterCount(productType) || 0;

  const requestConfig = getDefaultRequestConfig(token);
  requestConfig.params = {
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
    byMeter: meterCount <= 5,
  };

  if (!requestConfig.headers) {
    requestConfig.headers = {};
  }

  if (fileType === 'xml') {
    requestConfig.headers['Accept'] = 'text/xml';
  } else {
    requestConfig.headers['Accept'] = 'text/csv';
  }

  // TODO: make use of cancel token
  const request = blobRequest(url, requestConfig);
  request.catch(() => {
    callback(null);
  });
  void request.then((response) => {
    callback(response ? response.data || '' : '');
  });
}
