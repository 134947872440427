import { useQueryParam } from 'use-query-params';

function getValidValue(value: string, acceptableValues: string[], defaultValue?: string): string {
  let returnValue = value;
  returnValue = value?.trim();

  if (['undefined', 'null'].includes(returnValue)) {
    returnValue = undefined;
  }

  // Default to custom for dashboards
  if (acceptableValues.includes(returnValue)) {
    return returnValue;
  }

  return defaultValue;
}

function getValidString(value: string, isValid: (string) => boolean, defaultValue?: string): string {
  let normalized = value;
  normalized = value?.trim();

  if (['undefined', 'null'].includes(normalized)) {
    normalized = undefined;
  }

  if (!isValid(normalized)) {
    return defaultValue;
  }

  return normalized;
}

function getValidParam(isValid: (string) => boolean, defaultValue?: string) {
  return {
    encode(value: string): string {
      const encoded = getValidString(value, isValid, defaultValue);

      // Don't save default values
      if (encoded === defaultValue) {
        return undefined;
      }

      if (['undefined', 'null'].includes(encoded)) {
        return undefined;
      }

      return encoded;
    },

    decode(value: string): string {
      return getValidString(value, isValid, defaultValue);
    },
  };
}

export function useValidatedStringQueryParam(
  name: string,
  isValid: (string) => boolean,
  defaultValue?: string
): [string, (string) => void] {
  const [value, setValue] = useQueryParam(name, getValidParam(isValid, defaultValue));

  // const updateValue = (newValue: string) => {
  //   newValue = getValidValue(newValue, validValues, defaultValue);
  //   setValue(newValue);
  // };

  return [value, setValue];
}
