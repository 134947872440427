import React from 'react';
import { GraphValues } from './GraphRecord';
import { path } from 'd3-path';
import { sum } from 'd3-array';
import { createUUID, jsonEqual } from '../../util/utils';

function barPath(
  total: number,
  above: number,
  below: number,
  height: number,
  width: number,
  x: number,
  y: number
): [number, number, string] {
  const strokeWidth = 1.5;
  const radius = Math.max(width * 0.15, strokeWidth * 2);

  const barY = height * (above / total);
  const barHeight = height - barY - (below / total) * height;

  const bottom = y + barY + barHeight - strokeWidth;
  const top = y + barY + strokeWidth;
  const left = x + strokeWidth;
  const right = x + width - strokeWidth;

  const barPath = path();
  barPath.moveTo(left, bottom);

  if (barHeight < radius || above) {
    barPath.lineTo(left, top);
    barPath.lineTo(right, top);
    barPath.lineTo(right, bottom);
  } else {
    barPath.lineTo(left, top + radius);
    barPath.quadraticCurveTo(left, top, left + radius, top);
    barPath.lineTo(right - radius, top);
    barPath.quadraticCurveTo(right, top, right, top + radius);
    barPath.lineTo(right, bottom);
  }
  barPath.closePath();

  return [barHeight, barY, barPath.toString()];
}

function createBar(
  value: number | null | undefined,
  estimateValue: number | null | undefined,
  valueClass: string,
  valuePattern: string | undefined,
  total: number,
  above: number,
  below: number,
  height: number,
  width: number,
  x: number,
  y: number
): JSX.Element | null {
  const totalOfType = sum([value, estimateValue]);

  const [barHeight, barY, barPathString] = barPath(total, above, below, height, width, x, y);

  const clipUUID = createUUID();

  let valueFill = undefined;
  if (valuePattern) {
    valueFill = 'url(#' + valuePattern + ')';
  }

  const clipHeight = barHeight * ((estimateValue || 0) / totalOfType);

  let actualBar = null;
  if (value) {
    actualBar = <path className={valueClass + ' data'} d={barPathString} fill={valueFill} />;
  }

  let estimateBar = null;
  if (estimateValue) {
    if (value) {
      // Both an actual an estimate, include clipping
      estimateBar = (
        <React.Fragment>
          <defs>
            <clipPath id={'clip-estimate-bar-' + clipUUID}>
              <rect x={x} y={y + barY} width={width} height={clipHeight} />
            </clipPath>
          </defs>
          <path
            className={valueClass + ' noData'}
            d={barPathString}
            fill={'url(#pattern-stripe-backward-' + valueClass + ')'}
            clipPath={'url(#clip-estimate-bar-' + clipUUID + ')'}
          />
        </React.Fragment>
      );
    } else {
      // Estimate only
      estimateBar = (
        <path
          className={valueClass + ' noData'}
          d={barPathString}
          fill={'url(#pattern-stripe-backward-' + valueClass + ')'}
        />
      );
    }
  }

  return (
    <React.Fragment>
      {actualBar}
      {estimateBar}
    </React.Fragment>
  );
}

function standardBar(values: GraphValues, height: number, width: number, x: number, y: number): JSX.Element | null {
  if (!values.total || !values.getTotal(values.standard)) {
    return null;
  }

  const above = 0;
  const below = values.total - (values.getTotal(values.standard) || 0);

  return createBar(
    values.getValue(values.standard),
    values.getEstimate(values.standard),
    'standard',
    undefined,
    values.total,
    above,
    below,
    height,
    width,
    x,
    y
  );
}

function superOffpeakBar(values: GraphValues, height: number, width: number, x: number, y: number): JSX.Element | null {
  if (!values.total || !values.getTotal(values.superOffpeak)) {
    return null;
  }

  const above = values.getTotal(values.standard) || 0;
  const below = values.total - above - (values.getTotal(values.offpeak) || 0) - (values.getTotal(values.superOffpeak) || 0);

  return createBar(
      values.getValue(values.superOffpeak),
      values.getEstimate(values.superOffpeak),
      'superOffpeak',
      undefined,
      values.total,
      above,
      below,
      height,
      width,
      x,
      y
  );

}

function offpeakBar(values: GraphValues, height: number, width: number, x: number, y: number): JSX.Element | null {
  if (!values.total || !values.getTotal(values.offpeak)) {
    return null;
  }

  const above = (values.getTotal(values.standard) || 0) + (values.getTotal(values.superOffpeak) || 0);
  const below = values.total - above - (values.getTotal(values.offpeak) || 0);

  return createBar(
    values.getValue(values.offpeak),
    values.getEstimate(values.offpeak),
    'offpeak',
    undefined,
    values.total,
    above,
    below,
    height,
    width,
    x,
    y
  );
}

function peakBar(values: GraphValues, height: number, width: number, x: number, y: number): JSX.Element | null {
  if (!values.total || !values.getTotal(values.peak)) {
    return null;
  }

  const above = (values.getTotal(values.standard) || 0) + (values.getTotal(values.superOffpeak) || 0) + (values.getTotal(values.offpeak) || 0);
  const below = 0;

  return createBar(
    values.getValue(values.peak),
    values.getEstimate(values.peak),
    'peak',
    'pattern-stripe-forward-peak',
    values.total,
    above,
    below,
    height,
    width,
    x,
    y
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _TimeOfUseBar(props: any): JSX.Element | null {
  const values = props.values;
  if (!values.total) {
    return null;
  }

  return (
    <React.Fragment>
      {standardBar(values, props.height, props.width, props.x, props.y)}
      {superOffpeakBar(values, props.height, props.width, props.x, props.y)}
      {offpeakBar(values, props.height, props.width, props.x, props.y)}
      {peakBar(values, props.height, props.width, props.x, props.y)}
    </React.Fragment>
  );
}
export const TimeOfUseBar = React.memo(_TimeOfUseBar, jsonEqual);
