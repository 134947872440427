import React from 'react';
import { formatDigits } from '../../../util/utils';
import { YAxis } from 'recharts';
import { useProductType } from '../../../hooks/parameters/useProductType';
import { useMode } from '../../../hooks/parameters/useMode';

// Use unicode "minus" character so that it's vertically centered
// (- sign is not)
const minusSign = '\u2212';

const axisCutoffs = [
  0.15, 0.3, 0.45, 0.6, 0.9, 1.5, 3, 4.5, 6, 9, 15, 30, 45, 60, 90, 150, 300, 450, 600, 900, 1500, 3000, 4500, 6000,
  9000, 15000, 30000, 45000, 60000, 90000,
];

function usageMode(mode: string): boolean {
  return mode === 'usage';
}

function axisCutoff(value: number): number {
  for (const cutoff of axisCutoffs) {
    if (value <= cutoff) {
      return cutoff;
    }
  }

  return value;
}

function axisFormatter(mode: string, max: number): Intl.NumberFormat {
  const cutoff = axisCutoff(max);

  if ([0.15, 0.45].includes(cutoff)) {
    return formatDigits(2);
  }

  if ([0.3, 0.6, 0.9, 1.5, 4.5].includes(cutoff)) {
    if (usageMode(mode)) {
      return formatDigits(1);
    }
    return formatDigits(2);
  }

  return formatDigits(0);
}

function axisLabel(mode: string, value: number, max: number): string {
  let formatted = axisFormatter(mode, max).format(value);

  if (!usageMode(mode)) {
    formatted = '$' + formatted;
  }

  return formatted;
}

function getUsageTicks(max: number): number[] {
  const cutoff = axisCutoff(max);
  const incrementBasis = cutoff / 6;

  const returnArray = [];

  for (let i = 0; i < 7; i++) {
    returnArray.push(incrementBasis * i);
  }

  return returnArray;
}

export function leftMargin(mode: string, max: number): number {
  const ticks = getUsageTicks(max);
  let longestValue = '';
  if (ticks.length) {
    longestValue = axisLabel(mode, ticks[ticks.length - 1], max);
  }

  // Ensure we can handle at least a 4 character string
  // or the longest value
  const longest = Math.max(4, longestValue.length);
  return longest * 13;
}

// TODO: change formatting / unit designation if the usage is too big?
// i.e.: mWh instead of kWh
// TODO: could remove this if the types for react-vis get updated
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatUsageTick(mode: string, value: number, max: number): any {
  if (value > max) {
    return null;
  }

  const ticks = getUsageTicks(max);
  const index = ticks.indexOf(value);

  if (index % 2 === 1) {
    return <tspan className="minus">{minusSign}</tspan>;
  }

  return axisLabel(mode, value, max);
}

// How much space for the temperature line
const graphPercentTemperature = 20;
// How much space for the consumption bars
const graphPercentConsumption = 40;
// How much space above and below the temperature line
const graphPercentSpacer = 7.5;

// <YAxis orientation="left" tickFormat={this.formatUsageTicks} tickValues={getUsageTicks(max)} />

export function ConsumptionAxis(max: number): JSX.Element {
  const [productType] = useProductType();
  const [mode] = useMode();
  const ticks = getUsageTicks(max);
  const axisMax = axisCutoff(max);
  const newMax = axisMax + (axisMax / graphPercentConsumption) * (graphPercentTemperature + graphPercentSpacer * 2);

  const axisHeader = axisMax * 1.3;

  return (
    <YAxis
      className="consumptionAxis"
      domain={[0, newMax]}
      width={leftMargin(mode, max)}
      yAxisId="consumption"
      ticks={[...ticks, axisHeader]}
      tick={(record) => {
        let formatted;
        if (record.payload.value === axisHeader) {
          if (usageMode(mode)) {
            if (productType === 'gas') {
              formatted = 'CCF';
            } else {
              formatted = 'kWh';
            }
          } else {
            formatted = 'Cost';
          }
        } else {
          formatted = formatUsageTick(mode, record.payload.value, axisMax);
        }

        return (
          <text x={record.x} y={record.y + 4} className="yAxisLabel">
            {formatted}
          </text>
        );
      }}
      axisLine={false}
      tickLine={false}
      interval={0}
      minTickGap={0}
    />
  );
}
