import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { isBefore, addMonths, isSameMonth, format, startOfMonth, endOfMonth } from 'date-fns';
import { formatISO } from '../../util/dates';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { useDateLimits } from '../../contexts/DateContext/useDateLimits';
import { getDate } from '../../common/dates/dates';
import { firstDefinedValue } from '../../util/firstDefinedValue';
import { SelectDateRange } from './SelectDateRange';

function monthOption(date: Date, selectedDate: Date): JSX.Element {
  const isoDate = formatISO(date);
  let className = 'option';
  if (selectedDate && isSameMonth(date, selectedDate)) {
    className += ' selected';
  }

  return (
    <option key={isoDate} value={isoDate} className={className}>
      {format(date, 'MMMM yyyy')}
    </option>
  );
}

function getMonthsByYear(startDate: Date, endDate: Date, selectedDate: Date | undefined): Map<number, Date[]> {
  const end = endOfMonth(endDate);
  let current = startOfMonth(startDate);

  // Group dates by year
  const monthsByYear = new Map<number, Date[]>();
  while (isBefore(current, end)) {
    const year = current.getFullYear();
    const dates = monthsByYear.get(year) || [];

    if (selectedDate && isSameMonth(current, selectedDate)) {
      dates.push(selectedDate);
    } else {
      dates.push(current);
    }
    monthsByYear.set(year, dates);

    current = addMonths(current, 1);
  }

  return monthsByYear;
}

interface MonthOptionProps {
  startDate: Date;
  endDate: Date;
  selectedMonth: Date | undefined;
}
function _MonthOptions(props: MonthOptionProps): JSX.Element {
  const monthsByYear = getMonthsByYear(props.startDate, props.endDate, props.selectedMonth);

  const optionGroups = new Array<JSX.Element>();
  for (const [year, dates] of monthsByYear) {
    const options = new Array<JSX.Element>();
    for (const date of dates) {
      options.push(monthOption(date, props.selectedMonth));
    }
    options.reverse();

    optionGroups.push(
      <optgroup key={year} label={'' + year}>
        {options}
      </optgroup>
    );
  }
  optionGroups.reverse();

  return <React.Fragment>{optionGroups}</React.Fragment>;
}
const MonthOptions = React.memo(_MonthOptions);

export const MonthSelectionOld: React.FunctionComponent = () => {
  const [dateLimitMin, dateLimitMax] = useDateLimits();
  const [{ end: endDate }, setDateRange] = useSelectedDateRange();

  const [selectedDate, setSelectedDate] = useState<Date>(endDate);

  useEffect(() => {
    if (!dateLimitMax) {
      return;
    }

    const newDates = [selectedDate, endDate, dateLimitMax];
    const newDate = firstDefinedValue(newDates);
    const newRange = {
      start: startOfMonth(newDate),
      end: endOfMonth(newDate),
    };
    setDateRange(newRange);
  }, [dateLimitMax, endDate, selectedDate, setDateRange]);

  function changeMonth(event: ChangeEvent<HTMLSelectElement>): void {
    const newDate = getDate(event.currentTarget.value);
    setSelectedDate(newDate);
    event.stopPropagation();
  }

  return (
    <React.Fragment>
      <Col xs={{ span: 'auto' }}>
        <Form.Group as="fieldset">
          <Form.Label htmlFor="monthDate">Month</Form.Label>
          <Form.Control
            className="monthPicker"
            id="monthDate"
            as="select"
            value={endDate ? formatISO(endDate) : ''}
            onChange={changeMonth}
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
            data-track-detail="usage shiftandsave"
            data-track-action="click"
            data-track-sub-action="select month"
          >
            <MonthOptions startDate={dateLimitMin} endDate={dateLimitMax} selectedMonth={endDate} />
          </Form.Control>
        </Form.Group>
      </Col>
    </React.Fragment>
  );
};

interface Props {
  children?: React.ReactNode;
}

export const MonthSelection: React.FunctionComponent<Props> = (props: Props) => {
  const days = 35;
  return <SelectDateRange dayLimit={days}>{props.children}</SelectDateRange>;
};
