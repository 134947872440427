import { isBefore } from 'date-fns';
import { notNullOrUndefined } from '../../util/utils';
import { TemperatureRecord } from './TemperatureRecord';
import { TemperatureData } from './TemperatureData';
import { UrlDetails } from '../../util/AuthManager';

function data(interval: string, temperatures: TemperatureRecord[]): TemperatureData[] {
  const values = [];
  for (const record of temperatures || []) {
    const tempData = new TemperatureData(interval, record);
    const date = tempData.date;
    // TODO: figure out a good way to differentiate
    // Don't show forecast data
    if (date && isBefore(date, new Date())) {
      values.push(tempData);
    }
  }
  return values;
}

function hasData(records: TemperatureData[]): boolean {
  return records?.length > 0;
}

function lowestData(records: TemperatureData[]): TemperatureData | null {
  let value = null;

  for (const record of records) {
    if (!value || record.low < value.low) {
      value = record;
    }
  }

  return value;
}

function highestData(records: TemperatureData[]): TemperatureData | null {
  let value = null;

  for (const record of records) {
    if (!value || record.high > value.high) {
      value = record;
    }
  }

  return value;
}

function lows(records: TemperatureData[]): number[] {
  const values = records.map((record) => {
    return record.low;
  });
  return values.filter(notNullOrUndefined);
}

function highs(records: TemperatureData[]): number[] {
  const values = records.map((record) => {
    return record.high;
  });
  return values.filter(notNullOrUndefined);
}

export class TemperatureGraphHelper {
  readonly interval: string;
  readonly temperatures: TemperatureRecord[];
  readonly data: TemperatureData[];
  readonly hasData: boolean;
  readonly lowestData: TemperatureData | null;
  readonly highestData: TemperatureData | null;
  readonly lows: number[];
  readonly lowest: number;
  readonly highs: number[];
  readonly highest: number;
  readonly url: UrlDetails;

  constructor(url: UrlDetails, interval: string, temperatures: TemperatureRecord[]) {
    this.url = url;
    this.interval = interval;
    this.temperatures = temperatures;
    this.data = data(interval, temperatures);
    this.hasData = hasData(this.data);
    this.lowestData = lowestData(this.data);
    this.highestData = highestData(this.data);
    this.lows = lows(this.data);
    this.lowest = Math.min(...this.lows);
    this.highs = highs(this.data);
    this.highest = Math.max(...this.highs);
  }

  public dataByMidpoint(midpoint: number): TemperatureData | undefined {
    const filtered = this.data.filter((record) => {
      return record.midpoint === midpoint;
    });
    return filtered[0];
  }
}
