import React from 'react';
import { GraphRecord } from '../../TimeOfUseGraph/GraphRecord';

const hourLabels: Map<number, string | JSX.Element> = new Map<number, string | JSX.Element>([
  [0, '12am'],
  [6, '6'],
  [12, 'noon'],
  [18, '6'],
  [23, '11pm'],
]);

function isPeak(midpoint: number, graphData: GraphRecord[]): boolean {
  for (const record of graphData) {
    if (record.midpoint === midpoint) {
      return !!record.values.peak;
    }
  }

  return false;
}

function hasHourLabel(hour: number, graphData: GraphRecord[]): boolean {
  // Outside of the range
  if (hour < 0 || hour > 23) {
    return false;
  }

  if (hourLabels.get(hour) || isPeak(hour, graphData)) {
    return true;
  }

  return false;
}

function hourBlankLabel(hour: number, graphData: GraphRecord[], x: number, y: number): JSX.Element {
  // For dashes, determine when / if they need to be hidden
  let className = 'minus';
  const labelBefore = hasHourLabel(hour - 1, graphData);
  const labelAfter = hasHourLabel(hour + 1, graphData);
  // Special case for values near a string known to be long
  if ([2, 10, 14, 21].includes(hour)) {
    className += ' d-none d-md-inline';
  } else if (labelBefore || labelAfter) {
    className += ' d-none d-lg-inline';
  }

  return (
    <text x={x} y={y + 12} textAnchor="middle" orientation="bottom" className={className}>
      -
    </text>
  );
}

export function XAxisLabelHour(hour: number, graphData: GraphRecord[], x: number, y: number): JSX.Element | null {
  // Always display hour if peak
  if (isPeak(hour, graphData)) {
    const displayHour = hour > 12 ? hour - 12 : hour;
    return (
      <text x={x} y={y + 12} textAnchor="middle" orientation="bottom">
        {displayHour}
      </text>
    );
  }

  // Always display for specific hours
  const label = hourLabels.get(hour);
  if (label) {
    return (
      <text x={x} y={y + 12} textAnchor="middle" orientation="bottom">
        {label}
      </text>
    );
  }

  // Just a separator character
  return hourBlankLabel(hour, graphData, x, y);
}
