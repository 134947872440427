import React from 'react';
import { Container } from 'react-bootstrap';
import { GraphSelection } from './GraphSelection';
import { CustomerInformation } from '../../components/CustomerInformation/CustomerInformation';

import './PeakTimeSavings.scss';
import { useEventData } from '../../components/PeakTimeSavingsData/PeakTimeSavingsWrapper';

export const PeakTimeSavings: React.FunctionComponent = () => {
  const graphData = useEventData();

  return (
    <React.Fragment>
      <Container className="bodyContent">
        <CustomerInformation></CustomerInformation>
        <GraphSelection graphData={graphData} />
      </Container>
    </React.Fragment>
  );
};
