import { max, min } from 'date-fns';
import { useEffect, useState } from 'react';
import { getDate } from '../../common/dates/dates';
import { ApiResponse, DatesAvailableRecord } from '../../components/Dates/DatesAvailableRecord';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';
import { useProductType } from '../../hooks/parameters/useProductType';
import { UrlDetails, useAuthenticatedJsonGet } from '../../util/AuthManager';
import { config } from '../../util/config';

function earliestReadDate(records: DatesAvailableRecord[]): Date {
  if (!records?.length) {
    return undefined;
  }

  let dateValues = records.map((record) => {
    return getDate(record.earliestReadDate);
  });
  // Filter empty
  dateValues = dateValues.filter((dateValue) => dateValue);

  return min(dateValues);
}

function mostRecentReadDate(records: DatesAvailableRecord[]): Date {
  if (!records?.length) {
    return undefined;
  }

  let dateValues = records.map((record) => {
    return getDate(record.mostRecentReadDate);
  });
  // Filter empty
  dateValues = dateValues.filter((dateValue) => dateValue);

  return max(dateValues);
}

function useDatesAvailableURL(): UrlDetails {
  const [url, setURL] = useState<UrlDetails | null>(null);
  const [accountNumber] = useAccountNumber();
  const [productType] = useProductType();

  useEffect(() => {
    let newURL: UrlDetails | null = null;

    try {
      newURL = {
        url: `${config.apiBase}/authenticated/accounts/${accountNumber}/usage/datesAvailable/${productType}`,
      };
    } catch {
      // At least one parameter was invalid
    }

    setURL(newURL);
  }, [accountNumber, productType]);

  return url;
}

export const useDatesAvailableResults = (): [Date, Date, boolean] => {
  const url = useDatesAvailableURL();
  const [results, loading] = useAuthenticatedJsonGet<ApiResponse>(url);
  const [minLimit, setMinLimit] = useState<Date>();
  const [maxLimit, setMaxLimit] = useState<Date>();

  useEffect(() => {
    // Don't do anything until we have results
    if (loading) {
      return;
    }

    const newMin = earliestReadDate(results?.meters);
    const newMax = mostRecentReadDate(results?.meters);

    setMinLimit(newMin);
    setMaxLimit(newMax);
  }, [results, loading]);

  return [minLimit, maxLimit, loading];
};
