import React, { useEffect } from 'react';
import { Spinner } from '../../components/Spinner/Spinner';
import { getCustomerToken } from '../../util/AuthManager';
import { TokenContextWrapper } from './TokenContextWrapper';

interface Props {
  children?: React.ReactNode;
}

export const AuthenticatedCustomerWrapper: React.FunctionComponent<Props> = (props) => {
  const { children } = props;
  const [token, setToken] = React.useState('');
  const [updating, setUpdating] = React.useState(false);

  const updateToken = (newToken: string) => {
    setToken(newToken);
    setUpdating(false);
  };

  useEffect(() => {
    setUpdating(true);
    getCustomerToken(updateToken);
  }, []);

  if (!token || updating) {
    return <Spinner size="4x" className="fullPageSpinner" />;
  }

  return <TokenContextWrapper token={token}>{children}</TokenContextWrapper>;
};
