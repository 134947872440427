import React from 'react';
import { XAxis } from 'recharts';
import { subDays, subHours, addDays, addHours } from 'date-fns';
import { XAxisLabelMonth } from './MonthAxis';
import { XAxisLabelDateRange } from './DateRangeAxis';
import { getMidpointsForInterval } from '../../../util/dates';
import { GraphRecord } from '../../UsageGraph/GraphRecord';
import { useInterval } from '../../../hooks/parameters/useInterval';
import { useSelectedDateRange } from '../../../contexts/DateContext/useSelectedDateRange';
import { XAxisLabelHour } from './HourAxis';

function xAxisStart(interval: string, midpoint: number): number {
  switch (interval) {
    case 'hour':
      return -0.75;
    case 'day':
      return subHours(midpoint, 18).getTime();
    case 'month':
      return subDays(midpoint, 21).getTime();
  }

  return 0;
}

function xAxisEnd(interval: string, midpoint: number): number {
  switch (interval) {
    case 'hour':
      return 23.75;
    case 'day':
      return addHours(midpoint, 18).getTime();
    case 'month':
      return addDays(midpoint, 21).getTime();
  }

  return 0;
}

function getXAxisLabel(
  interval: string,
  midpoint: number,
  ticks: number[],
  graphData: GraphRecord[],
  x: number,
  y: number
): JSX.Element | null {
  if (interval === 'month') {
    return XAxisLabelMonth(midpoint, ticks, x, y);
  }

  if (interval === 'day') {
    return XAxisLabelDateRange(midpoint, ticks, x, y);
  }

  return XAxisLabelHour(midpoint, graphData, x, y);
}

export function dateTicks(interval: string, startDate: Date, endDate: Date, addPaddingTicks: boolean): number[] {
  let ticks = new Array<number>();
  if (interval === 'hour') {
    ticks.push(...Array(24).keys());
  } else {
    ticks.push(...getMidpointsForInterval(interval, startDate, endDate));
  }

  if (ticks.length && addPaddingTicks) {
    const beforeStart = xAxisStart(interval, ticks[0]);
    const afterEnd = xAxisEnd(interval, ticks[ticks.length - 1]);
    ticks = [beforeStart, ...ticks, afterEnd];
  }

  return ticks;
}

interface RelevantContext {
  interval: string;
  startDate: Date;
  endDate: Date;
}

function useRelevantContext(): RelevantContext | null {
  const [interval] = useInterval();
  const [{ start: startDate, end: endDate }] = useSelectedDateRange();

  const values = [interval, startDate, endDate];
  const missing = values.find((value) => !value);

  if (!missing) {
    return { interval: interval, startDate: startDate, endDate: endDate };
  }

  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomizedAxisTick: React.FunctionComponent<any> = (props: any) => {
  const context = useRelevantContext();
  if (!context) {
    return null;
  }

  const interval = context.interval;
  const startDate = context.startDate;
  const endDate = context.endDate;

  const ticks = dateTicks(interval, startDate, endDate, false);

  const midpoint = props.payload.value;
  const index = props.index;

  // Skip labels for the extra entries we added
  if (index === 0 || index > ticks.length) {
    return null;
  }

  const x = props.x;
  const y = props.y;

  return getXAxisLabel(interval, midpoint, ticks, props.graphData, x, y);
};

export function TimeAxis(ticks: number[], graphData: GraphRecord[]): JSX.Element {
  const domain = [ticks[0], ticks[length - 1]];

  return (
    <XAxis
      type="number"
      scale="linear"
      domain={domain}
      ticks={ticks}
      tick={<CustomizedAxisTick graphData={graphData} />}
      axisLine={false}
      tickLine={false}
      dataKey="midpoint"
      interval={0}
      minTickGap={0}
    />
  );
}
