import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GraphSelection } from './GraphSelection';
import { CustomerInformation } from '../../components/CustomerInformation/CustomerInformation';
import { DatesAvailableWrapper } from '../../components/Dates/DatesAvailableWrapper';

import './GasGraph.scss';
import { useProductType } from '../../hooks/parameters/useProductType';

const introduction = (
  <Row>
    <Col>
      <p className="pt-3">
        View your energy usage by applying one of the following filters located in the <b>Time Period</b> drop down
        menu: Date Range or 13 Months. The Date Range filter will allow a view of up to 35 days. To view data from a
        specific bill period, input the service dates located on your billing statement into the Start Date and End Date
        fields.
      </p>
      <p>
        Your gas use is measured in cubic feet, and you are charged a rate per 100 cubic feet (CCF). Usage values are
        rounded for display purposes. Review your bill statement for billed usage.
      </p>
      <hr />
    </Col>
  </Row>
);

export const GasGraph: React.FunctionComponent = () => {
  const [productType, setProductType] = useProductType();

  useEffect(() => {
    setProductType('gas');
  });

  if (productType !== 'gas') {
    return null;
  }

  return (
    <React.Fragment>
      <Container className="bodyContent">
        <CustomerInformation></CustomerInformation>
        <DatesAvailableWrapper>
          <h2>Your Energy Usage</h2>
          {introduction}
          <GraphSelection />
        </DatesAvailableWrapper>
      </Container>
    </React.Fragment>
  );
};
