import { max, min } from 'date-fns';
import { useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { getDate } from '../../common/dates/dates';
import { DateRange } from '../../contexts/DateContext/DateContext';
import { formatISO } from '../../util/dates';

function constrainDate(date: Date, dateRange?: DateRange): Date {
  if (!dateRange) {
    return date;
  }

  if (!date) {
    return undefined;
  }

  let returnValue = date;
  if (dateRange.end) {
    returnValue = min([returnValue, dateRange.end]);
  }
  if (dateRange.start) {
    returnValue = max([returnValue, dateRange.start]);
  }

  return returnValue;
}

function getDefaultValue(value: Date, defaultValue: Date, maxValue: Date): Date {
  const values = [value, defaultValue, maxValue];

  // Just get the first value that isn't undefined
  return values.find((value) => value !== undefined);
}

const CustomDateParam = {
  encode(value: Date): string {
    return formatISO(value);
  },

  decode(value: string): Date {
    return getDate(value);
  },
};

function getOrderedDateRange(dateRange: DateRange): DateRange {
  let newDates = [dateRange?.start, dateRange?.end];
  newDates = newDates.filter((date) => date);
  return {
    start: min(newDates),
    end: max(newDates),
  };
}

export function useDateRangeValues(limits?: DateRange): [DateRange, (DateRange) => void] {
  const [startValue, setStartValue] = useQueryParam('startDate', CustomDateParam);
  const [endValue, setEndValue] = useQueryParam('endDate', CustomDateParam);
  const [updating, setUpdating] = useState(false);

  const updateValue = (newDateRange: DateRange) => {
    setUpdating(true);

    // Make sure the values are in order
    let newRange = getOrderedDateRange(newDateRange);

    // Make sure the values are constrained to what's available
    newRange = {
      start: constrainDate(newDateRange?.start, limits),
      end: constrainDate(newDateRange?.end, limits),
    };

    setStartValue(newRange?.start);
    setEndValue(newRange?.end);

    setUpdating(false);
  };

  let dateRange = undefined;

  // Only return values once we have limits
  if (limits?.end && !updating) {
    dateRange = {
      start: startValue,
      end: endValue,
    };
  }

  return [dateRange, updateValue];
}
