import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { CustomerInformation } from '../../components/CustomerInformation/CustomerInformation';
import { UsageReport } from '../../components/UsageReport/UsageReport';
import { ManageSharedLink } from '../../components/ManageSharedLink/ManageSharedLink';

export const DownloadData: React.FunctionComponent<{}> = () => {
  return (
    <React.Fragment>
      <Container className="bodyContent">
        <Row>
          <Col>
            <CustomerInformation />
            <UsageReport />
            <hr />
            <ManageSharedLink />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
