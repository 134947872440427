import React, { useState } from 'react';
import { Modal, Container, Col, Row, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import './DeleteLinkPrompt.scss';
import { SharedLink } from './sharedLink';

interface Props {
  deleteSharedLink: () => void;
  sharedLink: SharedLink;
}

export const DeleteLinkPrompt: React.FunctionComponent<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = (): void => {
    setShowModal(true);
  };

  const closeModal = (): void => {
    setShowModal(false);
  };

  const deleteLink = (): void => {
    props.deleteSharedLink();
    closeModal();
  };

  const renderForm = () => {
    return (
      <React.Fragment>
        <Row>
          <Col className="text-right">
            <Button variant="outline-primary" onClick={closeModal}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary"
              onClick={deleteLink}
              id="delete-shared-link"
              data-track="usage"
              data-track-detail="usage report"
              data-track-action="click"
              data-track-sub-action="delete link"
            >
              Delete Link
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const renderLastAccessed = () => {
    const sharedLink = props.sharedLink;
    if (!sharedLink) {
      return null;
    }

    let accessText: JSX.Element;

    const accessDate = sharedLink.lastAccessedDate;

    if (sharedLink.accessCount > 0 && accessDate) {
      const accessDateFormatted = format(accessDate, 'MMMM d, yyyy');
      accessText = (
        <React.Fragment>
          Last Accessed: &nbsp; <b>{accessDateFormatted}</b>
        </React.Fragment>
      );
    } else {
      accessText = (
        <React.Fragment>
          <b>This link has never been accessed</b>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Col>{accessText}</Col>
        </Row>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Button
        onClick={openModal}
        variant="link"
        data-track="usage"
        data-track-detail="usage report"
        data-track-action="click"
        data-track-sub-action="delete-modal link"
      >
        Delete Link
      </Button>

      <Modal centered size="lg" show={showModal} animation={false} onHide={closeModal} className="deleteLink">
        <Modal.Header closeButton>
          <Modal.Title>Delete Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                Are you sure you want to delete this link? Anyone who has this link will no longer have access to your
                energy usage data. Do you want to proceed?
              </Col>
            </Row>
            {renderLastAccessed()}
            {renderForm()}
          </Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
