import { UsageRecord } from './UsageRecord';
import { parseIntervalDate, midpointForInterval } from '../../util/dates';
import { sumOrNull } from '../../util/utils';

function midpoint(interval: string, date: Date | null, hour?: number): number {
  // For hour, just use the hour of day to avoid issues with DST transitions
  if (interval === 'hour') {
    return hour || 0;
  }
  return midpointForInterval(interval, date);
}

function hasMissingData(usage: number | null, expectedHours: number, missingHours: number): boolean {
  // No usage
  if (usage === null || usage === undefined) {
    return true;
  }
  // Everything we were expecting to get is missing
  return missingHours === expectedHours;
}

function hasPartialData(usage: number | null, expectedHours: number, missingHours: number): boolean {
  // No usage means missing data, not partial data
  if (usage === null || usage === undefined) {
    return false;
  }
  return missingHours > 0 && missingHours < expectedHours;
}

export class UsageData {
  readonly interval: string;
  readonly record: UsageRecord;
  readonly costEstimate: number | null;
  readonly usage: number | null;
  readonly readingType: string;
  readonly timeOfDay: string;
  readonly date: Date | null;
  readonly midpoint: number;
  readonly missingHours: number;
  readonly expectedHours: number;
  readonly hours: number;
  readonly hasMissingData: boolean;
  readonly hasPartialData: boolean;
  readonly missingCostEstimate: number | null;
  readonly missingUsageEstimate: number | null;
  readonly totalUsage: number | null;
  readonly totalCostEstimate: number | null;

  constructor(interval: string, record: UsageRecord) {
    this.interval = interval;
    this.record = record;
    this.usage = record.usage;
    this.costEstimate = record.costEstimate;
    this.readingType = record.readingType;
    this.timeOfDay = record.timeOfDay || '';
    this.date = parseIntervalDate(interval, record.month, record.day, record.hour);
    this.midpoint = midpoint(interval, this.date, this.record.hour);
    this.missingHours = record.missingHours;
    this.expectedHours = record.expectedHours;
    this.hours = this.expectedHours - this.missingHours;
    this.hasMissingData = hasMissingData(this.usage, this.expectedHours, this.missingHours);
    this.hasPartialData = hasPartialData(this.usage, this.expectedHours, this.missingHours);
    this.missingUsageEstimate = record.missingUsageEstimate || null;
    this.missingCostEstimate = record.missingCostEstimate || null;
    this.totalUsage = sumOrNull([this.usage, this.missingUsageEstimate]);
    this.totalCostEstimate = sumOrNull([this.costEstimate, this.missingCostEstimate]);
  }
}
