import { addMinutes, isBefore } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';

// TODO: incorporate this into making requests
export function isGoodToken(value: string): boolean {
  // Will expire within the next 60 seconds
  const expiryThreshold = addMinutes(new Date(), 1);

  try {
    // Get the expiration from the token
    const token = jwtDecode<JwtPayload>(value);
    const expiration = token.exp;
    const tokenDate = new Date(expiration * 1000);
    const expired = isBefore(tokenDate, expiryThreshold);
    if (expired) {
      return false;
    }
  } catch {
    // Not a valid token
    return false;
  }

  // If we get here, the token is good
  return true;
}
