import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ReportDateRange } from './ReportDateRange';
import { FileTypeOptions } from './FileTypeOptions';
import { ProductTypeOptions } from './ProductTypeOptions';
import { Account } from '../AccountList/account';
import { DatesAvailableWrapper } from '../Dates/DatesAvailableWrapper';
import './UsageReport.scss';
import { ReportDownloader } from './ReportDownloader';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';
import { GenerationInfo } from '../Info/GenerationInfo';

function renderInstructions(account: Account): JSX.Element {
  // Combo AMI
  if (account.hasComboService(true)) {
    return (
      <React.Fragment>
        Electric customers can view their hourly data and gas customers can view their daily data. The most current data
        is available in a downloaded spreadsheet or a live link.
      </React.Fragment>
    );
  }

  // Electric only (AMI)
  if (account.hasElectricService(true)) {
    return (
      <React.Fragment>
        Electric customers can view their hourly data. The most current data is available in a downloaded spreadsheet or
        a live link.
      </React.Fragment>
    );
  }

  // Gas only (AMI) -- (not possible?)
  if (account.hasGasService(true)) {
    return (
      <React.Fragment>
        Gas customers can view their daily data. The most current data is available in a downloaded spreadsheet or a
        live link.
      </React.Fragment>
    );
  }

  // Account service but no AMI meter
  if (account.hasService()) {
    return <React.Fragment>An AMI meter is required to download usage data.</React.Fragment>;
  }

  // No service
  return <React.Fragment>Active electric or gas service is required to download usage data.</React.Fragment>;
}

export const UsageReport: React.FunctionComponent = () => {
  const [fileType, setFileType] = React.useState('csv');
  const account = useSelectedAccount();

  return (
    <React.Fragment>
      <h2>Energy Usage Report</h2>
      <Container className="report" id="usage-gb">
        <Row>
          <Col>
            DTE Energy customers with an advanced metering infrastructure (AMI) meter can generate an energy usage
            report from the previous 13 months to monitor their data or share with a third party.
          </Col>
        </Row>
        <Row>
          <Col>{renderInstructions(account)}</Col>
        </Row>
        <ProductTypeOptions>
          <DatesAvailableWrapper>
            <ReportDateRange>
              <FileTypeOptions fileType={fileType} setFileType={setFileType} />
              <ReportDownloader fileType={fileType} />
            </ReportDateRange>
          </DatesAvailableWrapper>
        </ProductTypeOptions>
      </Container>
      <GenerationInfo />
    </React.Fragment>
  );
};
