import React, { useState } from 'react';
import { DateSelection } from '../../components/Dates/DateSelection';
import { IntervalOptions } from '../../components/TimeOfUseGraph/IntervalOptions';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTimeOfDayData } from '../../components/TimeOfUseData/TimeOfDayUsageWrapper';
import { useTemperatureData } from '../../components/TemperatureData/TemperatureWrapper';
import { UsageSummary } from '../../components/TimeOfUseSummary/UsageSummary';
import { UsageTable } from '../../components/TimeOfUseTable/UsageTable';
import { GraphLegend } from '../../components/TimeOfUseGraph/GraphLegend';

import 'react-datepicker/dist/react-datepicker.css';
import './GraphSelection.scss';
import { UsageGraphHelper } from '../../components/TimeOfUseData/TimeOfUseDataHelper';
import { TimeOfUseGraph, useGraphRecords } from '../../components/TimeOfUseGraph/TimeOfUseGraph';
import { Spinner } from '../../components/Spinner/Spinner';
import { TemperatureGraphHelper } from '../../components/TemperatureData/TemperatureDataHelper';
import { useMode } from '../../hooks/parameters/useMode';
import { Patterns } from '../../components/Patterns';
import { MeterOptions } from '../../components/UsageGraph/MeterOptions';
import { DownloadDataInfo } from '../../components/Info/DownloadDataInfo';
import { GenerationInfo } from '../../components/Info/GenerationInfo';

interface UsageDataProps {
  usage?: UsageGraphHelper;
}

interface WarningsProps extends UsageDataProps {
  openModal: () => void;
}

const StandardDataWarning: React.FunctionComponent<UsageDataProps> = (props: UsageDataProps) => {
  // No time of day data
  if (!props.usage.hasTimeOfUseData) {
    return null;
  }

  // Time of day rate, but no standard usage
  if (!props.usage.standardUsage.length) {
    return null;
  }

  return (
    <tr className="warningDetails">
      <td>
        <sup>1</sup>
      </td>
      <td>
        Other Rates includes any usage not on a Time of Day rate and, when applicable, is factored in Total kWh Used.
      </td>
    </tr>
  );
};

const MissingDataWarning: React.FunctionComponent<UsageDataProps> = (props: UsageDataProps) => {
  let showWarning = false;
  showWarning = showWarning || props.usage.hasPartialData;
  showWarning = showWarning || props.usage.hasMissingData;

  if (!showWarning) {
    return null;
  }

  return (
    <tr className="warningDetails">
      <td>
        <sup>*</sup>
      </td>
      <td>
        Your bill statement may vary as data may take up to 36 hours to process or may not be available because of an
        interruption or outage.
      </td>
    </tr>
  );
};

// TODO: renable warning when we add cost back in
const CostWarning: React.FunctionComponent<WarningsProps> = (props: WarningsProps) => {
  const [mode] = useMode();

  if (mode !== 'cost') {
    return null;
  }

  return (
    <tr className="warningDetails">
      <td>
        <sup>2</sup>
      </td>
      <td>
        <Button
          variant="link"
          onClick={props.openModal}
          data-track="usage"
          data-track-detail="usage show-cost-advisement"
          data-track-action="click"
          data-track-sub-action="show cost-advisement"
        >
          Costs are estimated
        </Button>{' '}
        and may not match what’s listed on your bill. Please refer to your bill for exact costs.
      </td>
    </tr>
  );
};

const Warnings: React.FunctionComponent<WarningsProps> = (props: WarningsProps) => {
  if (!props.usage) {
    return null;
  }

  return (
    <Row>
      <Col className="mr-3 ml-3">
        <table className="graphWarnings">
          <colgroup>
            <col className="symbol" />
            <col className="explanation" />
          </colgroup>
          <thead className="sr-only">
            <tr>
              <th>Symbol</th>
              <th>Explanation</th>
            </tr>
          </thead>
          <tbody>
            <StandardDataWarning usage={props.usage} />
            <MissingDataWarning usage={props.usage} />
            {/* <CostWarning usage={props.usage} openModal={props.openModal} /> */}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

interface DataDisplayProps {
  usage: UsageGraphHelper | undefined;
  temperatures: TemperatureGraphHelper | undefined;
  openModal: () => void;
}

export const DataDisplay: React.FunctionComponent<DataDisplayProps> = (props: DataDisplayProps) => {
  const [mode] = useMode();
  const graphData = useGraphRecords(mode, props.usage, props.temperatures);

  if (!props.usage || !props.temperatures) {
    return <Spinner className="comboGraph" />;
  }

  return (
    <React.Fragment>
      <GraphLegend usage={props.usage} temperatures={props.temperatures} />
      <TimeOfUseGraph mode={mode} usage={props.usage} temperatures={props.temperatures} graphData={graphData} />
      <UsageSummary usage={props.usage} />
      <Warnings openModal={props.openModal} usage={props.usage} />
      <UsageTable mode={mode} graphData={graphData} />
    </React.Fragment>
  );
};

export const GraphSelection: React.FunctionComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const temperatures = useTemperatureData();
  const usage = useTimeOfDayData();

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form className="usageOptions">
            <Form.Row>
              <MeterOptions />
              <IntervalOptions />
              <DateSelection />
            </Form.Row>
          </Form>
          {Patterns}
          <DataDisplay usage={usage} temperatures={temperatures} openModal={openModal} />
        </Col>
      </Row>
      <DownloadDataInfo />
      <GenerationInfo />
    </React.Fragment>
  );
};
