import React, { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import imageDishwasher from './images/dishwasher.svg';
import imageEvCharger from './images/evCharger.svg';
import imageLaundryBasket from './images/laundryBasket.svg';
import imageLightBulb from './images/lightBulb.svg';
import imageSmartStrip from './images/smartStrip.svg';
import imageThermostat from './images/thermostat.svg';

export const SavingTips: FunctionComponent = () => {
  // TODO: check if they have time of date rates

  return (
    <React.Fragment>
      <Row>
        <div className="savingsIntro desktopIndent">
          <h2>Managing Your Savings</h2>
          Flexibility and planning are keys to saving money when you&apos;re on a Time of Day rate. You can reduce your
          electricity costs by shifting some of your energy use to mornings, nights, or weekends.
        </div>
      </Row>
      <Row className="savingTips" xs={1} md={2}>
        <Col className="tip">
          <div className="inner">
            <Row>
              <Col xs={{ span: 'auto' }}>
                <img src={imageLaundryBasket} alt="Laundry basket full of clothes" />
              </Col>
              <Col className="pl-0">
                <h3>Do Laundry on Weekends</h3>
              </Col>
            </Row>
            <p>
              Turn a household chore, like doing laundry, into an opportunity to save. Run your washer and dryer in
              Off-Peak hours, including anytime on Saturday and Sunday.
            </p>
            <p>
              <b>Tip:</b> Whenever possible, use the cold water washer setting. Heating the water can account for up to
              90% of the energy it takes to operate a clothes washer.
            </p>
          </div>
        </Col>
        <Col className="tip">
          <div className="inner">
            <Row>
              <Col xs={{ span: 'auto' }}>
                <img src={imageThermostat} alt="Thermostat set to 72 degrees" />
              </Col>
              <Col className="pl-0">
                <h3>Set a Thermostat Schedule</h3>
              </Col>
            </Row>
            <p>
              Take advantage of Off-Peak times by setting a schedule on your thermostat to automatically adjust your
              home temperature for different times of day and when you&apos;re out and about.
            </p>
            <p>
              <b>Tip:</b> Keep the air flowing by making sure rugs, drapes or furniture are not blocking airflow to
              registers.
            </p>
          </div>
        </Col>
        <Col className="tip">
          <div className="inner">
            <Row>
              <Col xs={{ span: 'auto' }}>
                <img src={imageDishwasher} alt="Dishwasher" />
              </Col>
              <Col className="pl-0">
                <h3>Run Dishwasher at Night</h3>
              </Col>
            </Row>
            <p>
              Delay running your dishwasher until you&apos;re heading off to bed. Save during Off-Peak hours even as you
              sleep.
            </p>
            <p>
              <b>Tip:</b> Fill up your dishwasher during the day. About the same amount of energy and water are used in
              each wash, regardless of load size. But make sure you don&apos;t block the sprayer arm inside the
              dishwasher and end up with dirty dishes.
            </p>
          </div>
        </Col>
        <Col className="tip">
          <div className="inner">
            <Row>
              <Col xs={{ span: 'auto' }}>
                <img src={imageSmartStrip} alt="Smart Power Strip" />
              </Col>
              <Col className="pl-0">
                <h3>Smart Strips and Switches Save</h3>
              </Col>
            </Row>
            <p>
              Plugged-in devices draw energy even when not in use and can account for as much as 12% of your monthly
              electric bill!
            </p>
            <p>
              <b>Tips:</b> Power strips that have a countdown timer you preset cut energy to TVs, gaming equipment and
              AV units when they aren&apos;t being used. A smart plug controlled by a smartphone app lets you power off
              lamps, TVs, printers and gaming consoles not in use.
            </p>
          </div>
        </Col>
        <Col className="tip">
          <div className="inner">
            <Row>
              <Col xs={{ span: 'auto' }}>
                <img src={imageLightBulb} alt="LED Light Bulb" />
              </Col>
              <Col className="pl-0">
                <h3>Save on Lighting</h3>
              </Col>
            </Row>
            <p>
              Make use of natural lighting during On-Peak hours and turn off lights in unoccupied spaces. Lighting can
              account for up to 20% of a typical electric bill.
            </p>
            <p>
              <b>Tips:</b> Be sure to switch to energy-efficient, longer lasting LED light bulbs. For convenience and
              energy savings, connect lights with a smart switch or smart plug paired with a smartphone app. You can
              control lighting whether you&apos;re home or away.
            </p>
          </div>
        </Col>
        <Col className="tip">
          <div className="inner">
            <Row>
              <Col xs={{ span: 'auto' }}>
                <img src={imageEvCharger} alt="Electric Vehicle Charger" />
              </Col>
              <Col className="pl-0">
                <h3>Charging Your Electric Vehicle</h3>
              </Col>
            </Row>
            <p>
              If you can, wait to charge your electric vehicle until Off-Peak hours on weekdays to take advantage of
              lower pricing.
            </p>
            <p>
              <b>Tips:</b> Explore more EV resources, including Level 2 charger rebates at our{' '}
              <a href="https://ev.dteenergy.com" target="_blank" rel="noreferrer">
                online EV Showroom
              </a>
              .
            </p>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
