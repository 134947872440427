import { format } from 'date-fns';
import FileSaver from 'file-saver';
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { downloadReport } from './api';
import { useProductType } from '../../hooks/parameters/useProductType';
import { useSelectedAccount } from '../../hooks/useSelectedAccount';
import { useToken } from '../../contexts/TokenContext/useToken';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';

interface Props {
  fileType: string;
}

function getFilename(productType: string, startDate: Date, endDate: Date, fileType: string): string {
  const nameParts: string[] = [];

  // Need to have a product type selected
  if (productType) {
    nameParts.push(productType);
  }

  nameParts.push('usage', 'report');

  // Only try to save something if we got something back
  if (startDate && endDate) {
    const start = format(startDate, 'MM-dd-yyyy');
    nameParts.push(start);

    nameParts.push('to');

    const end = format(endDate, 'MM-dd-yyyy');
    nameParts.push(end);
  }

  const name = nameParts.join('_');

  const filename = `${name}.${fileType}`;
  return filename;
}

export const ReportDownloader: React.FC<Props> = (props) => {
  const { fileType } = props;
  const token = useToken();
  const [{ start: startDate, end: endDate }] = useSelectedDateRange();

  const [downloading, setDownloading] = React.useState(false);
  const [showNoDataModal, setShowNoDataModal] = React.useState(false);
  const [productType] = useProductType();
  const account = useSelectedAccount();

  const downloadComplete = (result: string | Blob | null) => {
    setDownloading(false);

    // No data available
    if (result === '') {
      setShowNoDataModal(true);
      return;
    }

    if (result === null) {
      // Some type of error occurred
      return;
    }

    const filename = getFilename(productType, startDate, endDate, fileType);
    FileSaver.saveAs(result, filename);
  };

  const closeModal = () => {
    setShowNoDataModal(false);
  };

  const downloadData = () => {
    // Do nothing if already downloading
    if (downloading) {
      return;
    }

    setDownloading(true);

    downloadReport(token, account, productType, startDate, endDate, fileType, downloadComplete);
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="text-center">
          <Button
            name="submit"
            className="btn btn-primary submit"
            data-track="usage"
            data-track-detail="usage report"
            data-track-action="click"
            data-track-sub-action="download report"
            disabled={downloading}
            onClick={downloadData}
          >
            Download Report
          </Button>
        </Col>
      </Row>

      <Modal centered show={showNoDataModal} animation={false} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>No data available</Modal.Title>
        </Modal.Header>
        <Modal.Body>No data was available for the date range selected.</Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
