import React from 'react';
import { config } from '../../util/config';
import './Footer.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { format } from 'date-fns';

const footerContent = (
  <Container fluid className="landing-page-footer" aria-label="footer landmark">
    <Row className="footer-links">
      <Col>
        <a href={config.webBase + '/wps/wcm/connect/dte-web/quicklinks/footer/01+online+privacy+policy'}>
          Online Privacy
        </a>
        <span className="separator" aria-hidden="true">
          &#124;
        </span>
        <a href={config.webBase + '/wps/wcm/connect/dte-web/quicklinks/footer/customer+data+privacy+policy'}>
          Customer Data Privacy Policy
        </a>
        <span className="separator" aria-hidden="true">
          &#124;
        </span>
        <a href={config.webBase + '/wps/wcm/connect/dte-web/quicklinks/footer/terms-and-conditions'}>
          Terms & Conditions
        </a>
      </Col>
    </Row>
    <Row className="copyright">
      <Col>&copy; {format(new Date(), 'yyyy')} DTE Energy. All rights reserved.</Col>
    </Row>
  </Container>
);

export function Footer(): JSX.Element {
  return footerContent;
}
