import { useEffect, useState } from 'react';
import { PeakTimeSavingsHelper } from './PeakTimeSavingsHelper';
import { UrlDetails, useAuthenticatedJsonGet } from '../../util/AuthManager';
import { PeakTimeSavingsResponse } from './PeakTimeSavingsData';
import { config } from '../../util/config';
import { validateAccountNumber } from '../../util/validation';
import { jsonEqual } from '../../util/utils';
import { useAccountNumber } from '../../hooks/parameters/useAccountNumber';

function useURL(): UrlDetails | null {
  const [url, setURL] = useState<UrlDetails | null>(null);
  const [accountNumber] = useAccountNumber();

  useEffect(() => {
    try {
      validateAccountNumber(accountNumber);

      const url = `${config.apiBase}/authenticated/accounts/${accountNumber}/programs/peakTimeSavings`;
      // const url = 'https://usage-test.dteenergy.com/test/events.json';
      setURL({
        url: url,
      });
      return;
    } catch {
      // At least one parameter was invalid
    }

    setURL(null);
  }, [accountNumber]);

  return url;
}

export function useEventData(): PeakTimeSavingsHelper | undefined {
  const url = useURL();
  const [results, loading] = useAuthenticatedJsonGet<PeakTimeSavingsResponse>(url);
  const [peakSavingsData, setPeakSavingsData] = useState<PeakTimeSavingsHelper | undefined>(undefined);

  useEffect(() => {
    if (url && results) {
      // Don't do anything until we have results
      setPeakSavingsData(new PeakTimeSavingsHelper(url, results));
    } else {
      setPeakSavingsData(undefined);
    }
  }, [results, url]);

  if (loading || !jsonEqual(peakSavingsData?.url, url)) {
    return undefined;
  }

  return peakSavingsData;
}
