import React from 'react';
import { Row } from 'react-bootstrap';
import './GraphLegend.scss';
import { createUUID, jsonEqual } from '../../util/utils';
import { patternStripeBackward } from '../Patterns';

const actualLegend = (
  <dl key="actual">
    <dt className="actual" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" />
      </svg>
    </dt>
    <dd>Actual kWh Use</dd>
  </dl>
);

const baselineLegend = (() => {
  const UUID = createUUID();

  return (
    <dl key="baseline">
      <dt className="baseline" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
          <defs>{patternStripeBackward(UUID, undefined, 9)}</defs>
          <rect rx="3" x="7.5%" y="7.5%" width="85%" height="85%" fill={'url(#pattern-stripe-backward-' + UUID + ')'} />
        </svg>
      </dt>
      <dd>Baseline kWh Use</dd>
    </dl>
  );
})();

const _GraphLegend: React.FunctionComponent = () => {
  // TODO: align left and wrap as needed on mobile view

  return (
    <Row className="peakLegend d-flex justify-content-start justify-content-md-end">
      {baselineLegend}
      {actualLegend}
    </Row>
  );
};
export const GraphLegend = React.memo(_GraphLegend, jsonEqual);
