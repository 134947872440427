import { useValidatedStringQueryParam } from './useValidatedStringQueryParam';

function isValidMode(value: string): boolean {
  const normalized = value?.trim();
  const validValues = ['usage'];

  // TODO: add support for cost later

  return validValues.includes(normalized);
}

export function useMode(): [string, (string) => void] {
  const [mode, setMode] = useValidatedStringQueryParam('mode', isValidMode, 'usage');
  return [mode, setMode];
}
